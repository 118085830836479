import {
  DownloadSVG,
  EditSVG,
  RejectRoundSVG,
  RemoveSVG,
} from "../../../../lib/assets/icons";
import { useCallback, useMemo } from "react";

import { ComponentService } from "../../../../services/ComponentService";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { ExecutionService } from "../../../../services/ExecutionService";
import { ExecutionStatus } from "../../../../models/execution-status";
import { ExecutionStatusTag } from "../../../../components/ExecutionStatusTag";
import { ExecutionSummaryDTO } from "../../../../models/execution-summary-dto";
import { ExecutionsChangedEvent } from "../../../../events/ExecutionsChangedEvent";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { InstructionService } from "../../../../services/InstructionService";
import { InstructionsChangedEvent } from "../../../../events/InstructionsChangedEvent";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TestService } from "../../../../services/TestService";
import { TestSummaryDTO } from "../../../../models/test-summary-dto";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

interface ICellProps {
  value: ExecutionSummaryDTO;
  projectId: number;
  suiteId: number;
}

export const IdCell = (props: ICellProps) => {
  return (
    <div className="id-cell">
      <div className="label-name">{props.value.executionId}</div>
    </div>
  );
};

export const DateCell = (props: ICellProps) => {
  return (
    <div className="name-cell">
      <div className="label-name">
        {DateTime.fromISO(props.value.executionDate).toFormat(
          "dd/MM/yyyy HH:mm"
        )}
      </div>
    </div>
  );
};

export const TimeCell = (props: ICellProps) => {
  return (
    <div className="description-cell">
      <div className="label-name">
        {props.value.executionTime !== 0
          ? props.value.executionTime.toFixed(1) + "s"
          : "None"}
      </div>
    </div>
  );
};

export const StatsCell = (props: ICellProps) => {
  const stat = useMemo(
    () =>
      ExecutionStatus[props.value.status as keyof typeof ExecutionStatus] ??
      ExecutionStatus.None,
    [props.value]
  );

  return (
    <div className="stats-cell">
      <ExecutionStatusTag status={stat} />
    </div>
  );
};

const executionSvc = new ExecutionService();

export const OptionsCell = (props: ICellProps) => {
  const openPopup = usePopup();

  const abortCall = useServiceCallPro(executionSvc.abortExecution2);

  /****************************
   * DATA REQUESTS
   *****************************/

  const abortExecution = useCallback(() => {
    abortCall
      .invoke(props.value.executionId, props.projectId, props.suiteId)
      .then(() => {
        openPopup(<SuccessPopup>Execution aborted successfully!</SuccessPopup>);
        ExecutionsChangedEvent.fireExecutionsChanged();
      })
      .catch((error) => {
        if (error.response.data) {
          openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
        } else {
          openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        }
      });
  }, [
    abortCall.invoke,
    props.value,
    props.projectId,
    props.suiteId,
    openPopup,
  ]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleOnAbortConfirmClicked = useCallback(() => {
    abortExecution();
  }, [abortExecution]);

  const handleDownloadClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      // Specify the path to the file in the public folder
      const filePath = `${process.env.PUBLIC_URL}/cucumber_output.json`;

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", `report_${props.value.executionId}.json`); // Set the download attribute with the file name

      // Append the link to the document body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    },
    []
  );

  const handleAbortClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      openPopup(
        <WarningPopup onConfirmClicked={handleOnAbortConfirmClicked}>
          Are you sure you want to abort this execution?
        </WarningPopup>
      );
    },
    [openPopup, handleOnAbortConfirmClicked]
  );
  /****************************
   * CSS & HTML
   *****************************/

  return (
    <div>
      <FlexLayout className="options-cell" direction="horizontal">
        <IconButton
          size={40}
          type="tertiary"
          icon={<DownloadSVG />}
          onClick={handleDownloadClicked}
        />

        {props.value.status === "Aborted" ? (
          <Spacer mode="horizontal" px={24} />
        ) : (
          <IconButton
            size={40}
            type="tertiary"
            icon={<RejectRoundSVG />}
            onClick={handleAbortClicked}
          />
        )}
      </FlexLayout>
    </div>
  );
};
