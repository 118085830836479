import { Button } from "../../lib/components/buttons/Button";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { IconButtonFile } from "../../lib/components/buttons/IconButtonFile";
import { RiAddFill } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiCheckFill } from "react-icons/ri";
import { RiDownload2Line } from "react-icons/ri";
import { TextButton } from "../../lib/components/buttons/TextButton";
import { RiEdit2Fill } from "react-icons/ri";

interface IButtonsProps {}

export function Buttons(props: IButtonsProps) {
  return (
    <div className="section buttons">
      <h1>
        <span>01. </span>Buttons
      </h1>
      <div className="item">
        <div>Primary Button</div>
        <div className="flex-row">
          <Button type="primary" text="Primary" />
          <Button type="primary" text="Primary Disabled" isDisabled />
          <Button type="primary" text="With Icon" icon={<RiAddFill />} />
          <Button
            type="primary"
            text="With Icon Disabled"
            icon={<RiAddFill />}
            isDisabled
          />
        </div>
      </div>
      <div className="item">
        <div>Secondary Button</div>
        <div className="flex-row">
          <Button type="secondary" text="Secondary" />
          <Button type="secondary" text="Secondary Disabled" isDisabled />
          <Button type="secondary" text="With Icon" icon={<RiAddFill />} />
          <Button
            type="secondary"
            text="With Icon Disabled"
            icon={<RiAddFill />}
            isDisabled
          />
        </div>
      </div>
      <div className="item">
        <div>Tertiary Button</div>
        <div className="flex-row">
          <Button type="tertiary" text="Tertiary" />
          <Button type="tertiary" text="Tertiary Disabled" isDisabled />
          <Button type="tertiary" text="With Icon" icon={<RiAddFill />} />
          <Button
            type="tertiary"
            text="With Icon Disabled"
            icon={<RiAddFill />}
            isDisabled
          />
        </div>
      </div>
      <h1>
        <span>02. </span>Text Buttons
      </h1>
      <div className="item">
        <div>Primary</div>
        <div className="flex-row">
          <TextButton type="primary" text="Primary" />
          <TextButton type="primary" text="Primary Disabled" isDisabled />
          <TextButton
            type="primary"
            text="Icon Right"
            icon={<RiArrowRightSLine />}
          />
          <TextButton
            type="primary"
            text="Icon Right Disabled"
            icon={<RiArrowRightSLine />}
            isDisabled
          />
          <TextButton
            type="primary"
            text="Icon Left"
            icon={<RiArrowLeftSLine />}
            iconBefore
          />
          <TextButton
            type="primary"
            text="Icon Left Disabled"
            icon={<RiArrowLeftSLine />}
            iconBefore
            isDisabled
          />
        </div>
        <div>Secondary</div>
        <div className="flex-row">
          <TextButton type="secondary" text="Secondary" />
          <TextButton type="secondary" text="Secondary Disabled" isDisabled />
          <TextButton
            type="secondary"
            text="Icon Right"
            icon={<RiArrowRightSLine />}
          />
          <TextButton
            type="secondary"
            text="Icon Right Disabled"
            icon={<RiArrowRightSLine />}
            isDisabled
          />
          <TextButton
            type="secondary"
            text="Icon Left"
            icon={<RiArrowLeftSLine />}
            iconBefore
          />
          <TextButton
            type="secondary"
            text="Icon Left Disabled"
            icon={<RiArrowLeftSLine />}
            iconBefore
            isDisabled
          />
        </div>
      </div>
      <h1>
        <span>03. </span>Icon Buttons
      </h1>
      <div className="item">
        <div>Icon Button</div>
        <div className="flex-row">
          <IconButton type="primary" icon={<RiCheckFill />} />
          <IconButton type="primary" icon={<RiCheckFill />} isDisabled />
          <IconButton type="primary" icon={<RiCheckFill />} size={30} />
          <IconButton type="primary" icon={<RiCheckFill />} isRound />
          <IconButton type="secondary" icon={<RiCheckFill />} />
          <IconButton type="secondary" icon={<RiCheckFill />} isDisabled />
          <IconButton type="secondary" icon={<RiCheckFill />} size={30} />
          <IconButton type="secondary" icon={<RiCheckFill />} isRound />
          <IconButton type="tertiary" icon={<RiCheckFill />} />
          <IconButton type="tertiary" icon={<RiCheckFill />} isDisabled />
          <IconButton type="tertiary" icon={<RiCheckFill />} size={30} />
        </div>
      </div>
      <div className="item">
        <div>Upload</div>
        <div className="flex-row">
          <IconButtonFile
            type="primary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
          />
          <IconButtonFile
            type="primary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
            isDisabled
          />
          <IconButtonFile
            type="primary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
            size={30}
          />
          <IconButtonFile
            type="primary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
            isRound
          />
          <IconButtonFile
            type="secondary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
          />
          <IconButtonFile
            type="secondary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
            isDisabled
          />
          <IconButtonFile
            type="secondary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
            size={30}
          />
          <IconButtonFile
            type="secondary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
            isRound
          />
          <IconButtonFile
            type="tertiary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
          />
          <IconButtonFile
            type="tertiary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
            isDisabled
          />
          <IconButtonFile
            type="tertiary"
            icon={<RiDownload2Line />}
            onFileSelected={() => {}}
            size={30}
          />
        </div>
      </div>
    </div>
  );
}
