export class TestSuitesChangedEvent {
 static fireTestSuitesChanged() {
    let event = new CustomEvent<void>("test-suites-changed");
    window.dispatchEvent(event);
 }

 static attachHandler(handler: EventListener){
    window.addEventListener("test-suites-changed", handler);
 }

 static detachHandler(handler: EventListener){
    window.removeEventListener("test-suites-changed", handler);
 }
}