import { DateTime } from "luxon";
import { useCallback, useContext, useState } from "react";
import React from "react";
import { ResponsiveDataTable } from "../../lib/components/table/ResponsiveDataTable";
import {
  ColumnDefinition,
  IOrderInfo,
} from "../../lib/components/table/TableInterfaces";
import "../ListTable.css";
import {
  RiAddFill,
  RiCheckFill,
  RiCloseFill,
  RiDeleteBin7Fill,
  RiEditFill,
  RiEyeFill,
  RiSearchFill,
} from "react-icons/ri";
import { Tag } from "../../lib/components/tag/Tag";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { Tooltip } from "../../lib/components/tooltip/Tooltip";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { useFormControl } from "../../lib/components/form/Form";
import { UISContext } from "../../lib/infrastructure/ui/UIServices";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { useNavigate, useParams } from "react-router-dom";
import { ComponentService } from "../../services/ComponentService";
import { ComponentSummaryDTO } from "../../models/component-summary-dto";
import { TestSummaryDTO } from "../../models/test-summary-dto";
import { TestService } from "../../services/TestService";
const testService = new TestService();

interface IListProps {
  items?: TestSummaryDTO[];
}

export function ListTestsTable(props: IListProps) {
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const textInputFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });

  const projectId = parseInt(useParams().projectId?.toString() || "0");
  const suiteSetId = parseInt(useParams().suiteSetId?.toString() || "0");
  var url = `/projects/${projectId}/suiteSets/${suiteSetId}`;

  async function handleDelete(
    id: number,
    projectid: number,
    suiteSetId: number
  ) {
    if (context !== null) {
      context.setShowPopup(true);
      context.setPopupContent(
        <WarningPopup
          onConfirmClicked={async () => {
            const res = await testService.deleteTest(id, projectid, suiteSetId);
            if (res) {
              context.setPopupContent(
                <SuccessPopup
                  onDismissClicked={() => {
                    context?.setShowPopup(false);
                    setNeedsRefreshing(!needsRefreshing);
                  }}
                >
                  Test removed successfully!
                </SuccessPopup>
              );
            } else {
              context.setPopupContent(
                <ErrorPopup
                  onDismissClicked={() => {
                    context?.setShowPopup(false);
                  }}
                >
                  Error deleting test!
                </ErrorPopup>
              );
            }
          }}
          onDismissClicked={() => {
            context.setShowPopup(false);
          }}
        >
          Are you sure you want to delete this test?
        </WarningPopup>
      );
    }
  }

  const context = useContext(UISContext);
  const navigate = useNavigate();
  const columns: ColumnDefinition<TestSummaryDTO>[] = [
    {
      columnKey: "Name",
      cellRenderProp: (v: TestSummaryDTO) => v.name,
      headerRender: isSearching ? (
        <div className="search-header">
          <FormContainer>
            <FlexLayout direction="horizontal" horizontalAlign="start">
              <FormFieldTextInput
                formControl={textInputFormControl}
                placeholder="Name"
                id="name"
              />
              <IconButton
                type="primary"
                icon={<RiSearchFill />}
                onClick={() => {
                  setIsSearching(!isSearching);
                  search();
                }}
                isRound
                className="search-icon-box"
              />
            </FlexLayout>
          </FormContainer>
        </div>
      ) : (
        <div className="search-header">
          <FlexLayout direction="horizontal" horizontalAlign="start">
            Name
            <IconButton
              type="primary"
              icon={<RiSearchFill />}
              onClick={() => {
                setIsSearching(!isSearching);
              }}
              isRound
              className="search-icon"
            />
          </FlexLayout>
        </div>
      ),
      isMobilePrimaryCell: true,
      isSortable: true,
    },
    {
      columnKey: "view",
      cellRenderProp: (v: TestSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiEyeFill />}
          onClick={() => navigate(url + "/tests/" + v.testId)}
          id={v.name.toString() + "-view"}
        />
      ),
      isMobileHeaderIcon: true,
      width: "24px",
    },
    {
      columnKey: "edit",
      cellRenderProp: (v: TestSummaryDTO) => {
        console.log(v);
        return (
          <IconButton
            type="tertiary"
            icon={<RiEditFill />}
            onClick={() => {
              navigate(`${url}/tests/${v.testId}/edit`);
            }}
            id={v.name.toString() + "-edit"}
          />
        );
      },
      isMobileHeaderIcon: true,
      width: "24px",
    },
    {
      columnKey: "delete",
      cellRenderProp: (v: TestSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiDeleteBin7Fill />}
          onClick={() => {
            handleDelete(v.testId, projectId, suiteSetId);
          }}
          id={v.name.toString() + "-delete"}
        />
      ),
      isMobileHeaderIcon: true,
      headerRender: (
        <IconButton
          type="primary"
          isRound={true}
          icon={<RiAddFill />}
          onClick={() => navigate(`${url}/tests/new`)}
          id="add-new"
        />
      ),
      width: "24px",
    },
  ];
  const [filters, setFilters] = useState<IFilterOption[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
    { columnKey: "Name", direction: "ASC" },
  ]);
  const [lastOrderInfoColumn, setLastOrderInfoColumn] = useState<IOrderInfo>();
  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );

  const updateColumnOrderInfo = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderInfoColumns);
      setLastOrderInfoColumn(orderedPriorities[orderedPriorities.length - 1]);
    },
    [orderInfoColumns]
  );

  const [totalItems, setTotalItems] = React.useState(0);
  const [items, setItems] = React.useState([] as TestSummaryDTO[]);
  async function search() {
    let filters: IFilterOption[] = [];
    if (textInputFormControl.value) {
      filters.push({
        field: "Name",
        value: textInputFormControl.value,
        exact: "false",
      } as IFilterOption);
    }
    setFilters(filters);
  }
  React.useEffect(() => {
    setIsLoading(true);
    testService
      .getTests(
        {
          pageNumber: currentPage,
          pageSize: itemsPerPage,
          orderOption: lastOrderInfoColumn,
          filterOptions: filters,
        } as PageModel,
        projectId,
        suiteSetId
      )
      .then((datat) => {
        if (datat != null) {
          setTotalItems(datat.totalItems || 0);
          setItems(datat.items || []);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          navigate("/networkerror");
        } else if (error.response?.status === 404) {
          navigate("/notfound");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    needsRefreshing,
    currentPage,
    itemsPerPage,
    lastOrderInfoColumn,
    filters,
    props.items,
    projectId,
    suiteSetId,
    navigate,
  ]);
  return (
    <div>
      <h1>Tests</h1>
      <ResponsiveDataTable
        items={items}
        columnDefinitions={columns}
        totalitems={totalItems}
        currentpage={currentPage}
        paginationOptions={{
          itemsPerPage: itemsPerPage,
          itemsPerPageOptions: [5, 10, 20],
        }}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
        onOrderChanged={updateColumnOrderInfo}
        isLoading={isLoading}
        resizeThreshold={510}
      ></ResponsiveDataTable>
    </div>
  );
}
