//EditPagePopup.tsx on Ark Web - MdEditor is container where code is

import "./InstructionEditor.css";

import { CodeiumEditor, Language } from "@codeium/react-code-editor";
import {
  UISContext,
  useCloseEditor,
  useClosePopup,
  usePopup,
} from "../../lib/infrastructure/ui/UIServices";
import {
  requiredValidator,
  useFormControl,
} from "../../lib/components/form/Form";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button } from "../../lib/components/buttons/Button";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { InstructionCompiler } from "../../utils/InstructionCompiler";
import { InstructionParameterTemplateCreateDTO } from "../../models/instruction-parameter-template-create-dto";
import { InstructionService } from "../../services/InstructionService";
import { InstructionTemplateCreateDTO } from "../../models/instruction-template-create-dto";
import { InstructionTemplateDetailsDTO } from "../../models/instruction-template-details-dto";
import { InstructionTemplateUpdateDTO } from "../../models/instruction-template-update-dto";
import { LineSeparator } from "../../lib/components/separator/LineSeparator";
import ParameterTemplateCrossValidation from "../../utils/ParameterTemplateCrossValidation";
import { PopupArea } from "../../lib/layouts/containers/popup-area/PopupArea";
import { PopupContainer } from "../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../lib/components/buttons/TextButton";

export interface IInstructionEditorPopupProps {
  instructionId: string;
  projectId: string;
  onInstructionUpdated?: (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

const instructionSvc = new InstructionService();
export function InstructionEditorPopup(props: IInstructionEditorPopupProps) {
  const context = useContext(UISContext);
  const openPopup = usePopup();
  const closePopup = useClosePopup();

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  /* const editorRef = useRef<MdEditor>(null); */
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [gherkin, setGherkin] = useState<string>();
  const [code, setCode] = useState<string>();
  const [isGherkinSelected, setIsGherkinSelected] = useState<boolean>(true);
  const [isCodeSelected, setIsCodeSelected] = useState<boolean>(false);
  const [instructionDetails, setInstructionDetails] =
    useState<InstructionTemplateDetailsDTO>();
  const [content, setContent] = useState<string>();

  const simplifiedInstructionFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
  });
  /****************************
   * DATA REQUESTS
   *****************************/

  useEffect(() => {
    if (props.instructionId && props.projectId) {
      instructionSvc
        .getInstruction(props.instructionId, props.projectId)
        .then((result: any) => {
          simplifiedInstructionFormControl.setValue(result.simplified);
          setGherkin(result.gherkin);
          setCode(result.code);
          setIsLoadingData(false);
        })
        .catch((error) => {
          setIsLoadingData(false);
          openPopup(
            <ErrorPopup
              onDismissClicked={() => {
                context?.setShowPopup(false);
              }}
            >
              {error.code}
            </ErrorPopup>
          );
        });
    }
  }, []);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/
  /* 
  const isButtonDisabled = useMemo(() => {
    return editPage.isLoading || isLoadingData;
  }, [editPage]); */

  /****************************
   * USER ACTIONS
   *****************************/
  const close = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      closePopup();
    },
    [closePopup]
  );

  const handleEditorChange = useCallback(
    ({ html, text }: { html: any; text: any }) => {
      setContent(text);
    },
    [setContent, content]
  );
  const handleParamInsertion = useCallback(() => {
    if (isGherkinSelected) {
      setGherkin(`${gherkin}<name:description>`);
    } else if (isCodeSelected) {
      setCode(`${code}<name:description>`);
    }
  }, [gherkin, code]);

  const handleSaveInstructionClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (
        !simplifiedInstructionFormControl.validate() ||
        code == null ||
        code == undefined ||
        code == ""
      )
        return;
      const parameterTemplateCompiler = new InstructionCompiler();
      var parametersSimplifed =
        parameterTemplateCompiler.createInstructionParameterTemplates(
          simplifiedInstructionFormControl.value?.toString() as string
        );
      var parametersCode =
        parameterTemplateCompiler.createInstructionParameterTemplates(code);
      var parametersGherkin =
        parameterTemplateCompiler.createInstructionParameterTemplates(
          gherkin === undefined ? "" : gherkin
        );
      var valid = ParameterTemplateCrossValidation(
        parametersSimplifed,
        parametersGherkin,
        parametersCode
      );

      if (valid) {
        var templateUpdate: InstructionTemplateUpdateDTO = {
          instructionTemplateId: parseInt(props.instructionId),
          simplified:
            simplifiedInstructionFormControl.value?.toString() as string,
          gherkin: gherkin,
          code: code,
          parameters: parametersSimplifed,
        };
        try {
          const res = instructionSvc.updateInstruction(
            props.projectId,
            templateUpdate
          );
          openPopup(
            <SuccessPopup
              onDismissClicked={() => {
                context?.setShowPopup(false);
              }}
            >
              Instruction updated successfully
            </SuccessPopup>
          );
          ev.stopPropagation();
          props.onInstructionUpdated && props.onInstructionUpdated(ev);
        } catch (error: any) {
          openPopup(
            <ErrorPopup
              onDismissClicked={() => {
                context?.setShowPopup(false);
              }}
            >
              {error.code}
            </ErrorPopup>
          );
        }
      }
    },
    [
      simplifiedInstructionFormControl,
      gherkin,
      code,
      props.projectId,
      openPopup,
      props.onInstructionUpdated,
    ]
  );
  /****************************
   * CSS & HTML
   *****************************/

  return (
    <PopupArea>
      <PopupContainer className="edit-page-popup">
        <div className="edit-page-popup-header">
          <FormFieldTextInput
            className="edit-page-popup-simple-field"
            placeholder="Insert simplified instruction"
            formControl={simplifiedInstructionFormControl}
          />
          <div className="act-btns">
            <TextButton type="white" text={"Cancel"} onClick={close} />
            <Button
              text="Save"
              type="primary"
              isDisabled={isButtonDisabled}
              onClick={handleSaveInstructionClicked}
            />
          </div>
        </div>
        <LineSeparator />
        <FlexLayout direction={"horizontal"} className="page-content-renderer">
          <Button
            text="Add parameter at end of text"
            type="tertiary"
            isDisabled={isButtonDisabled}
            onClick={handleParamInsertion}
          />

          <InfoBlock
            label={
              "You can also type <name:description> at the location you want to put the parameter, with the correspondning name and description of parameters."
            }
            value={""}
          />
        </FlexLayout>
        <LineSeparator />

        <Spacer px="15" mode="vertical" />
        <FlexLayout
          direction={"horizontal"}
          horizontalAlign={"center"}
          className="page-content-renderer content"
        >
          <FormContainer>
            <FormSection title="Gherkin">
              <CodeiumEditor
                language="gherkin"
                theme="vs-dark"
                value={gherkin}
                onChange={(value) => {
                  setIsGherkinSelected(true);
                  setIsCodeSelected(false);
                  setGherkin(value);
                }}
              />
            </FormSection>
          </FormContainer>

          <div className="vertical-line-separator" />
          <FormContainer>
            <FormSection title="Code" className="form-section">
              <CodeiumEditor
                language="javascript"
                theme="vs-dark"
                value={code}
                onChange={(value) => {
                  setIsGherkinSelected(false);
                  setIsCodeSelected(true);
                  setCode(value);
                }}
              />
            </FormSection>
          </FormContainer>

          {/* <FormFieldTextArea
            formControl={gherkinFormControl}
            label="Gherkin"
            placeholder="Type translation in Gherkin form"
          />
          
          <FormFieldTextArea
            formControl={codeFormControl}
            label="Code"
            placeholder="Type translation in target-code form"
          /> */}
        </FlexLayout>
      </PopupContainer>
    </PopupArea>
  );
}
