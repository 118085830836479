export class CssClassnameBuilder {

    classNames : string[] = []


    static new() {
        return new CssClassnameBuilder();
    }

    add(className?: string|null): CssClassnameBuilder{
        if(className) {
            this.classNames.push(className);
        }
        return this;
    }

    addConditional(condition: any, className: string | undefined) : CssClassnameBuilder {
        if(condition && className)
        {
            this.classNames.push(className);
        }
        return this;
    }

    build(): string {
        return this.classNames.join(" ");
    }
}
