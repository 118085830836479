import { FormFieldSelectSingle } from "../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { useFormControl } from "../../lib/components/form/Form";

interface Option {
  id: string;
  name: string;
}

const optionsMock = [
  {
    id: "01",
    name: "Option 01",
  },
  {
    id: "02",
    name: "Option 02",
  },
  {
    id: "03",
    name: "Option 03",
  },
  {
    id: "04",
    name: "Option 04",
  },
];

const labelSelector = (item: Option) => item.name;
const idSelector = (item: Option) => item.id;

interface ISectionsProps {}

export function Sections(props: ISectionsProps) {
  const textInputFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });

  const selectSingleFormControl = useFormControl<Option>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
  });

  return (
    <div className="section inputs">
      <h1>
        <span>01. </span>Info Sections
      </h1>
      <div className="item">
        <div className="flex-row">
          <FormSection title="Section of info" unsetFlexGrow>
            <InfoBlock label="Name" value="John Doe" />
            <InfoBlock label="E-mail" value="john.doe@domain.com" />
            <InfoBlock label="Company" value="Orbital Watch" />
            <InfoBlock label="Job Title" value="Developer" />
          </FormSection>
        </div>
      </div>
      <div className="item">
        <div className="flex-row">
          <FormSection
            title="Section of info with column direction"
            childrenFlexDirColumn
          >
            <InfoBlock label="Name" value="John Doe" />
            <InfoBlock label="E-mail" value="john.doe@domain.com" />
            <InfoBlock label="Company" value="Orbital Watch" />
            <InfoBlock label="Job Title" value="Developer" />
          </FormSection>
        </div>
      </div>
      <h1>
        <span>02. </span>Input Sections
      </h1>
      <div className="item">
        <div className="flex-row">
          <FormSection title="Section of inputs" isInputGap>
            <FormFieldTextInput
              formControl={textInputFormControl}
              label="Text Input"
              placeholder="Type something"
            />
            <FormFieldSelectSingle
              label="Select Single"
              placeholder="Type something"
              formControl={selectSingleFormControl}
              options={optionsMock}
              labelSelector={labelSelector}
              idSelector={idSelector}
              isClearSelectionAvailable
            />
          </FormSection>
        </div>
      </div>
      <div className="item">
        <div className="flex-row">
          <FormSection
            title="Section with column direction"
            isInputGap
            childrenFlexDirColumn
          >
            <FormFieldTextInput
              formControl={textInputFormControl}
              label="Text Input"
              placeholder="Type something"
            />
            <FormFieldSelectSingle
              label="Select Single"
              placeholder="Type something"
              formControl={selectSingleFormControl}
              options={optionsMock}
              labelSelector={labelSelector}
              idSelector={idSelector}
              isClearSelectionAvailable
            />
          </FormSection>
        </div>
      </div>
    </div>
  );
}
