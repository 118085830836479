import "./TabMenu.css";

import React, { useEffect, useMemo, useState } from "react";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";

export interface Tab {
  key: string;
  menuTitle: string;
  content: React.ReactNode;
  isActive?: boolean;
  className?: string;
  counter?: number | undefined;
}

interface ITabMenuProps {
  tabs: Tab[];
  className?: string;
}

export function TabMenu(props: ITabMenuProps) {
  const [active, setActive] = useState<string>("0");


  useEffect(() => {
    props.tabs.map((tab) => {
      if (tab.isActive) setActive(tab.key);
      return null;
    });
  }, [props.tabs]);

  const tabMenuCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("tab-menu")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const renderTabs = useMemo(() => {
    return props.tabs.map((tab) => {
      const tabCss = CssClassnameBuilder.new()
        .add("tab")
        .addConditional(tab.className, tab.className)
        .addConditional(tab.key === active, "active")
        .build();

      return (
        <div
          className={tabCss}
          key={tab.key}
          onClick={() => setActive(tab.key)}
        >
          {tab.menuTitle}
          {tab.counter ? (
            <div className="tiny options-counter">{tab.counter}</div>
          ) : null}
        </div>
      );
    });
  }, [props.tabs, setActive, active]);

  const renderTabContents = useMemo(() => {
    return props.tabs.map((tab) => {
      if (tab.key === active) {
        return <div className="tab-content">{tab.content}</div>;
      }
      return null;
    });
  }, [props.tabs, active]);

  return (
    <div className={tabMenuCss}>
      <div className="tab-buttons">{renderTabs}</div>
      {renderTabContents}
    </div>
  );
}
