import { useEffect, useState } from "react";
import {
  useServiceCall,
  useServiceCallPro,
} from "../../../lib/hooks/useServiceCall";

import { Button } from "../../../lib/components/buttons/Button";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { Loader } from "../../../lib/components/loader/Loader";
import { PageContainer } from "../../../lib/layouts/containers/page/PageContainer";
import { ProjectDetailsDTO } from "../../../models/project-details-dto";
import { ProjectService } from "../../../services/ProjectService";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { useParams } from "react-router-dom";

const projectSvc = new ProjectService();

export function ProjectOverviewPage() {
  const { projectId } = useParams();
  const getProjectByIdCall = useServiceCallPro(projectSvc.getProject);
  const [projectDetails, setProjectDetails] = useState<ProjectDetailsDTO>();

  useEffect(() => {
    getProjectByIdCall.invoke(projectId).then((dets) => {
      setProjectDetails(dets);
      console.log(dets);
    });
  }, [getProjectByIdCall, projectId]);

  return (
    <PageContainer className="components-page">
      <FlexLayout direction="horizontal" className="components-page-header">
        <div className="page-header-title">
          <h1>Overview</h1>
        </div>
        {/* <div className="page-header-actions">
                <Button
                    className="addproject-button"
                    icon={<PlusSVG />}
                    text="Add Component"
                    type="primary"
                    onClick={handleAddComponentClicked}
                />
            </div> */}
      </FlexLayout>

      <Spacer mode="vertical" px={20} />

      <div className="project-info">
        {getProjectByIdCall.isLoading ? <Loader /> : null}

        {projectDetails ? (
          <>
            <h2>{projectDetails.name}</h2>
            <Spacer mode="vertical" px={20} />
            <div>Executions: {projectDetails.executionNumber || 0}</div>
            <div>Sets: {projectDetails.suiteSetNumber || 0}</div>
            <div>Components: {projectDetails.componentNumber || 0}</div>
          </>
        ) : null}
      </div>
    </PageContainer>
  );
}
