import "./PopupContainer.css";

import { CssClassnameBuilder } from '../../../utils/CssClassnameBuilder';
import { useMemo } from "react";

interface IPopupProps {
  children: React.ReactNode;
  className?: string;
}

export function PopupContainer(props: IPopupProps) {

  const popupContainerCss = useMemo(() => {
    return CssClassnameBuilder.new()
        .add("popup-container")
        .addConditional(props.className, props.className)
        .build()
  }, [props.className]);

  return (
    <div className={popupContainerCss}>
        {props.children}
    </div>

  );
}
