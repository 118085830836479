import "./EditComponentPopup.css";

import { requiredValidator, useFormControl } from "../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { ComponentService } from "../../../services/ComponentService";
import { ComponentUpdateDTO } from "../../../models/component-update-dto";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { lengthValidator } from "../AddComponentPopup/AddComponentPopup";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IProps {
    projectId: number;
    componentId: number;
    onCompleted: () => void;
}

const componentService = new ComponentService();

export function EditComponentPopup(props: IProps) {

    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const nameFormControl = useFormControl<string>({
        validators: [requiredValidator(), lengthValidator(50)],
        enableAutoValidate: true,
        isDisabled: false,
        initialValue: "",
    });
    const descriptionFormControl = useFormControl<string>({
        validators: [],
        enableAutoValidate: true,
        isDisabled: false,
        initialValue: "",
    });
    const repositoryUrlFormControl = useFormControl<string>({
        validators: [requiredValidator(), lengthValidator(2048)],
        enableAutoValidate: true,
        isDisabled: false,
        initialValue: "",
    });
    const accessTokenFormControl = useFormControl<string>({
        validators: [requiredValidator()],
        enableAutoValidate: true,
        isDisabled: false,
        initialValue: "",
    });

    const getComponentCall = useServiceCallPro(componentService.getComponent);
    const updateComponentCall = useServiceCallPro(componentService.updateComponent);


    /****************************
     * DATA REQUESTS
     *****************************/

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    const isFormValid = useMemo(() =>
        nameFormControl.isValid && descriptionFormControl.isValid && repositoryUrlFormControl.isValid && accessTokenFormControl.isValid
        && nameFormControl.value && repositoryUrlFormControl.value && accessTokenFormControl.value
        , [nameFormControl, descriptionFormControl, repositoryUrlFormControl, accessTokenFormControl]);

    const isButtonDisabled = useMemo(() => {
        return !isFormValid || updateComponentCall.isLoading || getComponentCall.isLoading;
    }, [isFormValid, updateComponentCall.isLoading, getComponentCall.isLoading]);

    useEffect(() => {
        getComponentCall.invoke(props.componentId, props.projectId).then((response) => {
            if (response != null) {
                nameFormControl.setValue(response.name)
                descriptionFormControl.setValue(response.description)
                repositoryUrlFormControl.setValue(response.repositoryUrl)
                accessTokenFormControl.setValue(response.accessToken)
            }
        }).catch((error) => {
            openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        });

    }, []);

    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnClickEditComponent = useCallback(() => {

        if (!isFormValid || updateComponentCall.isLoading) return;

        const component: ComponentUpdateDTO = {
            componentId: props.componentId,
            name: nameFormControl.value as string,
            projectId: props.projectId,
            description: descriptionFormControl.value,
            repositoryUrl: repositoryUrlFormControl.value as string,
            accessToken: accessTokenFormControl.value as string,
        };

        updateComponentCall.invoke(component).then((response) => {
            openPopup(<SuccessPopup>
                <div>Project edited with success!</div>
            </SuccessPopup>);
            props.onCompleted();

        }).catch((error) => {
            openPopup(<ErrorPopup><div>{error.response.data.message}</div></ErrorPopup>);
        });
    }, [props, isFormValid, updateComponentCall, openPopup,
        nameFormControl.value, descriptionFormControl.value, repositoryUrlFormControl.value, accessTokenFormControl.value]);

    /****************************
     * CSS & HTML
     *****************************/

    if (getComponentCall.isLoading || updateComponentCall.isLoading) {
        return (
            <PopupContainer className="edit-component-popup">
                <Loader />
            </PopupContainer>
        )
    }

    return (
        <PopupContainer className="edit-component-popup">

            <h3>Edit component</h3>
            <Spacer mode="vertical" px="16" />

            <FormContainer>
                <FormFieldTextInput id="name" label="Name" placeholder="Insert component name" formControl={nameFormControl} />
                <FormFieldTextArea label="Description" placeholder="Insert description" formControl={descriptionFormControl} />
                <FormFieldTextInput id="repourl" label="Repository URL" placeholder="Insert repository url" formControl={repositoryUrlFormControl} />
                <FormFieldTextInput id="accesstoken" label="Access Token" placeholder="Insert access token" formControl={accessTokenFormControl} />
            </FormContainer>

            <Spacer mode="vertical" px="16" />
            <LineSeparator />

            <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >
                <TextButton type="white" text={"Cancel"} onClick={() => closePopup()} />
                <Button text="Edit" type="primary" isDisabled={isButtonDisabled}
                    onClick={handleOnClickEditComponent} />
            </FlexLayout>
        </PopupContainer>
    );
}

