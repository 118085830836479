import "./EditInstructionPopup.css";

import {
    requiredValidator,
    useFormControl,
} from "../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
    useClosePopup,
    usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { CodeiumEditor } from "@codeium/react-code-editor";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { InstructionCompiler } from "../../../utils/InstructionCompiler";
import { InstructionService } from "../../../services/InstructionService";
import { InstructionTemplateDetailsDTO } from "../../../models/instruction-template-details-dto";
import { InstructionTemplateUpdateDTO } from "../../../models/instruction-template-update-dto";
import { LineSeparator } from "../../../lib/components/separator/LineSeparator";
import ParameterTemplateCrossValidation from "../../../utils/ParameterTemplateCrossValidation";
import { PopupArea } from "../../../lib/layouts/containers/popup-area/PopupArea";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

export interface EditInstructionPopupProps {
    projectId: number;
    instructionId: number;
    onCompleted: () => void;
}

const instructionSvc = new InstructionService();

//todo NEEDS REFACTOR :)
export function EditInstructionPopup(props: EditInstructionPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    /* const editorRef = useRef<MdEditor>(null); */
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

    const [gherkin, setGherkin] = useState<string>();
    const [code, setCode] = useState<string>();
    const [isGherkinSelected, setIsGherkinSelected] = useState<boolean>(true);
    const [isCodeSelected, setIsCodeSelected] = useState<boolean>(false);

    const [instructionDetails, setInstructionDetails] =
        useState<InstructionTemplateDetailsDTO>();
    const [content, setContent] = useState<string>();

    const simplifiedInstructionFormControl = useFormControl<string>({
        validators: [requiredValidator()],
        enableAutoValidate: true,
    });

    const updateInstructionCall = useServiceCallPro(instructionSvc.updateInstruction);
    const getInstructionCall = useServiceCallPro(instructionSvc.getInstruction);

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    const isFormValid = useMemo(() => simplifiedInstructionFormControl.isValid && simplifiedInstructionFormControl.value, [simplifiedInstructionFormControl])

    const isButtonDisabled = useMemo(() => {
        return !isFormValid || getInstructionCall.isLoading || updateInstructionCall.isLoading;
    }, [isFormValid, getInstructionCall.isLoading, updateInstructionCall.isLoading]);

    useEffect(() => {
        getInstructionCall.invoke(props.instructionId.toString(), props.projectId.toString()).then((response) => {
            if (response != null) {
                simplifiedInstructionFormControl.setValue(response.simplified);
                setGherkin(response.gherkin);
                setCode(response.code);
                setIsLoadingData(false);
            }
        }).catch((error) => {
            openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        });
    }, []);

    /****************************
     * USER ACTIONS
     *****************************/

    const handleEditorChange = useCallback(
        ({ html, text }: { html: any; text: any }) => {
            setContent(text);
        },
        [setContent, content]
    );

    const handleParamInsertion = useCallback(() => {
        if (isGherkinSelected) {
            setGherkin(`${gherkin}<name:description>`);
        } else if (isCodeSelected) {
            setCode(`${code}<name:description>`);
        }
    }, [isGherkinSelected, isCodeSelected, gherkin, code]);

    const handleSaveInstructionClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!simplifiedInstructionFormControl.validate() || !code) return;

        const parameterTemplateCompiler = new InstructionCompiler();

        var parametersSimplifed =
            parameterTemplateCompiler.createInstructionParameterTemplates(
                simplifiedInstructionFormControl.value?.toString() as string
            );

        var parametersCode =
            parameterTemplateCompiler.createInstructionParameterTemplates(code);

        var parametersGherkin =
            parameterTemplateCompiler.createInstructionParameterTemplates(
                gherkin === undefined ? "" : gherkin
            );
        var valid = ParameterTemplateCrossValidation(
            parametersSimplifed,
            parametersGherkin,
            parametersCode
        );

        if (valid) {

            var templateUpdate: InstructionTemplateUpdateDTO = {
                instructionTemplateId: props.instructionId,
                simplified:
                    simplifiedInstructionFormControl.value?.toString() as string,
                gherkin: gherkin,
                code: code,
                parameters: parametersSimplifed,
            };

            updateInstructionCall.invoke(props.projectId.toString(), templateUpdate).then((response) => {
                openPopup(<SuccessPopup> <div>Instruction updated successfully!</div> </SuccessPopup>);
                ev.stopPropagation();
                props.onCompleted();
            }).catch((err) => {
                console.log(err);
                openPopup(<ErrorPopup><div>{err.response.data.message}</div></ErrorPopup>);
            });
        }
    },
        [simplifiedInstructionFormControl, code, gherkin, props, updateInstructionCall, openPopup]
    );

    /****************************
     * CSS & HTML
     *****************************/

    return (
        <PopupArea>
            <PopupContainer className="edit-instruction-popup">
                <div className="edit-instruction-popup-header">
                    <FormFieldTextInput
                        className="edit-instruction-popup-simple-field"
                        placeholder="Insert simplified instruction"
                        formControl={simplifiedInstructionFormControl}
                    />
                    <div className="act-btns">
                        <TextButton type="white" text={"Cancel"} onClick={() => closePopup()} />
                        <Button
                            text="Save"
                            type="primary"
                            isDisabled={isButtonDisabled}
                            onClick={handleSaveInstructionClicked}
                        />
                    </div>
                </div>
                <LineSeparator />
                <FlexLayout direction={"horizontal"} className="page-content-renderer">
                    <Button
                        text="Add parameter at end of text"
                        type="tertiary"
                        isDisabled={isButtonDisabled}
                        onClick={handleParamInsertion}
                    />

                    <InfoBlock
                        label={
                            "You can also type <name:description> at the location you want to put the parameter, with the correspondning name and description of parameters."
                        }
                        value={""}
                    />
                </FlexLayout>
                <LineSeparator />

                <Spacer px="15" mode="vertical" />
                <FlexLayout
                    direction={"horizontal"}
                    horizontalAlign={"center"}
                    className="page-content-renderer content"
                >
                    <div className="edit-instruction-popup-codeeditor">
                        <FormContainer>
                            <FormSection title="Gherkin">
                                <CodeiumEditor
                                    language="gherkin"
                                    theme="vs-dark"
                                    value={gherkin}
                                    onChange={(value) => {
                                        setIsGherkinSelected(true);
                                        setIsCodeSelected(false);
                                        setGherkin(value);
                                    }}
                                />
                            </FormSection>
                        </FormContainer>
                    </div>

                    <div className="vertical-line-separator" />

                    <div className="edit-instruction-popup-codeeditor">
                        <FormContainer>
                            <FormSection title="Code" className="form-section">
                                <CodeiumEditor
                                    language="javascript"
                                    theme="vs-dark"
                                    value={code}
                                    onChange={(value) => {
                                        setIsGherkinSelected(false);
                                        setIsCodeSelected(true);
                                        setCode(value);
                                    }}
                                />
                            </FormSection>
                        </FormContainer>
                    </div>
                    {/* <FormFieldTextArea
            formControl={gherkinFormControl}
            label="Gherkin"
            placeholder="Type translation in Gherkin form"
          />
          
          <FormFieldTextArea
            formControl={codeFormControl}
            label="Code"
            placeholder="Type translation in target-code form"
          /> */}
                </FlexLayout>
            </PopupContainer>
        </PopupArea >
    );
}
