import { ErrorPage } from "../../components/error-page/ErrorPage";

export function NetworkErrorPage() {


    return   <ErrorPage
    mainTitle={"Orbital Watch"}
    errorName={"Network Error"}
    errorMessage={
      "The code monkeys at our headquarters are working VEWY HAWD to fix this! Please try again later."
    }
    buttonText={"Return to Base"}
  />
}