import "./AddComponentPopup.css";

import { Optional, ValidatorFunction, requiredValidator, useFormControl } from "../../../lib/components/form/Form";
import { useCallback, useMemo } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { ComponentCreateDTO } from "../../../models/component-create-dto";
import { ComponentService } from "../../../services/ComponentService";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IProps {
  projectId: number;
  onCompleted: () => void;
}

export function lengthValidator(n: number): ValidatorFunction<Optional<string>> {
  return (value) => {
    if (value !== undefined) {
      if (value.length >= n) {
        return "This field exceeds maximum length!";
      }
    }
    return null;
  };
}


const componentService = new ComponentService();

export function AddComponentPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();


  const nameFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator(50)],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const descriptionFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const repositoryUrlFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator(2048)],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const accessTokenFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });

  const createComponentCall = useServiceCallPro(componentService.createComponent);


  /****************************
   * DATA REQUESTS
   *****************************/

  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  const isFormValid = useMemo(() =>
    nameFormControl.isValid && descriptionFormControl.isValid && repositoryUrlFormControl.isValid && accessTokenFormControl.isValid
    && nameFormControl.value && repositoryUrlFormControl.value && accessTokenFormControl.value
    , [nameFormControl, descriptionFormControl, repositoryUrlFormControl, accessTokenFormControl]);

  const isButtonDisabled = useMemo(() => {
    return !isFormValid || createComponentCall.isLoading;
  }, [isFormValid, createComponentCall.isLoading]);


  /****************************
  * USER ACTIONS
  *****************************/

  const handleAddComponentClicked = useCallback(() => {

    if (!isFormValid || createComponentCall.isLoading) return;

    const component: ComponentCreateDTO = {
      name: nameFormControl.value as string,
      projectId: props.projectId,
      description: descriptionFormControl.value,
      repositoryUrl: repositoryUrlFormControl.value as string,
      accessToken: accessTokenFormControl.value as string,
    };

    createComponentCall.invoke(component).then((response) => {

      openPopup(<SuccessPopup>
        <div>Component created successfully!</div>
      </SuccessPopup>);

      props.onCompleted();
    }).catch((err) => {
      console.log(err);
      openPopup(<ErrorPopup><div>{err.response.data.message}</div></ErrorPopup>);
    });

  },
    [props, isFormValid, createComponentCall, openPopup,
    nameFormControl.value, descriptionFormControl.value, repositoryUrlFormControl.value, accessTokenFormControl.value]
  );

  /****************************
  * CSS & HTML
  *****************************/

  if (createComponentCall.isLoading) {
    return (
      <PopupContainer className="add-component-popup">
        <Loader />
      </PopupContainer>
    )
  }

  return (
    <PopupContainer className="add-component-popup">

      <h3>Add component</h3>
      <Spacer mode="vertical" px="16" />

      <FormContainer>
        <FormFieldTextInput id="name" label="Name" placeholder="Insert component name" formControl={nameFormControl} />
        <FormFieldTextArea label="Description" placeholder="Insert description" formControl={descriptionFormControl} />
        <FormFieldTextInput id="repourl" label="Repository URL" placeholder="Insert repository url" formControl={repositoryUrlFormControl} />
        <FormFieldTextInput id="accesstoken" label="Access Token" placeholder="Insert access token" formControl={accessTokenFormControl} />
      </FormContainer>

      <Spacer mode="vertical" px="16" />
      <LineSeparator />

      <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >

        <TextButton text="Cancel" type="white" onClick={() => closePopup()} />
        <Button text="Add" type="primary" isDisabled={isButtonDisabled} onClick={handleAddComponentClicked} />

      </FlexLayout>

    </PopupContainer>
  );
}


