import axios, { AxiosResponse } from "axios";
import Settings from "../appconfiguration.json";
import { ProjectCreateDTO } from "../models/project-create-dto";
import { ProjectUpdateDTO } from "../models/project-update-dto";
import { ProjectDetailsDTO } from "../models/project-details-dto";
import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import qs from "qs";

const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;

export class ProjectService {
  async updateProject(project: ProjectUpdateDTO) {
    const res = await axios.put<ProjectUpdateDTO>(Route(`api/Projects/${project.projectId}`), project);
    return res.data ? res.data : undefined;
  }
  async createProject(project: ProjectCreateDTO) {
    const res = await axios.post<ProjectCreateDTO>(Route(`api/Projects`), project);
    return res.data ? res.data : undefined;
  }

  async getProjects(paginationModel: PageModel | undefined) {
    return axios.request({
      url: Route(`api/Projects`),
      method: 'get',
      paramsSerializer: {
        indexes: null
      },
      params: {
        pageNumber: paginationModel?.pageNumber,
        pageSize: paginationModel?.pageSize,
        orderOption: paginationModel?.orderOption,
        filterOptions: paginationModel?.filterOptions
      }
    }).then((res: AxiosResponse<ProjectPage>) => res.data);

  }

  async deleteProject(id: number) {

    const res = await axios.delete(Route(`api/Projects/${id}`));
    console.log(res);
    return res.status === 200 ? true : false;
  }
  async getProject(id: string | undefined) {
    const res = await axios.get<ProjectDetailsDTO>(Route(`api/Projects/${id}`));
    return res.data ? res.data : undefined;
  }
}
