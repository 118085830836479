import {
  RiAlertFill,
  RiEdit2Fill,
  RiPlayFill,
  RiRunFill,
} from "react-icons/ri";
import { Button } from "../../lib/components/buttons/Button";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { SuiteSetService } from "../../services/SuiteSetService";
import { UISContext, usePopup } from "../../lib/infrastructure/ui/UIServices";
import { ExecutionService } from "../../services/ExecutionService";
import { ExecutionStatus } from "../../models/execution-status";
import { DateTime } from "luxon";
import { ExecutionCreateDTO } from "../../models/execution-create-dto";
import { ExecutionDetailsDTO } from "../../models/execution-details-dto";
import { ExecutionStatusTag } from "../ExecutionStatusTag";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { Spacer } from "../../lib/components/separator/Spacer";
import { ExecutionUpdateDTO } from "../../models/execution-update-dto";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { ViewerReport } from "../report/ViewerReport";
import { ReportSimpleDetailsDTO } from "../../models/report-simple-details-dto";
const suiteSetService = new SuiteSetService();
const executionService = new ExecutionService();
export function ConsultExecution() {
  const openPopup = usePopup();
  const navigate = useNavigate();
  const [execution, setExecution] = React.useState<ExecutionDetailsDTO>();
  const [isNotFound, setIsNotFound] = React.useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = React.useState<boolean>(false);
  const [isRunning, setIsRunning] = React.useState<boolean>(false);
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false);
  const [report, setReport] = useState<ReportSimpleDetailsDTO>();
  const url = window.location.pathname;
  const id = parseInt(useParams().id?.toString() || "0");
  const projectId = parseInt(useParams().projectId?.toString() || "0");
  const suiteSetId = parseInt(useParams().suiteSetId?.toString() || "0");
  const context = useContext(UISContext);
  const handleAbort = useCallback(async () => {
    if (context !== null) {
      context.setShowPopup(true);
      context.setPopupContent(
        <WarningPopup
          onConfirmClicked={async () => {
            const res = await executionService.abortExecution(
              projectId,
              suiteSetId,
              id,
              {
                executionId: id,
                suiteSetId: suiteSetId,
                projectId: projectId,
                status: ExecutionStatus[ExecutionStatus.Aborted],
              } as ExecutionUpdateDTO
            );
            if (res) {
              context.setPopupContent(
                <SuccessPopup
                  onDismissClicked={() => {
                    context?.setShowPopup(false);
                    setNeedsRefreshing(!needsRefreshing);
                  }}
                >
                  Execution aborted successfully!
                </SuccessPopup>
              );
            } else {
              context.setPopupContent(
                <ErrorPopup
                  onDismissClicked={() => {
                    context?.setShowPopup(false);
                  }}
                >
                  Error aborting execution!
                </ErrorPopup>
              );
            }
          }}
          onDismissClicked={() => {
            context.setShowPopup(false);
          }}
        >
          Are you sure you want to abort this execution?
        </WarningPopup>
      );
    }
  }, [context, projectId, suiteSetId, id, needsRefreshing]);
  useEffect(() => {
    if (id === undefined) {
      setIsNotFound(true);
    }
    executionService
      .getExecution(id, projectId, suiteSetId)
      .then((datat) => {
        if (datat != null) {
          setExecution(datat);
          setIsRunning(
            datat.status === ExecutionStatus[ExecutionStatus.Pending] ||
              datat.status === ExecutionStatus[ExecutionStatus.Running]
          );
          setReport(datat.report);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          setIsNetworkError(true);
        } else if (error.response?.status === 404) {
          console.log("Not found!");
          setIsNotFound(true);
        }
      });
  }, []);

  const handleExecutionRun = useCallback(async () => {
    const res = await executionService.createExecution(
      {
        projectId: projectId,
        suiteSetId: suiteSetId,
        executionDate: DateTime.utc().toISO(),
      } as ExecutionCreateDTO,
      suiteSetId
    );
    if (res) {
      setIsRunning(true);
    }
  }, [projectId, suiteSetId]);
  if (isNotFound) {
    navigate("/notfound");
  } else if (isNetworkError) {
    navigate("/networkerror");
  }

  return (
    <div>
      <FormContainer mode={"vertical"}>
        <FlexLayout direction={"horizontal"} verticalAlign="center">
          <h1>
            Execution of{" "}
            {execution?.suiteSetName ? execution.suiteSetName : "None"}
          </h1>
          <Spacer mode={"horizontal"} />

          <ExecutionStatusTag
            status={
              ExecutionStatus[
                execution?.status as keyof typeof ExecutionStatus
              ] !== undefined
                ? ExecutionStatus[
                    execution?.status as keyof typeof ExecutionStatus
                  ]
                : ExecutionStatus.None
            }
          />
        </FlexLayout>

        <FormSection title="Details" unsetFlexGrow childrenFlexDirColumn>
          <FormSection>
            <InfoBlock
              label="Project"
              value={execution?.projectName ? execution.projectName : "None"}
            />
            <FlexLayout direction={"horizontal"} horizontalAlign="end">
              <Spacer mode={"horizontal"} />
              <Button
                type="primary"
                text={isRunning ? "Abort" : "Rerun"}
                icon={isRunning ? <RiAlertFill /> : <RiPlayFill />}
                onClick={() => {
                  !isRunning ? handleExecutionRun() : handleAbort();
                }}
              />
            </FlexLayout>
          </FormSection>

          <InfoBlock
            label="Execution Time"
            value={
              execution?.executionTime
                ? execution.executionTime.toString()
                : "None"
            }
          />
          <InfoBlock
            label="Execution Date"
            value={
              execution?.executionDate
                ? DateTime.fromISO(execution.executionDate).toFormat(
                    "dd/MM/yyyy"
                  )
                : "None"
            }
          />
        </FormSection>
      </FormContainer>
      <ViewerReport report={report} />
    </div>
  );
}
