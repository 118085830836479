import { EditSVG, RemoveSVG } from "../../../lib/assets/icons";

import { ComponentService } from "../../../services/ComponentService";
import { ComponentSummaryDTO } from '../../../models/component-summary-dto';
import { ComponentsChangedEvent } from "../../../events/ComponentsChangedEvent";
import { EditComponentPopup } from "../../popups/EditComponentPopup/EditComponentPopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { useCallback } from "react";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface ICellProps {
    value: ComponentSummaryDTO
    projectId: number
}


const componentService = new ComponentService();

export const NameCell = (props: ICellProps) => {
    return (
        <div className="name-cell">
            <div className="label-name">{props.value.name}</div>
        </div>
    );
};

export const DescriptionCell = (props: ICellProps) => {
    return (
        <div className="description-cell">
            <div className="label-name">{props.value.description}</div>
        </div>
    );
};


export const OptionsCell = (props: ICellProps) => {
    const openPopup = usePopup();

    const deleteComponentCall = useServiceCallPro(componentService.deleteComponent);

    /****************************
    * DATA REQUESTS
    *****************************/

    const deleteProject = useCallback(() => {
        deleteComponentCall.invoke(props.value.componentId, props.projectId)
            .then(() => {
                openPopup(
                    <SuccessPopup>Component removed with success!</SuccessPopup>
                );
                ComponentsChangedEvent.fireComponentsChanged();
            })
            .catch((error) => {
                if (error.response.data) {
                    openPopup(
                        <ErrorPopup>{error.response.data}</ErrorPopup>
                    );
                } else {
                    openPopup(
                        <ErrorPopup>{error.message}</ErrorPopup>
                    );
                }
            });
    }, [deleteComponentCall.invoke, props.value.componentId, props.projectId, openPopup]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleOnDeleteConfirmClicked = useCallback(() => {
        deleteProject();
    }, [deleteProject]);

    const handleEditComponentClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
            <EditComponentPopup
                projectId={props.projectId}
                componentId={props.value.componentId} 
                onCompleted={() => ComponentsChangedEvent.fireComponentsChanged()} />)
    }, [openPopup, props.projectId, props.value.componentId]);


    const handleRemoveIconClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
        <WarningPopup onConfirmClicked={handleOnDeleteConfirmClicked}>
            You are removing this component.
        </WarningPopup>
    )}, [handleOnDeleteConfirmClicked]);

    
    /****************************
    * CSS & HTML
    *****************************/

    return (
        <div>
            <FlexLayout className="options-cell" direction="horizontal">
                <IconButton size={40} type="tertiary" icon={<EditSVG />} onClick={handleEditComponentClicked}/>
                <IconButton size={40} type="tertiary" icon={<RemoveSVG />} onClick={handleRemoveIconClicked} />
            </FlexLayout>
        </div>
    );
};