export class TestsChangedEvent {
 static fireTestsChanged() {
    let event = new CustomEvent<void>("tests-changed");
    window.dispatchEvent(event);
 }

 static attachHandler(handler: EventListener){
    window.addEventListener("tests-changed", handler);
 }

 static detachHandler(handler: EventListener){
    window.removeEventListener("tests-changed", handler);
 }
}