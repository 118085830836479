import "./App.css";

import { BrowserRouter, Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import {
  UISContext,
  useEditor,
  usePopup,
  useSetSelectedProject,
} from "./lib/infrastructure/ui/UIServices";

import { AppPortal } from "./lib/layouts/portal/AppPortal";
import { AppRoutingModule } from "./modules/AppRoutingModule";
import { Button } from "./lib/components/buttons/Button";
import { Buttons } from "./pages/examples/Buttons";
import { Components } from "./pages/examples/Components";
import { ConsultComponent } from "./components/component/ConsultComponent";
import { ConsultExecution } from "./components/executions/ConsultExecution";
import { ConsultProject } from "./components/project/ConsultProject";
import { ConsultSuiteSet } from "./components/suite-sets/ConsultSuiteSet";
import { ConsultTest } from "./components/tests/ConsultTest";
import { CreateComponentForm } from "./components/component/CreateComponentForm";
import { CreateProjectForm } from "./components/project/CreateProjectForm";
import { CreateSuiteSetForm } from "./components/suite-sets/CreateSuiteSetForm";
import { CreateTestForm } from "./components/tests/CreateTestForm";
import { EditComponentForm } from "./components/component/EditComponentForm";
import { EditProjectForm } from "./components/project/EditProjectForm";
import { EditSuiteSetForm } from "./components/suite-sets/EditSuiteSetForm";
import { EditTestForm } from "./components/tests/EditTestForm";
import { ErrorPage } from "./components/error-page/ErrorPage";
import { FlexLayout } from "./lib/layouts/containers/flex/FlexLayout";
import { Home } from "./components/project/Home";
import { IconButton } from "./lib/components/buttons/IconButton";
import { Inputs } from "./pages/examples/Inputs";
import { InstructionEditorPopup } from "./components/instruction-template/InstructionEditorPopup";
import { ListComponentsTable } from "./components/component/ListComponentsTable";
import { ListInstructionsTemplatesTable } from "./components/instruction-template/ListInstructionTemplatesTable";
import { ListProjectsTable } from "./components/project/ListProjectsTable";
import { ListSuiteSetsTable } from "./components/suite-sets/ListSuiteSetsTable";
import { Lists } from "./pages/examples/Lists";
import { Main } from "./pages/Main";
import { Menus } from "./pages/examples/Menus";
import { NetworkErrorPage } from "./features/common/NetworkErrorPage";
import { NotFoundPage } from "./features/common/NotFoundPage";
import { RiEditFill } from "react-icons/ri";
import { SPAApp } from "./lib/infrastructure/app/SPAApp";
import { Sections } from "./pages/examples/Sections";
import { TestEditorPopupAdd } from "./components/tests/TestEditorPopupAdd";
import logo from "./logo.svg";

function App() {

  const [selectedProject, setSelectedProject] = useSetSelectedProject();
  const location = useLocation();
  const { projectId } = useParams();
  

  


  return (

      <AppPortal>

          <Routes>
            <Route path="/" element={<Navigate to="projects2"/>} />
           
            <Route path="/notfound" element={ <NotFoundPage /> } />
            <Route path="/networkerror" element={ <NetworkErrorPage /> } />

            <Route path="examples" element={<Components />} />
            <Route path="examples/buttons" element={<Buttons />} />
            <Route path="examples/inputs" element={<Inputs />} />
            <Route path="examples/sections" element={<Sections />} />
            <Route path="examples/lists" element={<Lists />} />
            <Route path="examples/menus" element={<Menus />} />
            <Route path="*" element={<AppRoutingModule />} />
          </Routes>
  
      </AppPortal>

  );
}

export default App;
