import axios, { AxiosResponse } from "axios";
import Settings from "../appconfiguration.json";
import { ComponentCreateDTO } from "../models/component-create-dto";
import { ComponentDetailsDTO } from "../models/component-details-dto";
import { ComponentUpdateDTO } from "../models/component-update-dto";
import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";

const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;
export class ComponentService {
    async deleteComponent(id: number, projectId: number) {
        const res = await axios.delete(Route(`api/Projects/${projectId}/Components/${id}`));
        return res.status === 200 ? true : false;
    }

    async updateComponent(component: ComponentUpdateDTO) {
        const res = await axios.put<ComponentUpdateDTO>(Route(`api/Projects/${component.projectId}/Components/${component.componentId}`), component);
        return res.data ? res.data : undefined;
    }

    async getComponent(id: number, projectId: number) {
        const res = await axios.get<ComponentDetailsDTO>(Route(`api/Projects/${projectId}/Components/${id}`));
        return res.data ? res.data : undefined;
    }
    async createComponent(component: ComponentCreateDTO) {
        const res = await axios.post<ComponentCreateDTO>(Route(`api/Projects/${component.projectId}/Components`), component);
        return res.data ? res.data : undefined;
    }



    async getComponents(paginationModel: PageModel | undefined, projectId: number | 0) {

        return axios.request({
            url: Route(`api/Projects/${projectId}/Components`),
            method: 'get',
            paramsSerializer: {
                indexes: false
            },
            params: {
                pageNumber: paginationModel?.pageNumber,
                pageSize: paginationModel?.pageSize,
                orderOption: paginationModel?.orderOption,
                filterOptions: paginationModel?.filterOptions
            }
        }).then((res: AxiosResponse<ComponentPage>) => res.data);
    }
}
