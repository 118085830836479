export class InstructionsChangedEvent {
 static fireInstructionsChanged() {
    let event = new CustomEvent<void>("instructions-changed");
    window.dispatchEvent(event);
 }

 static attachHandler(handler: EventListener){
    window.addEventListener("instructions-changed", handler);
 }

 static detachHandler(handler: EventListener){
    window.removeEventListener("instructions-changed", handler);
 }
}