import { BookSVG, DiamondSVG, RoundBottomFlaskSVG, ScienceSVG, WarningMessageSVG } from "../../lib/assets/icons";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

import { MenuItem } from "../../lib/components/side-bar/MenuItem";
import { ProjectComponentsPage } from '../../features/project/components/ProjectComponentsPage';
import { ProjectInstructionsPage } from '../../features/project/instructions/ProjectInstructionsPage';
import { ProjectOverviewPage } from "../../features/project/overview/ProjectOverviewPage";
import { ProjectService } from "../../services/ProjectService";
import { ProjectTestSuitesPage } from "../../features/project/test-suites/ProjectTestSuitesPage";
import { Sidebar } from "../../lib/components/side-bar/Sidebar";
import { TestSuiteModule } from "./lvl2/TestSuiteModule";
import { useEffect } from "react";
import { useSetSelectedProject } from "../../lib/infrastructure/ui/UIServices";

const sideBarButtons: MenuItem[] = [
    {
        icon: <DiamondSVG />,
        navigateTo: "overview/"
    },
    {
        icon: <ScienceSVG />,
        navigateTo: "components/"
    },
    {
        icon: <RoundBottomFlaskSVG />,
        navigateTo: "suites/"
    },
    {
        icon: <BookSVG />,
        navigateTo: "instructions/"
    },
];

const projectSvc = new ProjectService();
export function ProjectsModule() {

    const [selectedProject, setSelectedProject] = useSetSelectedProject();
    const location = useLocation();
    const { projectId } = useParams();
    


    useEffect(() => {
        if (projectId && projectId != selectedProject?.id) {
            projectSvc.getProject(projectId).then((proj) => {
                setSelectedProject({ id: projectId, name: proj?.name || "-" });
            });
        }
    }, [projectId, location]);


    useEffect(() => {
        return () => setSelectedProject(undefined);
    }, []);

    return (
        <div className="main-content">
            <div className="sidebar-content">
                <Sidebar buttons={sideBarButtons} />
            </div>

            <div className="content">
                <Routes>
                    <Route index element={<Navigate to="overview" />} /> 
                    <Route path="overview" element={<ProjectOverviewPage />} />
                    <Route path="components" element={<ProjectComponentsPage />} />
                    <Route path="suites" element={<ProjectTestSuitesPage />} />
                    <Route path="suites/:suiteId/*" element={<TestSuiteModule />} />
                    <Route path="instructions" element={<ProjectInstructionsPage />} />
                </Routes>
            </div>
        </div>
    );
}
