import { EditSVG, RemoveSVG } from "../../../lib/assets/icons";
import { useCallback, useMemo } from "react";

import { DateTime } from "luxon";
import { EditTestSuitePopup } from "../../popups/EditTestSuitePopup/EditTestSuitePopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { ExecutionStatus } from "../../../models/execution-status";
import { ExecutionStatusTag } from "../../../components/ExecutionStatusTag";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { SuiteSetService } from "../../../services/SuiteSetService";
import { SuiteSetSummaryDTO } from "../../../models/suite-set-summary-dto";
import { TestSuitesChangedEvent } from "../../../events/TestSuitesChangedEvent";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface ICellProps {
    value: SuiteSetSummaryDTO
    projectId: number
}

const suiteSetService = new SuiteSetService();

export const NameCell = (props: ICellProps) => {
    return (
        <div className="name-cell">
            <div className="label-name">{props.value.name}</div>
        </div>
    );
};

export const TestTypeCell = (props: ICellProps) => {
    return (
        <div className="test-type-cell">
            <div className="label-name">{props.value.testType}</div>
        </div>
    );
};

export const StatsCell = (props: ICellProps) => {

    const stat = useMemo(() => ExecutionStatus[props.value.mostRecentExecutionStatus as keyof typeof ExecutionStatus] ?? ExecutionStatus.None, [props.value]);

    return (
        <div className="stats-cell">
            <ExecutionStatusTag
                status={stat}
                toolTipName="Date"
                toolTipMessage={DateTime.fromISO(props.value.mostRecentExecutionDate).toFormat("dd/MM/yyyy")}
            />
        </div>
    );
};


export const DescriptionCell = (props: ICellProps) => {
    return (
        <div className="description-cell">
            <div className="label-name">{props.value.name}</div>
        </div>
    );
};


export const OptionsCell = (props: ICellProps) => {
    const openPopup = usePopup();

    const deleteTestSuiteCall = useServiceCallPro(suiteSetService.deleteSuiteSet);

    /****************************
    * DATA REQUESTS
    *****************************/

    const deleteProject = useCallback(() => {
        deleteTestSuiteCall.invoke(props.value.suiteSetId, props.projectId)
            .then(() => {
                openPopup(
                    <SuccessPopup>Suite Set removed successfully!</SuccessPopup>
                );
                TestSuitesChangedEvent.fireTestSuitesChanged();
            })
            .catch((error) => {
                if (error.response.data) {
                    openPopup(
                        <ErrorPopup>{error.response.data}</ErrorPopup>
                    );
                } else {
                    openPopup(
                        <ErrorPopup>{error.message}</ErrorPopup>
                    );
                }
            });
    }, [deleteTestSuiteCall.invoke, props.value, props.projectId, openPopup]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleOnDeleteConfirmClicked = useCallback(() => {
        deleteProject();
    }, [deleteProject]);

    const handleEditTestSuiteClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
            <EditTestSuitePopup projectId={props.projectId} testSuiteId={props.value.suiteSetId} 
                onCompleted={() => { TestSuitesChangedEvent.fireTestSuitesChanged(); }} />
        )
    }, [openPopup, props.projectId, props.value.suiteSetId]);


    const handleRemoveIconClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
            <WarningPopup onConfirmClicked={handleOnDeleteConfirmClicked}>
                Are you sure you want to delete this suite set?
            </WarningPopup>
        )
    }, [handleOnDeleteConfirmClicked]);


    /****************************
    * CSS & HTML
    *****************************/

    return (
        <div>
            <FlexLayout className="options-cell" direction="horizontal">
                <IconButton size={40} type="tertiary" icon={<EditSVG />} onClick={handleEditTestSuiteClicked} />
                <IconButton size={40} type="tertiary" icon={<RemoveSVG />} onClick={handleRemoveIconClicked} />
            </FlexLayout>
        </div>
    );
};