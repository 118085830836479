import { Navigate, Route, Routes } from "react-router-dom";

import { ModuleContentContainer } from "./content/ModuleContentContainer";
import { NavBar } from "../lib/components/nav-bar/NavBar";
import { ProjectsHomePage } from "../features/project/ProjectsHomePage";
import { ProjectsModule } from "./lvl1/ProjectModule";
import { ProjectsOldModule } from "./lvl1/ProjectsOldModule";

export function AppRoutingModule() {

    return (
        <>
            <NavBar />
            <ModuleContentContainer>
                <Routes>
                    <Route path="/projects/*" element={<ProjectsOldModule />} />
                    <Route path="/projects2" element={<ProjectsHomePage />} />
                    <Route path="/projects2/:projectId/*" element={<ProjectsModule />} />
                    <Route path="*" element={<Navigate to="/notfound" />} />
                </Routes>
            </ModuleContentContainer>
        </>
    )
}
