import "./Toggle.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";

export interface ITooggleProps {
    label?: string;
    isDisabled?: boolean;
    isActive?: boolean;
    className?: string;
    onToggleChanged?: (checked: boolean) => void;
}

export function Toggle(props: ITooggleProps) {

    const [isActive, setIsActive] = useState(props.isActive);

    useEffect(() => {
        if (props.isActive === undefined) return;
        setIsActive(props.isActive);
    }, [props.isActive]);

    const toggleCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("toggle")
            .addConditional(props.isDisabled, "disabled")
            .addConditional(isActive, "active")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className, props.isDisabled, isActive]);


    const handleClicked = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation();
            if (props.isDisabled) return;
            setIsActive(!isActive);
            props.onToggleChanged && props.onToggleChanged(!isActive);
        },
        [props.onToggleChanged, props.isDisabled, setIsActive, isActive]
    );

    return (
        <div className={toggleCss} >
            <div className="toggle-input" onClick={handleClicked} >
                <div className="slider" />
            </div>
            {props.label ? <div className="toggle-label">{props.label}</div> : null}
        </div>
    );
}