import {
  RiArrowGoForwardFill,
  RiCheckFill,
  RiClockwise2Fill,
  RiCloseFill,
  RiCrossFill,
  RiDownloadFill,
  RiEdit2Fill,
  RiSkipForwardFill,
  RiTimeFill,
} from "react-icons/ri";
import "./ViewerReport.css";
import { Button } from "../../lib/components/buttons/Button";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { ComponentService } from "../../services/ComponentService";
import { ComponentDetailsDTO } from "../../models/component-details-dto";
import { ReportService } from "../../services/ReportService";
import { ReportSimpleDetailsDTO } from "../../models/report-simple-details-dto";
import { ReportDetailsDTO } from "../../models/report-details-dto";
import { DateTime, Info } from "luxon";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { Tooltip } from "../../lib/components/tooltip/Tooltip";
import { Spacer } from "../../lib/components/separator/Spacer";
const reportService = new ReportService();

interface IReportProps {
  id?: number | undefined;
  setId?: (id: number) => void;
  report?: ReportSimpleDetailsDTO;
}

export function ViewerReport(props: IReportProps) {
  const navigate = useNavigate();
  const params = useParams();
  const [report, setReport] = React.useState<
    ReportDetailsDTO | ReportSimpleDetailsDTO
  >();
  const [isNotFound, setIsNotFound] = React.useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = React.useState<boolean>(false);
  const url = window.location.pathname;
  const projectId = parseInt(params.projectId?.toString() || "0");
  const suiteSetId = parseInt(params.suiteSetId?.toString() || "0");
  const executionId = parseInt(params.executionId?.toString() || "0");
  useEffect(() => {
    if (props.report === undefined) {
      let id: number | undefined;
      if (props.id === undefined) {
        params.reportId
          ? (id = parseInt(params.reportId?.toString()))
          : (id = undefined);
        setIsNotFound(true);
      }
      if (id !== undefined) {
        console.log("id", id);
        reportService
          .getReport(id, projectId, suiteSetId, executionId)
          .then((datat) => {
            console.log("datat", datat);
            if (datat != null) {
              setReport(datat);
            }
          })
          .catch((error) => {
            if (error.response?.status === 500) {
              setIsNetworkError(true);
            } else if (error.response?.status === 404) {
              console.log("Not found!");
              setIsNotFound(true);
            }
          });
      }
    } else {
      setIsNotFound(false);
      setReport(props.report);
    }
  }, [
    executionId,
    projectId,
    suiteSetId,
    props.id,
    params.reportId,
    props.report,
  ]);
  if (isNetworkError) {
    navigate("/networkerror");
  }
  return (
    <>
      {isNotFound ? (
        <h1>Report not available yet</h1>
      ) : (
        <div>
          <FormContainer>
            <h1>Report</h1>

            <FormSection title="Details" unsetFlexGrow>
              <InfoBlock
                label="Start"
                value={
                  report?.start
                    ? DateTime.fromISO(report.start).toFormat("dd/MM/yyyy")
                    : "None"
                }
              />
              <InfoBlock
                label="End"
                value={
                  report?.start
                    ? DateTime.fromISO(report.end).toFormat("dd/MM/yyyy")
                    : "None"
                }
              />
              <InfoBlock
                label="Duration"
                value={report?.duration ? report.duration + "s" : "None"}
              />
            </FormSection>
            <Spacer mode={"vertical"} />
            <FormSection title="Execution" unsetFlexGrow>
              <FlexLayout direction="horizontal" verticalAlign="end">
                <Tooltip text="Pass">
                  <InfoBlock
                    label=""
                    value={
                      report?.nPasses
                        ? report.nPasses + "/" + report.nTests
                        : "None"
                    }
                  />
                </Tooltip>
                <RiCheckFill className="green" />
              </FlexLayout>
              <FlexLayout direction="horizontal" verticalAlign="end">
                <Tooltip text="Pending">
                  <InfoBlock
                    label=""
                    value={
                      report?.nPending
                        ? report.nPending + "/" + report.nTests
                        : "None"
                    }
                  />
                </Tooltip>
                <RiTimeFill className="blue" />
              </FlexLayout>
              <FlexLayout direction="horizontal" verticalAlign="end">
                <Tooltip text="Failed">
                  <InfoBlock
                    label=""
                    value={
                      report?.nFailures
                        ? report.nFailures + "/" + report.nTests
                        : "None"
                    }
                  />
                </Tooltip>
                <RiCloseFill className="red" />
              </FlexLayout>
              <FlexLayout direction="horizontal" verticalAlign="end">
                <Tooltip text="Skipped">
                  <InfoBlock
                    label=""
                    value={
                      report?.skipped
                        ? report.skipped + "/" + report.nTests
                        : "0/" + report?.nTests
                    }
                  />
                </Tooltip>
                <RiSkipForwardFill className="yellow" />
              </FlexLayout>
              <Button
                type="secondary"
                text="Download Full Report"
                icon={<RiDownloadFill />}
                onClick={() => {
                  navigate(`${url}/edit`);
                }}
              />
            </FormSection>
          </FormContainer>
        </div>
      )}
    </>
  );
}
