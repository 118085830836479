import "./ProjectInstructionsPage.css"

import { ColumnDefinition, IOrderInfo } from '../../../lib/components/table/TableInterfaces';
import { DescriptionCell, NameCell, OptionsCell } from './ProjectInstructionsTableCells';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInitLoader, useServiceCallPro } from '../../../lib/hooks/useServiceCall';
import { useNavigate, useParams } from 'react-router-dom';

import { AddInstructionPopup } from "../../popups/AddInstructionPopup/AddInstructionPopup";
import { Button } from '../../../lib/components/buttons/Button';
import { ComponentService } from '../../../services/ComponentService';
import { ComponentSummaryDTO } from '../../../models/component-summary-dto';
import { FlexLayout } from '../../../lib/layouts/containers/flex/FlexLayout';
import { InstructionService } from '../../../services/InstructionService';
import { InstructionTemplateSummaryDTO } from '../../../models/intstruction-template-summary-dto';
import { InstructionsChangedEvent } from '../../../events/InstructionsChangedEvent';
import { PageContainer } from '../../../lib/layouts/containers/page/PageContainer';
import { PlusSVG } from '../../../lib/assets/icons';
import { ResponsiveDataTable } from '../../../lib/components/table/ResponsiveDataTable';
import { Spacer } from '../../../lib/components/separator/Spacer';
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";

const instructionsService = new InstructionService();

export function ProjectInstructionsPage() {
    const [instructions, setInstructions] = useState<InstructionTemplateSummaryDTO[]>([]);
    const navigate = useNavigate();

    const {projectId} = useParams();
    const openPopup = usePopup();

    const projectIdAsNumber = useMemo(() =>  parseInt(projectId||"0"), [projectId]);

    const initLoader = useInitLoader();
  
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
      {
        columnKey: "Name",
        direction: "ASC",
      }
    ]);

    const getInstructionsCall = useServiceCallPro(instructionsService.getInstructions);

  /****************************
  * DATA REQUESTS
  *****************************/

    const getInstructions = useCallback(() => {
        const request : PageModel = {
            pageNumber: currentPage,
            pageSize: itemsPerPage,
            orderOption: undefined,
            filterOptions: undefined
        //   column: orderInfoColumns[0]?.columnKey || 'Name',
        //   isDescending: orderInfoColumns[0]?.direction === 'DESC' || false,
        //   name: nameFilterFormControl.value
        }


        getInstructionsCall.invoke(request, projectIdAsNumber).then((res) => {
          setInstructions(res.items);
          setTotalItems(res.totalItems)
        });
    
      }, [getInstructionsCall.invoke, currentPage, itemsPerPage, orderInfoColumns, setInstructions, setTotalItems]);

      
/****************************
  * DATA MANIPULATION EFFECTS
 *****************************/

    useEffect(() => {
        if (!initLoader && currentPage === 0) {
            getInstructions();
        } else {
          setCurrentPage(0)
        }
      }, [itemsPerPage]);
    
      useEffect(() => {
        getInstructions();
      }, [currentPage, orderInfoColumns]);

      useEffect(() => {
        const handleInstructionsEvent = () => {
            getInstructions();
        }
    
        InstructionsChangedEvent.attachHandler(handleInstructionsEvent);
        return () => { InstructionsChangedEvent.detachHandler(handleInstructionsEvent); };
      }, [getInstructions]);


  /****************************
  * USER ACTIONS
  *****************************/
    const handleAddInstructionClicked = useCallback(() => {
      openPopup(<AddInstructionPopup projectId={projectIdAsNumber} onCompleted={getInstructions} />)
    }, [getInstructions, openPopup, projectIdAsNumber]);


      const handleClickSearch = useCallback(() => {
        getInstructions();
      }, [getInstructions]);
    
      const handleOnOrderChanged = useCallback(
        (orderedPriorities: IOrderInfo[]) => {
          setOrderInfoColumns(orderedPriorities);
        }, []);

        const handleOnPageAndItemsChanged = useCallback(
            (items: number, currentPage: number) => {
              setItemsPerPage(items);
              setCurrentPage(currentPage);
            },
            [setItemsPerPage, setCurrentPage]
          );

  /****************************
  * CSS & HTML
  *****************************/
    const columns: ColumnDefinition<InstructionTemplateSummaryDTO>[] = useMemo(() =>  [
    {
        cellRenderProp: (v) => <NameCell value={v} projectId={projectIdAsNumber} />,
        width: "40%",
        headerRender: <>Instruction</>,
        columnKey: "Name",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <DescriptionCell value={v} projectId={projectIdAsNumber} />,
        width: "40%",
        headerRender: <>Gherkin</>,
        columnKey: "Description",
        isSortable: true,
    },

    {
        cellRenderProp: (v) => <OptionsCell value={v} projectId={projectIdAsNumber} />,
        width: "15%",
        headerRender: <></>,
        columnKey: "options",
        isSortable: false,
        },
    ],[projectIdAsNumber]);



    return (

        <PageContainer className="components-page">

            <FlexLayout direction="horizontal" className="components-page-header">
                <div className="page-header-title">
                    <h1>Instructions</h1>
                </div>
                <div className="page-header-actions">
                    <Button
                        className="addproject-button"
                        icon={<PlusSVG />}
                        text="Add Instruction"
                        type="primary"
                        onClick={handleAddInstructionClicked}
                    />
                </div>
            </FlexLayout>

            <Spacer mode="vertical" px={20} />
                 
            <div className="table">

                <ResponsiveDataTable
                    items={instructions}
                    columnDefinitions={columns}
                    totalitems={totalItems}
                    currentpage={currentPage}
                    paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [5, 10, 20],
                    }}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                    onOrderChanged={handleOnOrderChanged}
                    isLoading={getInstructionsCall.isLoading}
                    onClickRow={(item) => navigate(item.instructionTemplateId)}
                    resizeThreshold={510}
    
                ></ResponsiveDataTable>
            </div>

        </PageContainer>
    )
}