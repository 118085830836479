import { DiamondSVG, RoundBottomFlaskSideSVG, ScienceSVG, TestTubeSVG, WarningMessageSVG } from '../../../lib/assets/icons';
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useSetSelectedProject, useSetSelectedTestSuite } from '../../../lib/infrastructure/ui/UIServices';

import { ExecutionDetailsPage } from '../../../features/project/test-suites/execution-details/ExecutionDetailsPage';
import { ExecutionsHomePage } from "../../../features/project/test-suites/executions/ExecutionsHomePage";
import { MenuItem } from "../../../lib/components/side-bar/MenuItem";
import { ProjectComponentsPage } from '../../../features/project/components/ProjectComponentsPage';
import { ProjectOverviewPage } from "../../../features/project/overview/ProjectOverviewPage";
import { ProjectTestSuitesPage } from "../../../features/project/test-suites/ProjectTestSuitesPage";
import { Sidebar } from "../../../lib/components/side-bar/Sidebar";
import { SuiteComponentsPage } from "../../../features/project/test-suites/components/SuiteComponentsPage";
import { SuiteSetService } from '../../../services/SuiteSetService';
import { TestDefinitionsPage } from "../../../features/project/test-suites/test-definitions/TestDefinitionsPage";

const sideBarButtons: MenuItem[] = [
    {
        icon: <RoundBottomFlaskSideSVG />,
        navigateTo: "executions/"
    },
    {
        icon: <TestTubeSVG />,
        navigateTo: "tests/"
    },
    // {
    //     icon: <ScienceSVG />,
    //     navigateTo: "components/" // FOR THE FUTURE
    // }
];


var suiteSvc = new SuiteSetService();

export function TestSuiteModule() {



    const [selectedTestSuite, setSelectedTestSuite] = useSetSelectedTestSuite();
    const location = useLocation();
    const { projectId, suiteId } = useParams();
    
    const suiteIdAsNumber = useMemo(() => parseInt(suiteId || "0"), [suiteId]);
    const projectIdAsNumber = useMemo(() => parseInt(projectId || "0"), [projectId]);

    useEffect(() => {
        if (suiteId && projectId && suiteId != selectedTestSuite?.id) {
            suiteSvc.getSuiteSet(suiteIdAsNumber, projectIdAsNumber).then((res) => {
                setSelectedTestSuite({ id: suiteId, name: res?.name || "-" });
            });
        }
    }, [suiteId, projectId, location]);


    useEffect(() => {
        return () => setSelectedTestSuite(undefined);
    }, []);
    
    return (
        <div className="main-content">
            <div className="sidebar-content">
                <Sidebar buttons={sideBarButtons} className="secondary-sidebar"/>
            </div>

            <div className="content">
                <Routes>
                    <Route index element={<Navigate to="executions" />} /> 
                    <Route path="executions" element={<ExecutionsHomePage />} />
                    <Route path="executions/:executionId" element={<ExecutionDetailsPage />} />
                    <Route path="tests" element={<TestDefinitionsPage />} />
                    {/* <Route path="components" element={<SuiteComponentsPage />} /> FOR THE FUTURE */}
                </Routes>
            </div>
        </div>
    );
}
