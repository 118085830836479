import "./ProjectTestSuitesPage.css"

import { ColumnDefinition, IOrderInfo } from '../../../lib/components/table/TableInterfaces';
import { NameCell, OptionsCell, StatsCell, TestTypeCell } from './ProjectTestSuitesTableCells';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInitLoader, useServiceCallPro } from '../../../lib/hooks/useServiceCall';
import { useNavigate, useParams } from 'react-router-dom';

import { AddTestSuitePopup } from "../../popups/AddTestSuitePopup/AddTestSuitePopup";
import { Button } from '../../../lib/components/buttons/Button';
import { FlexLayout } from '../../../lib/layouts/containers/flex/FlexLayout';
import { JSX } from "react/jsx-runtime";
import { PageContainer } from '../../../lib/layouts/containers/page/PageContainer';
import { PlusSVG } from '../../../lib/assets/icons';
import { ResponsiveDataTable } from '../../../lib/components/table/ResponsiveDataTable';
import { Spacer } from '../../../lib/components/separator/Spacer';
import { SuiteSetService } from "../../../services/SuiteSetService";
import { SuiteSetSummaryDTO } from "../../../models/suite-set-summary-dto";
import { TestSuitesChangedEvent } from '../../../events/TestSuitesChangedEvent';
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";

const suiteSetService = new SuiteSetService(); 

export function ProjectTestSuitesPage() {
    const [testSuites, setTestSuites] = useState<SuiteSetSummaryDTO[]>([]);
    const navigate = useNavigate();
    const openPopup = usePopup();
    const {projectId} = useParams();

    const projectIdAsNumber = useMemo(() =>  parseInt(projectId||"0"), [projectId]);

    const initLoader = useInitLoader();
  
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
      {
        columnKey: "Name",
        direction: "ASC",
      }
    ]);

    const getTestSuitesCall = useServiceCallPro(suiteSetService.getSuiteSets);

  /****************************
  * DATA REQUESTS
  *****************************/

    const getTestSuites = useCallback(() => {
        const request : PageModel = {
            pageNumber: currentPage,
            pageSize: itemsPerPage,
            orderOption: undefined,
            filterOptions: undefined
        //   column: orderInfoColumns[0]?.columnKey || 'Name',
        //   isDescending: orderInfoColumns[0]?.direction === 'DESC' || false,
        //   name: nameFilterFormControl.value
        }


        getTestSuitesCall.invoke(request, projectIdAsNumber).then((res) => {
          setTestSuites(res.items);
          setTotalItems(res.totalItems)
        });
    
      }, [getTestSuitesCall.invoke, currentPage, itemsPerPage, orderInfoColumns, setTestSuites, setTotalItems]);

      
/****************************
  * DATA MANIPULATION EFFECTS
 *****************************/

    useEffect(() => {
        if (!initLoader && currentPage === 0) {
            getTestSuites();
        } else {
          setCurrentPage(0)
        }
      }, [itemsPerPage]);
    
      useEffect(() => {
        getTestSuites();
      }, [currentPage, orderInfoColumns]);

      useEffect(() => {
        const handleTestSuitesEvent = () => {
            getTestSuites();
        }
    
        TestSuitesChangedEvent.attachHandler(handleTestSuitesEvent);
        return () => { TestSuitesChangedEvent.detachHandler(handleTestSuitesEvent); };
      }, [getTestSuites]);


  /****************************
  * USER ACTIONS
  *****************************/
    const handleAddTestSuiteClicked = useCallback(() => {
      openPopup(<AddTestSuitePopup onCompleted={() => { getTestSuites(); } } projectId={projectIdAsNumber} />)
    }, [getTestSuites, openPopup, projectIdAsNumber]);


      const handleClickSearch = useCallback(() => {
        getTestSuites();
      }, [getTestSuites]);
    
      const handleOnOrderChanged = useCallback(
        (orderedPriorities: IOrderInfo[]) => {
          setOrderInfoColumns(orderedPriorities);
        }, []);

        const handleOnPageAndItemsChanged = useCallback(
            (items: number, currentPage: number) => {
              setItemsPerPage(items);
              setCurrentPage(currentPage);
            },
            [setItemsPerPage, setCurrentPage]
          );

  /****************************
  * CSS & HTML
  *****************************/
    const columns: ColumnDefinition<SuiteSetSummaryDTO>[] = useMemo(() =>  [
    {
        cellRenderProp: (v) => <NameCell value={v} projectId={projectIdAsNumber} />,
        width: "40%",
        headerRender: <>TestSuite</>,
        columnKey: "Name",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <TestTypeCell value={v} projectId={projectIdAsNumber} />,
        width: "40%",
        headerRender: <>Type</>,
        columnKey: "Description",
        isSortable: true,
    },

    {
        cellRenderProp: (v) => <StatsCell value={v} projectId={projectIdAsNumber} />,
        width: "40%",
        headerRender: <>Status</>,
        columnKey: "status",
        isSortable: true,
    },

    {
        cellRenderProp: (v) => <OptionsCell value={v} projectId={projectIdAsNumber} />,
        width: "15%",
        headerRender: <></>,
        columnKey: "options",
        isSortable: false,
    },

    {
        cellRenderProp: (v) => <>Run</>,
        width: "15%",
        headerRender: <>Run</>,
        columnKey: "run",
        isSortable: false,
    },
    ],[projectIdAsNumber]);



    return (

        <PageContainer className="components-page">

            <FlexLayout direction="horizontal" className="components-page-header">
                <div className="page-header-title">
                    <h1>TestSuites</h1>
                </div>
                <div className="page-header-actions">
                    <Button
                        className="addproject-button"
                        icon={<PlusSVG />}
                        text="Add TestSuite"
                        type="primary"
                        onClick={handleAddTestSuiteClicked}
                    />
                </div>
            </FlexLayout>

            <Spacer mode="vertical" px={20} />
                 
            <div className="table">

                <ResponsiveDataTable
                    items={testSuites}
                    columnDefinitions={columns}
                    totalitems={totalItems}
                    currentpage={currentPage}
                    paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [5, 10, 20],
                    }}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                    onOrderChanged={handleOnOrderChanged}
                    isLoading={getTestSuitesCall.isLoading}
                    onClickRow={(item) => navigate(""+item.suiteSetId)}
                    resizeThreshold={510}
    
                ></ResponsiveDataTable>
            </div>

        </PageContainer>
    )
}