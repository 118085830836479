import "./AddTestSuitePopup.css";

import { Optional, ValidatorFunction, requiredValidator, useFormControl } from "../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { ComponentService } from "../../../services/ComponentService";
import { ComponentSummaryDTO } from "../../../models/component-summary-dto";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectMultiple } from "../../../lib/components/form/form-field/FormFieldSelectMultiple";
import { FormFieldSelectSingle } from "../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { SearchSVG } from "../../../lib/assets/icons";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { SuiteSetCreateDTO } from "../../../models/suite-set-create-dto";
import { SuiteSetService } from "../../../services/SuiteSetService";
import { TestType } from "../../../models/suite-set-test-type";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IProps {
  projectId: number;
  onCompleted: () => void;
}

export function lengthValidator(n: number): ValidatorFunction<Optional<string>> {
  return (value) => {
    if (value !== undefined) {
      if (value.length >= n) {
        return "This field exceeds maximum length!";
      }
    }
    return null;
  };
}


const suiteSetService = new SuiteSetService();
const componentService = new ComponentService();

const labelTestTypeSelector = (item: string) => item;
const labelComponentSelector = (item: ComponentSummaryDTO) => item.name + " - " + item.projectName;
const idComponentSelector = (item: ComponentSummaryDTO) => item.componentId;


export function AddTestSuitePopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const [components, setComponents] = useState<ComponentSummaryDTO[]>([]);

  const nameFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator(50)],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const descriptionFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const testTypeFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: undefined,
  });
  const componentFormControl = useFormControl<ComponentSummaryDTO[]>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: [
      // {
      //   componentId: 0,
      //   name: "",
      //   description: "",
      //   projectId: 0,
      //   projectName: "",
      // },
    ],
  });

  const createTestSuiteCall = useServiceCallPro(suiteSetService.createSuiteSet);
  const getComponentsCall = useServiceCallPro(componentService.getComponents);



  /****************************
   * DATA REQUESTS
   *****************************/

  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  const isFormValid = useMemo(() =>
    nameFormControl.isValid && descriptionFormControl.isValid && testTypeFormControl.isValid && componentFormControl.isValid
    && nameFormControl.value && testTypeFormControl.value
    , [nameFormControl.isValid, nameFormControl.value, descriptionFormControl.isValid, testTypeFormControl.isValid, testTypeFormControl.value, componentFormControl.isValid]);

  const isButtonDisabled = useMemo(() => {
    return !isFormValid || createTestSuiteCall.isLoading || getComponentsCall.isLoading;
  }, [isFormValid, createTestSuiteCall.isLoading, getComponentsCall.isLoading]);

  useEffect(() => {
    getComponentsCall.invoke(undefined, props.projectId).then((response) => {
      if (response.items.length === 0) componentFormControl.setIsDisabled(true);
      else setComponents(response.items);
    }).catch((error) => {
      openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
    });
  }, []);


  /****************************
  * USER ACTIONS
  *****************************/

  const handleAddTestSuiteClicked = useCallback(() => {

    if (!isFormValid || createTestSuiteCall.isLoading) return;

    const suiteSet: SuiteSetCreateDTO = {
      name: nameFormControl.value as string,
      description: descriptionFormControl.value,
      projectId: props.projectId,
      testType: testTypeFormControl.value as string,
      componentIds: componentFormControl.value?.map((c) => c.componentId),
      testIds: [],
    };

    createTestSuiteCall.invoke(suiteSet).then((response) => {

      openPopup(<SuccessPopup>
        <div>Test Suite created successfully!</div>
      </SuccessPopup>);

      props.onCompleted();
    }).catch((err) => {
      console.log(err);
      openPopup(<ErrorPopup><div>{err.response.data.message}</div></ErrorPopup>);
    });

  },
    [isFormValid, createTestSuiteCall, nameFormControl.value, descriptionFormControl.value, props, testTypeFormControl.value, componentFormControl.value, openPopup]
  );

  /****************************
  * CSS & HTML
  *****************************/

  if (createTestSuiteCall.isLoading || getComponentsCall.isLoading) {
    return (
      <PopupContainer className="add-test-suite-popup">
        <Loader />
      </PopupContainer>
    )
  }

  return (
    <PopupContainer className="add-test-suite-popup">

      <h3>Add test suite</h3>
      <Spacer mode="vertical" px="16" />

      <FormContainer>
        <FormFieldTextInput id="name" label="Name" placeholder="Insert name" formControl={nameFormControl} />
        <FormFieldTextArea label="Description" placeholder="Insert description" formControl={descriptionFormControl} />
        <FormFieldSelectSingle
          label="Type of Test"
          placeholder="Select an option"
          formControl={testTypeFormControl}
          options={Object.getOwnPropertyNames(TestType).filter((prop) =>
            isNaN(parseInt(prop))
          )}
          labelSelector={labelTestTypeSelector}
          idSelector={labelTestTypeSelector}
          icon={<SearchSVG />}
        />
        <FormFieldSelectMultiple
          label="Components"
          placeholder="Select an option"
          formControl={componentFormControl}
          options={components}
          labelSelector={labelComponentSelector}
          idSelector={idComponentSelector}
          icon={<SearchSVG />}
        />
      </FormContainer>

      <Spacer mode="vertical" px="16" />
      <LineSeparator />

      <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >

        <TextButton text="Cancel" type="white" onClick={() => closePopup()} />
        <Button text="Add" type="primary" isDisabled={isButtonDisabled} onClick={handleAddTestSuiteClicked} />

      </FlexLayout>

    </PopupContainer>
  );
}


