import {
  RiAlertFill,
  RiEdit2Fill,
  RiEditFill,
  RiPlayFill,
  RiRunFill,
  RiStopCircleFill,
} from "react-icons/ri";
import { Button } from "../../lib/components/buttons/Button";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { useCallback, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { ComponentService } from "../../services/ComponentService";
import { ComponentDetailsDTO } from "../../models/component-details-dto";
import { SuiteSetService } from "../../services/SuiteSetService";
import { SuiteSetDetailsDTO } from "../../models/suite-set-details-dto";
import { ListComponentsTable } from "../component/ListComponentsTable";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { TestEditorPopupAdd } from "../tests/TestEditorPopupAdd";
import { JSX } from "react/jsx-runtime";
import { usePopup } from "../../lib/infrastructure/ui/UIServices";
import { ListTestsTable } from "../tests/ListTestsTable";
import { ListExecutionsTable } from "../executions/ListExecutionsTable";
import { ExecutionService } from "../../services/ExecutionService";
import { ExecutionStatus } from "../../models/execution-status";
import { IOrderInfo } from "../../lib/components/table/TableInterfaces";
import { DateTime } from "luxon";
import { ExecutionCreateDTO } from "../../models/execution-create-dto";
const suiteSetService = new SuiteSetService();
const executionService = new ExecutionService();
export function ConsultSuiteSet() {
  const openPopup = usePopup();
  const navigate = useNavigate();
  const [suiteSet, setSuiteSet] = React.useState<SuiteSetDetailsDTO>();
  const [isNotFound, setIsNotFound] = React.useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = React.useState<boolean>(false);
  const [isRunning, setIsRunning] = React.useState<boolean>(false);
  const url = window.location.pathname;
  const id = parseInt(useParams().suiteSetId?.toString() || "0");
  const projectId = parseInt(useParams().projectId?.toString() || "0");
  const suiteSetId = parseInt(useParams().suiteSetId?.toString() || "0");
  useEffect(() => {
    if (id === undefined) {
      setIsNotFound(true);
    }
    suiteSetService
      .getSuiteSet(id, projectId)
      .then((datat) => {
        if (datat != null) {
          setSuiteSet(datat);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          setIsNetworkError(true);
        } else if (error.response?.status === 404) {
          console.log("Not found!");
          setIsNotFound(true);
        }
      });

    var pageModel = {
      pageNumber: undefined,
      pageSize: undefined,
      orderOption: undefined,
      filterOptions: undefined,
    } as PageModel;
    executionService
      .getExecutions(pageModel, projectId, suiteSetId)
      .then((data) => {
        var items = data.items.find(
          (item) =>
            item.status === ExecutionStatus[ExecutionStatus.Pending] ||
            item.status === ExecutionStatus[ExecutionStatus.Running]
        );
        if (items != null) {
          setIsRunning(data.items.length > 0);
        } else {
          setIsRunning(false);
        }
      });
  }, []);

  const handleExecutionRun = useCallback(async () => {
    const res = await executionService.createExecution(
      {
        projectId: projectId,
        suiteSetId: suiteSetId,
        executionDate: DateTime.utc().toISO(),
      } as ExecutionCreateDTO,
      suiteSetId
    );
    if (res) {
      setIsRunning(true);
    }
  }, [projectId, suiteSetId]);
  if (isNotFound) {
    navigate("/notfound");
  } else if (isNetworkError) {
    navigate("/networkerror");
  }

  return (
    <div>
      <FormContainer>
        <h1>{suiteSet?.name ? suiteSet.name : "None"}</h1>
        <FormSection title="Details" unsetFlexGrow>
          <InfoBlock
            label="Project"
            value={suiteSet?.projectName ? suiteSet.projectName : "None"}
          />
          <InfoBlock
            label="Test Type"
            value={suiteSet?.testType ? suiteSet.testType : "None"}
          />

          <InfoBlock
            label="Description"
            value={suiteSet?.description ? suiteSet.description : "None"}
          />

          <Button
            type="secondary"
            text="Edit"
            icon={<RiEdit2Fill />}
            onClick={() => {
              navigate(`${url}/edit`);
            }}
          />
          <Button
            type="primary"
            text={isRunning ? "Running" : "Run"}
            icon={isRunning ? <RiRunFill /> : <RiPlayFill />}
            isDisabled={isRunning}
            onClick={() => {
              handleExecutionRun();
            }}
          />
        </FormSection>
        <ListExecutionsTable />
        <ListTestsTable items={suiteSet?.tests || []} />
        <ListComponentsTable items={suiteSet?.components || []} />
      </FormContainer>
    </div>
  );
}
