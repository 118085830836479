import { EditSVG, RemoveSVG } from "../../../../lib/assets/icons";

import { EditTestDefinitionPopup } from "../../../popups/EditTestDefinitionPopup/EditTestDefinitionPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TestService } from '../../../../services/TestService';
import { TestSummaryDTO } from "../../../../models/test-summary-dto";
import { TestsChangedEvent } from "../../../../events/TestsChangedEvent";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { useCallback } from "react";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

interface ICellProps {
    value: TestSummaryDTO
    projectId: number
    suiteId: number
}

const testsService = new TestService();

export const NameCell = (props: ICellProps) => {
    return (
        <div className="name-cell">
            <div className="label-name">{props.value.name}</div>
        </div>
    );
};

export const DescriptionCell = (props: ICellProps) => {
    return (
        <div className="description-cell">
            <div className="label-name">{props.value.description}</div>
        </div>
    );
};

export const PriorityCell = (props: ICellProps) => {
    return (
        <div className="priority-cell">
            <div className="label-name">{props.value.priority}</div>
        </div>
    );
};


export const OptionsCell = (props: ICellProps) => {
    const openPopup = usePopup();

    const deleteCall = useServiceCallPro(testsService.deleteTest);

    /****************************
    * DATA REQUESTS
    *****************************/

    const deleteTest = useCallback(() => {
        deleteCall.invoke(props.value.testId, props.projectId, props.suiteId)
            .then(() => {
                openPopup(
                    <SuccessPopup>Test removed successfully!</SuccessPopup>
                );
                TestsChangedEvent.fireTestsChanged();
            })
            .catch((error) => {
                if (error.response.data) {
                    openPopup(
                        <ErrorPopup>{error.response.data}</ErrorPopup>
                    );
                } else {
                    openPopup(
                        <ErrorPopup>{error.message}</ErrorPopup>
                    );
                }
            });
    }, [deleteCall, props.value.testId, props.projectId, props.suiteId, openPopup]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleOnDeleteConfirmClicked = useCallback(() => {
        deleteTest();
    }, [deleteTest]);

    const handleEditProjectClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
            <EditTestDefinitionPopup
                projectId={props.projectId}
                suiteId={props.suiteId}
                testId={props.value.testId}
                onCompleted={() => TestsChangedEvent.fireTestsChanged()} />)
    }, [openPopup, props.value.testId]);


    const handleRemoveIconClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
            <WarningPopup onConfirmClicked={handleOnDeleteConfirmClicked}>
                Are you sure you want to delete this test?
            </WarningPopup>
        )
    }, [handleOnDeleteConfirmClicked, openPopup]);


    /****************************
    * CSS & HTML
    *****************************/

    return (
        <div>
            <FlexLayout className="options-cell" direction="horizontal">
                <IconButton size={40} type="tertiary" icon={<EditSVG />} onClick={handleEditProjectClicked} />
                <IconButton size={40} type="tertiary" icon={<RemoveSVG />} onClick={handleRemoveIconClicked} />
            </FlexLayout>
        </div>
    );
};