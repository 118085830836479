import React, { useMemo } from "react";

import { ReactComponent as ArrowDownSVGComponent } from "./icons/arrowDown.svg";
import { ReactComponent as ArrowSVGComponent } from "./icons/arrowRight.svg";
import { ReactComponent as BookSVGComponent } from './icons/book.svg';
import { ReactComponent as CircleArrowSVGComponent } from "./icons/circle-arrow.svg";
import { ReactComponent as CloseSVGComponent } from "./icons/close.svg";
import { ReactComponent as DiamondSVGComponent } from "./icons/diamond.svg";
import { ReactComponent as DownloadSVGComponent } from "./icons/download.svg";
import { ReactComponent as EditSVGComponent } from "./icons/edit.svg";
import { ReactComponent as ErrorSVGComponent } from "./icons/error-big.svg";
import { ReactComponent as HeartSVGComponent } from "./icons/heart-icon.svg";
import { ReactComponent as PlaySVGComponent } from "./icons/play.svg";
import { ReactComponent as PlusSVGComponent } from "./icons/plus.svg";
import { ReactComponent as RejectDarkSVGComponent } from "./icons/reject-dark.svg";
import { ReactComponent as RejectRoundSVGComponent } from "./icons/reject-round.svg";
import { ReactComponent as RejectSVGComponent } from "./icons/reject.svg";
import { ReactComponent as RemoveSVGComponent } from "./icons/remove.svg";
import { ReactComponent as RoundBottomFlaskSVGComponent } from './icons/round-bottom-flask.svg';
import { ReactComponent as RoundBottomFlaskSideSVGComponent } from './icons/round-bottom-flask-side.svg';
import { ReactComponent as ScienceSVGComponent } from "./icons/science.svg";
import { ReactComponent as SearchSVGComponent } from "./icons/search-icon.svg";
import { ReactComponent as SuccessSVGComponent } from "./icons/success-big.svg";
import { ReactComponent as TestTubeSVGComponent } from './icons/test-tube.svg';
import { ReactComponent as WarningMessageSVGComponent } from "./icons/warning-message.svg";
import { ReactComponent as WarningPageSVGComponent } from "./icons/warning-page-icon.svg";
import { ReactComponent as WarningV2SVGComponent } from "./icons/warning-icon-v2.svg";

export const CircleArrowSVG = withWrapper(CircleArrowSVGComponent)
export const RemoveSVG = withWrapper(RemoveSVGComponent);
export const DiamondSVG = withWrapper(DiamondSVGComponent);
export const WarningMessageSVG = withWrapper(WarningMessageSVGComponent);
export const PlusSVG = withWrapper(PlusSVGComponent);
export const ErrorSVG = withWrapper(ErrorSVGComponent);
export const SuccessSVG = withWrapper(SuccessSVGComponent);
export const HeartSVG = withWrapper(HeartSVGComponent);
export const ArrowSVG = withWrapper(ArrowSVGComponent);
export const ArrowDownSVG = withWrapper(ArrowDownSVGComponent);
export const EditSVG = withWrapper(EditSVGComponent);
export const CloseSVG = withWrapper(CloseSVGComponent);
export const SearchSVG = withWrapper(SearchSVGComponent)


export const RoundBottomFlaskSVG = withWrapper(RoundBottomFlaskSVGComponent, 38);
export const RoundBottomFlaskSideSVG = withWrapper(RoundBottomFlaskSideSVGComponent, 32);
export const BookSVG = withWrapper(BookSVGComponent, 26);
export const ScienceSVG = withWrapper(ScienceSVGComponent, 26);
export const TestTubeSVG = withWrapper(TestTubeSVGComponent, 26);



export const DownloadSVG = withWrapper(DownloadSVGComponent)
export const PlaySVG = withWrapper(PlaySVGComponent)
export const RejectDarkSVG= withWrapper(RejectDarkSVGComponent)
export const RejectRoundSVG = withWrapper(RejectRoundSVGComponent)
export const RejectSVG = withWrapper(RejectSVGComponent)

export const WarningV2SVG = withWrapper(WarningV2SVGComponent)
export const WarningPageSVG = withWrapper(WarningPageSVGComponent)





function withWrapper(Component: any, baseSizePx?: number) {
    return (props: React.SVGProps<SVGSVGElement>) => <SVGWrapper svg={Component} {...props} style={{"fontSize": baseSizePx ? baseSizePx+"px" : undefined}} />;
}

interface SVGWrapperProps extends React.SVGProps<SVGSVGElement> {
    svg: any
}

export const SVGWrapper = (props: SVGWrapperProps) => {

    const component = useMemo(() => {
    
        return React.createElement(props.svg, {...props, 
            svg:undefined, 
            onClick: (ev:React.MouseEvent<SVGSVGElement, MouseEvent>) =>{
                if(props.onClick){
                    ev.stopPropagation();
                    props.onClick(ev);
                }
            }
        });
    }, [props])

    return component;
};
