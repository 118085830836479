export class ExecutionsChangedEvent {
 static fireExecutionsChanged() {
    let event = new CustomEvent<void>("executions-changed");
    window.dispatchEvent(event);
 }

 static attachHandler(handler: EventListener){
    window.addEventListener("executions-changed", handler);
 }

 static detachHandler(handler: EventListener){
    window.removeEventListener("executions-changed", handler);
 }
}