import "./SelectMultipleNoSearch.css";

import { isNull, isNullOrEmpty } from "../../../utils/ValidationChecks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as ArrowDownSVG } from "../../../assets/icons/arrowDown.svg";
import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import React from "react";
import { ReactComponent as SelectedOptionSVG } from "../../../assets/icons/check.svg";
import { TextButton } from "../../buttons/TextButton";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useOutsideComponentClickTrigger } from "../../../hooks/useOutsideComponentClickTrigger";

interface ISelectMultipleNoSearchProps<T> {
    value?: T[]; // TODO: Hadle Initial Value
    options: T[];
    labelSelector: (value: T) => string;
    idSelector: (value: T) => string | number;
    className?: string;
    placeholder?: string;
    isDisabled?: boolean;
    hasError?: boolean;
    maxHeightOptions?: string;
    onChange?: (values?: T[]) => void;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    icon?: React.ReactNode;
}

interface ISelectedSimpleOption<T> {
    value?: T;
    id: string | number;
    label: string;
}

/****************************
 * NEEDS REFACTOR AND CLEAN UP
 * ****************************
 * ****************************
 * ****************************
 * ****************************
 * 
 * 
 * 
 * 
 * ****************************
 * ****************************
 * ****************************
 * 
 * ********************************************************
 * ****************************
 * ****************************
 * 
 *****************************/

export function SelectMultipleNoSearch<T>(props: ISelectMultipleNoSearchProps<T>) {
    const nativeInputRef = useRef<HTMLInputElement>(null);
    const componentRef = useRef<HTMLDivElement>(null);

    const [inputValue, setInputValue] = useState<string>();
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<ISelectedSimpleOption<T>[]>(
        []
    );
    const [mode, setMode] = useState<"value" | "search">("value");
    const [highlightedOption, setHighlightedOption] = useState<number>(-1);

    /****************************
     * NEEDS REFACTOR AND CLEAN UP
     * ****************************
     * ****************************
     * ****************************
     * ****************************
     * 
     * 
     * 
     * 
     * ****************************
     * ****************************
     * ****************************
     * 
     * ********************************************************
     * ****************************
     * ****************************
     * 
     *****************************/


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    const placeHolder = useMemo(
        () => !isNull(props.placeholder) ? props.placeholder : translate("DESIGNSYSTEM.TypeHere"),
        [props.placeholder]
    );

    const options = useMemo(() => {
        let mappedOptions = props.options.map((opt): ISelectedSimpleOption<T> => {
            const label = props.labelSelector(opt) || "**EMPTY VALUE**";
            const id = props.idSelector(opt);

            if (isNullOrEmpty(id)) {
                console.error("********* There are options without ID!! *********");
            }
            return {
                value: opt,
                label: label,
                id: id,
            };
        });
        return mappedOptions;
    }, [props.options, props.idSelector, props.labelSelector]);



    useEffect(() => {
        setHighlightedOption(-1);
        if (mode === "search") {
            nativeInputRef.current?.focus();
            setInputValue("");
            setIsPanelOpen(true);
        } else {
            setIsPanelOpen(false);
        }
    }, [mode]);



    useEffect(() => {
        if (props.value) {
            setSelectedOptions(
                props.value.map((value) => {
                    return {
                        value: value,
                        id: props.idSelector(value),
                        label: props.labelSelector(value),
                    };
                })
            );
        }
    }, [options, props.value]);



    /****************************
     * USER ACTIONS
     *****************************/


    const handleSelectSingleClicked = useCallback(() => {
        if (!props.isDisabled) setMode("search");
    }, [setMode, props.isDisabled]);



    const handleItemSelected = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent> | null, option: ISelectedSimpleOption<T>) => {
        ev?.stopPropagation();

        let valuesArray: ISelectedSimpleOption<T>[] = [];

        if (!selectedOptions.some((opt) => opt.id === option.id)) {
            valuesArray = [
                ...selectedOptions,
                {
                    value: option.value,
                    id: option.id,
                    label: option.label,
                },
            ];
        } else {
            valuesArray = selectedOptions.filter((opt) => opt.id !== option.id);
        }

        setSelectedOptions(valuesArray);

        props.onChange &&
            props.onChange(
                valuesArray.map((value) => {
                    return value.value as T;
                })
            );
    },
        [selectedOptions, setSelectedOptions, props.onChange]
    );



    const handleSelectAllClicked = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev?.stopPropagation();

            let valuesArray: T[] = [];
            options.forEach((opt) => {
                if (opt.value) valuesArray.push(opt.value);
            });

            setSelectedOptions([...options]);
            props.onChange && props.onChange(valuesArray);
        },
        [props.onChange, setSelectedOptions, options]
    );



    const handleClearSelectionClicked = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev?.stopPropagation();
            setSelectedOptions([]);
            props.onChange && props.onChange(undefined);
        },
        [props.onChange, setSelectedOptions]
    );



    useOutsideComponentClickTrigger(componentRef, () => {
        setInputValue("");
        setMode("value");
    });



    /****************************
     * CSS & HTML
     *****************************/



    const selectMultipleCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("select-multiple-simple")
            .addConditional(props.className, props.className)
            .addConditional(selectedOptions.length, "has-selected-options")
            .addConditional(props.hasError, "error")
            .addConditional(props.isDisabled, "disabled")
            .addConditional(props.icon, "has-icon")
            .build();
    }, [
        props.className,
        props.hasError,
        props.isDisabled,
        props.icon,
        selectedOptions,
    ]);



    const panelStyle = useMemo(() => {
        let panelObject = {};
        if (componentRef.current && selectedOptions.length) {
            let inputHeight = componentRef.current.clientHeight;
            panelObject = {
                ...panelObject,
                top: inputHeight + 6,
            };
        }
        if (props.maxHeightOptions) {
            panelObject = {
                ...panelObject,
                maxHeight: props.maxHeightOptions,
            };
        }

        return panelObject;
    }, [selectedOptions, props.maxHeightOptions, componentRef]);



    const optionsHTML = useMemo(
        () =>
            options.map((opt, idx) => {
                const id = opt.id;

                const isSelected = selectedOptions.some((opt) => opt.id === id);

                const css = CssClassnameBuilder.new()
                    .add("multiple-select-option")
                    .addConditional(isSelected, "selected")
                    .addConditional(highlightedOption === idx, "hover")
                    .build();

                return (
                    <div
                        key={id}
                        className={css}
                        onClick={(ev) => handleItemSelected(ev, opt)}
                    >
                        <span>{opt.label}</span>

                        {isSelected ? <SelectedOptionSVG className="check-option" /> : null}
                    </div>
                );
            }),
        [handleItemSelected, options, highlightedOption, selectedOptions]
    );



    const selectedOptionsTags = useMemo(() => {
        if (selectedOptions.length) {
            return (
                <div className="select-multiple-simple-selected-options">
                    {selectedOptions.map((opt, idx) => {
                        return (
                            <OptionSelectedTag
                                key={idx}
                                value={opt.label}
                            />
                        );
                    })}
                </div>
            );
        } else return null;
    }, [selectedOptions]);



    return (
        <div
            ref={componentRef}
            className={selectMultipleCss}
            onClick={handleSelectSingleClicked}
        >
            <div className="select-multiple-simple-box">
                {props.icon ? (
                    <div className="select-multiple-simple-icon">{props.icon}</div>
                ) : null}

                <input
                    ref={nativeInputRef}
                    className="native-input"
                    placeholder={selectedOptions.length ? "" : placeHolder}
                    readOnly
                    type="text"
                ></input>

                {selectedOptionsTags}

                <ArrowDownSVG className="arrow-down-icon" />
            </div>

            {isPanelOpen ? (
                <div className="select-multiple-simple-options-panel" style={panelStyle}>
                    <div className="multiple-select-actions">
                        <TextButton
                            text={translate("DESIGNSYSTEM.All")}
                            className="mini-text-button"
                            onClick={handleSelectAllClicked}
                        />
                        <div className="dot-separator"></div>
                        <TextButton
                            text={translate("DESIGNSYSTEM.Clear")}
                            className="mini-text-button"
                            onClick={handleClearSelectionClicked}
                        />
                    </div>
                    {optionsHTML}
                </div>
            ) : null}
        </div>
    );
}



interface IOptionSelectedTagProps {
    value: string;
}



export function OptionSelectedTag(props: IOptionSelectedTagProps) {
    return (
        <div className="selected-option-tag">
            <span>{props.value}</span>
        </div>
    );
}
