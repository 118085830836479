import "./DataTable.css";

import {
  ColumnDefinition,
  IOrderInfo,
  PaginationOptions,
} from "./TableInterfaces";
import { ITableRowProps as ITableRow, TableRow } from "./table-row/TableRow";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ArrayHelper } from "../../utils/ArrayHelper";
import { ReactComponent as ArrowDownSVG } from "../../assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUpSVG } from "../../assets/icons/arrowUp.svg";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { Empty } from "./empty/Empty";
import { ITableCellProps as ITableCell } from "./table-cell/TableCell";
import { Loader } from "../loader/Loader";
import { TableFooter } from "./table-footer/TableFooter";
import { isNull } from "../../utils/ValidationChecks";

interface IDataTableProps<T> {
  className?: string;
  rowClassName?: (cells: T) => string;
  items: T[];
  columnDefinitions: ColumnDefinition<T>[];
  totalitems: number;
  currentpage?: number;
  paginationOptions?: PaginationOptions;
  isLoading?: boolean;
  maxBodyHeight?: number;
  orderColumns?: IOrderInfo[];
  onOrderChanged?: (orderInfos: IOrderInfo[]) => void;
  //onItemsPerPageChanged?: (rowsPerPage: number, newPage: number) => void;
  //onCurrentPageChanged?: (rowsPerPage: number, newPage: number) => void;
  onClickRow?: (item: T) => void;
  showSelectedRowHighlighted?: boolean;
  onPageAndItemsChanged?: (rowsPerPage: number, newPage: number) => void;
}

export function DataTable<T>(props: IDataTableProps<T>) {
  const tableRef = useRef<HTMLTableElement>(null);
  const [orderInfos, setOrderInfos] = useState<IOrderInfo[]>([]);

  const [clickedRowIdx, setClickedRowIdx] = useState<number | null>(null);

  useEffect(() => {
    setOrderInfos(props.orderColumns || []);
  }, [props.orderColumns]);

  /****************************
   * DATA MANIPULATION EFFECTS *
   *****************************/

  const defaultColumnWidth = useMemo(() => {
    const tableWidth = tableRef.current?.offsetWidth || 0;

    const columnWithoutWidthCount = props.columnDefinitions.filter(
      (col) => col.width === undefined
    ).length;

    if (columnWithoutWidthCount === 0) {
      return 0;
    }

    const tableWidthOcupated = props.columnDefinitions.reduce((sum, col) => {
      let columnWidth = 0;
      if (col.width?.includes("%")) {
        columnWidth = parseInt(col.width.split("%")[0]);
      } else if (col.width?.includes("px")) {
        let columnWidthPx = parseInt(col.width.split("px")[0]);
        columnWidth = (columnWidthPx * 100) / tableWidth;
      }
      return (sum += columnWidth);
    }, 0);

    const defaultWidth = (100 - tableWidthOcupated) / columnWithoutWidthCount;

    return defaultWidth + "%";
  }, [props.columnDefinitions, tableRef]);

  const hasItems = useMemo(() => props.items.length > 0, [props.items]);

  const isFooterDisabled = useMemo(
    () => (props.isLoading && props.currentpage === 0) || !hasItems,
    [props.isLoading, props.currentpage, hasItems]
  );

  /****************************
   * USER ACTIONS
   *****************************/

  const handleSortColumnClick = useCallback(
    (columnKey: string | undefined) => {
      if (!columnKey) return;

      let newColumnOrder: IOrderInfo = {
        columnKey: columnKey,
        direction: "NONE",
      };

      const oldColumnOrderIdx = orderInfos.findIndex(
        (ord) => ord.columnKey === columnKey
      );

      var oldColumnOrder =
        oldColumnOrderIdx > -1 ? orderInfos.at(oldColumnOrderIdx) : undefined;

      switch (oldColumnOrder?.direction) {
        case "ASC":
          newColumnOrder.direction = "DESC";
          break;
        case "DESC":
          newColumnOrder.direction = "NONE";
          break;
        default:
          newColumnOrder.direction = "ASC";
          break;
      }

      let newOrderState: IOrderInfo[] = orderInfos.filter(
        (ord) => ord.columnKey !== columnKey
      );

      switch (newColumnOrder.direction) {
        case "ASC":
          newOrderState.push(newColumnOrder);
          break;
        case "DESC":
          ArrayHelper.insertIntoPosition(
            newOrderState,
            oldColumnOrderIdx,
            newColumnOrder
          );
          break;
        default:
          break;
      }

      setOrderInfos(newOrderState);
      props.onOrderChanged && props.onOrderChanged(newOrderState);
    },
    [orderInfos, setOrderInfos, props.onOrderChanged]
  );

  const handleRowClicked = useCallback(
    (item: T) => {
      props.onClickRow && props.onClickRow(item);
    },
    [props.onClickRow]
  );

  /****************************
   * CSS && HTML
   *****************************/

  const tableCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("table-container")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const theaderCss = useMemo((): React.CSSProperties | undefined => {
    let nrOfHeaderColumnsToRender = props.columnDefinitions.filter(
      (col) => !isNull(col.headerRender) && col.isVisible !== false
    ).length;
    if (nrOfHeaderColumnsToRender !== 0) return undefined;
    return { height: 0 };
  }, [props.columnDefinitions]);

  const tableHeader = useMemo(() => {
    let nrOfHeaderColumnsToRender = props.columnDefinitions.filter(
      (col) => !isNull(col.headerRender) && col.isVisible !== false
    ).length;

    return props.columnDefinitions.map((col, idx) => {
      const thCss: React.CSSProperties = {
        width: col.width || defaultColumnWidth,
      };
      if (nrOfHeaderColumnsToRender === 0) thCss.padding = 0;

      const orderInfo = orderInfos.find(
        (order) => order.columnKey === col?.columnKey
      );

      let arrow = <></>;
      switch (orderInfo?.direction) {
        case "ASC":
          arrow = <ArrowUpSVG />;
          break;
        case "DESC":
          arrow = <ArrowDownSVG />;
          break;
        default:
          arrow = <ArrowUpSVG />;
      }

      var hasOrder = col.isSortable;

      const tableColumnHeaderCss = CssClassnameBuilder.new()
        .add("table-column-header")
        .addConditional(hasOrder, "header-clickable")
        .addConditional(props.className, props.className)
        .build();

      const orderbyCss = CssClassnameBuilder.new()
        .add("orderby-icon")
        .addConditional(hasOrder && !orderInfo, "orderby-neutral")
        .addConditional(hasOrder && orderInfo, "show-arrow")
        .build();

      return col.isVisible === false ? null : (
        <th style={thCss} key={idx}>
          <div className={tableColumnHeaderCss}>
            {col.headerRender}
            <div
              className={orderbyCss}
              onClick={() =>
                hasOrder ? handleSortColumnClick(col.columnKey) : null
              }
            >
              {hasOrder ? arrow : null}
            </div>
          </div>
        </th>
      );
    });
  }, [
    props.columnDefinitions,
    defaultColumnWidth,
    handleSortColumnClick,
    orderInfos,
    props.className,
  ]);

  const rows = useMemo(() => {
    let mappedRows = props.items.map(
      (item): ITableRow => ({
        className: props.rowClassName && props.rowClassName(item),
        cells: props.columnDefinitions
          .filter((column) => column.isVisible !== false) // Filter out invisible columns
          .map(
            (column): ITableCell => ({
              children: column.cellRenderProp && column.cellRenderProp(item),
            })
          ),
      })
    );

    return mappedRows.map((row, idx) => {
      //TODO: IN PROGRESS.

      return (
        <TableRow
          key={idx}
          className={row.className}
          cells={row.cells}
          showSelectedRowHighlighted={
            props.showSelectedRowHighlighted && clickedRowIdx === idx
              ? true
              : false
          }
          onClick={(r) => {
            handleRowClicked(props.items[idx]);
            if (idx === clickedRowIdx) {
              setClickedRowIdx(null);
            } else setClickedRowIdx(idx);
          }}
        />
      );
    });
  }, [
    props.items,
    props.columnDefinitions,
    handleRowClicked,
    props.showSelectedRowHighlighted,
    clickedRowIdx,
    setClickedRowIdx,
    props.rowClassName,
  ]);

  return (
    <div className={tableCss}>
      <table ref={tableRef}>
        <thead style={theaderCss}>
          <tr>{tableHeader}</tr>
        </thead>
        {props.isLoading ? (
          <tbody>
            <tr>
              <td colSpan={tableHeader.length}>
                <div className="table-empty-loader-container">
                  <Loader></Loader>
                </div>
              </td>
            </tr>
          </tbody>
        ) : hasItems ? (
          <tbody>{rows}</tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={tableHeader.length}>
                <div className="table-empty-loader-container">
                  <Empty />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {isFooterDisabled ? null : (
          <tfoot>
            <TableFooter
              columnCount={tableHeader.length}
              currentPage={props.currentpage}
              paginationOptions={props.paginationOptions || undefined}
              totalItems={props.totalitems}
              //onCurrentPageChanged={props.onCurrentPageChanged}
              onPageAndItemsChanged={props.onPageAndItemsChanged}
            ></TableFooter>
          </tfoot>
        )}
      </table>
    </div>
  );
}
