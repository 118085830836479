import { RiEdit2Fill, RiEditFill } from "react-icons/ri";
import { Button } from "../../lib/components/buttons/Button";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { ComponentService } from "../../services/ComponentService";
import { ComponentDetailsDTO } from "../../models/component-details-dto";
import { SuiteSetService } from "../../services/SuiteSetService";
import { SuiteSetDetailsDTO } from "../../models/suite-set-details-dto";
import { ListComponentsTable } from "../component/ListComponentsTable";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { TestEditorPopupAdd } from "./TestEditorPopupAdd";
import { JSX } from "react/jsx-runtime";
import { usePopup } from "../../lib/infrastructure/ui/UIServices";
import { ListTestsTable } from "./ListTestsTable";
import { TestService } from "../../services/TestService";
import { TestDetailsDTO } from "../../models/test-details-dto";
const testService = new TestService();

export function ConsultTest() {
  const openPopup = usePopup();
  const navigate = useNavigate();
  const [test, setTest] = React.useState<TestDetailsDTO>();
  const [isNotFound, setIsNotFound] = React.useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = React.useState<boolean>(false);
  const url = window.location.pathname;
  const id = parseInt(useParams().testId?.toString() || "0");
  const suiteSetId = parseInt(useParams().suiteSetId?.toString() || "0");
  const projectId = parseInt(useParams().projectId?.toString() || "0");
  useEffect(() => {
    if (id === undefined) {
      setIsNotFound(true);
    }
    testService
      .getTest(id, suiteSetId, projectId)
      .then((datat) => {
        if (datat != null) {
          setTest(datat);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          setIsNetworkError(true);
        } else if (error.response?.status === 404) {
          console.log("Not found!");
          setIsNotFound(true);
        }
      });
  }, []);
  if (isNotFound) {
    navigate("/notfound");
  } else if (isNetworkError) {
    navigate("/networkerror");
  }

  return (
    <div>
      <FormContainer>
        <h1>{test?.name ? test.name : "None"}</h1>
        <FormSection title="Details" childrenFlexDirColumn>
          <InfoBlock
            label="Project"
            value={test?.projectName ? test.projectName : "None"}
          />
          <InfoBlock
            label="Description"
            value={test?.description ? test.description : "None"}
          />
          <InfoBlock
            label="Requirements"
            value={test?.requirements ? test.requirements : "None"}
          />
          <InfoBlock
            label="Priority"
            value={test?.priority ? test.priority : "None"}
          />
          <Button
            type="secondary"
            text="Edit"
            icon={<RiEdit2Fill />}
            onClick={() => {
              navigate(`${url}/edit`);
            }}
          />
        </FormSection>
      </FormContainer>
    </div>
  );
}
