import {
  Optional,
  ValidatorFunction,
  requiredValidator,
  useFormControl,
} from "../../lib/components/form/Form";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormFieldDatePicker } from "../../lib/components/form/form-field/FormFieldDatePicker";
import { DateTime } from "luxon";
import { Button } from "../../lib/components/buttons/Button";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { useContext, useEffect, useState } from "react";
import { ProjectService } from "../../services/ProjectService";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { UISContext } from "../../lib/infrastructure/ui/UIServices";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { ProjectUpdateDTO } from "../../models/project-update-dto";
import { useParams } from "react-router-dom";
import React from "react";
import { ComponentService } from "../../services/ComponentService";
import { FormFieldTextArea } from "../../lib/components/form/form-field/FormFieldTextArea";
import { RiSearchLine } from "react-icons/ri";
import { FormFieldSelectSingle } from "../../lib/components/form/form-field/FormFieldSelectSingle";
import { ComponentUpdateDTO } from "../../models/component-update-dto";
import { ProjectSelectDTO } from "../../models/project-select-dto";
const componentService = new ComponentService();
const projectService = new ProjectService();
const labelSelector = (item: ProjectSelectDTO) => item.name;
const idSelector = (item: ProjectSelectDTO) => item.projectId;
export function EditComponentForm() {
  const [projects, setProjects] = useState<ProjectSummaryDTO[]>([]);
  useEffect(() => {
    projectService.getProjects(undefined).then((res) => {
      setProjects(res.items);
    });
  }, []);
  const context = useContext(UISContext);
  const nameFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator(50)],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const descriptionFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const repositoryFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator(2048)],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const accessTokenFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const projectFormControl = useFormControl<ProjectSelectDTO>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: {
      projectId: 0,
      name: "",
    },
  });
  var id = parseInt(useParams().projectId?.toString() || "0");
  const componentId = parseInt(useParams().componentId?.toString() || "0");
  useEffect(() => {
    componentService
      .getComponent(componentId, id)
      .then((datat) => {
        if (datat != null) {
          nameFormControl.setValue(datat.name);
          descriptionFormControl.setValue(datat.description);
          repositoryFormControl.setValue(datat.repositoryUrl);
          accessTokenFormControl.setValue(datat.accessToken);
          projectFormControl.setValue({
            projectId: datat.projectId,
            name: datat.projectName,
          });
        }
      })
      .catch((error) => {
        /* if (error.code === "ERR_NOT_FOUND") {
          navigate("/notfound");
        } else if (error.code === "ERR_NETWORK") {
          navigate("/networkerror");
        } */
      });
  }, []);

  /* if (useParams().id === undefined) {
    id = "0";
    return <div>Not found</div>;
  } */

  function lengthValidator(n: number): ValidatorFunction<Optional<string>> {
    return (value) => {
      if (value !== undefined) {
        if (value.length >= n) {
          return "This field exceeds maximum length!";
        }
      }
      return null;
    };
  }

  async function handleSubmit() {
    if (
      nameFormControl.validate() &&
      descriptionFormControl.validate() &&
      repositoryFormControl.validate() &&
      accessTokenFormControl.validate() &&
      projectFormControl.validate()
    ) {
      const component: ComponentUpdateDTO = {
        componentId: componentId,
        name: nameFormControl.value as string,
        projectId: projectFormControl.value?.projectId as number,
        description: descriptionFormControl.value,
        repositoryUrl: repositoryFormControl.value as string,
        accessToken: accessTokenFormControl.value as string,
      };
      try {
        const res = await componentService.updateComponent(component);
        context?.setShowPopup(true);
        context?.setPopupContent(
          <SuccessPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Component updated successfully!
          </SuccessPopup>
        );
      } catch (err: any) {
        context?.setShowPopup(true);
        const msg = err.response.data.message;
        context?.setPopupContent(
          <ErrorPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Something went wrong!{msg}
          </ErrorPopup>
        );
      }
    }
  }
  return (
    <div>
      <FormContainer>
        <h1>Update Component</h1>
        <FormFieldTextInput
          formControl={nameFormControl}
          label="Name"
          placeholder="Type something"
          id="name"
        />
        <FormFieldSelectSingle
          label="Project"
          placeholder="Select an option"
          formControl={projectFormControl}
          options={projects}
          labelSelector={labelSelector}
          idSelector={idSelector}
          icon={<RiSearchLine />}
        />
        <FormFieldTextArea
          formControl={descriptionFormControl}
          label="Description"
          placeholder="Type something"
        />
        <FormFieldTextInput
          formControl={repositoryFormControl}
          label="Repository URL"
          placeholder="Type something"
          id="Repository"
        />
        <FormFieldTextInput
          formControl={accessTokenFormControl}
          label="Access Token"
          placeholder="Type something"
          id="Access-Token"
        />

        <Button
          type="primary"
          text="Save"
          onClick={handleSubmit}
          isDisabled={
            !nameFormControl.isValid ||
            !descriptionFormControl.isValid ||
            !repositoryFormControl.isValid ||
            !accessTokenFormControl.isValid ||
            !projectFormControl.isValid
          }
        />
      </FormContainer>
    </div>
  );
}
