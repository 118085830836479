import "./IconButton.css";

import { ChangeEvent, useCallback, useMemo, useRef } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface IIconButtonFileProps {
    size?: number;
    icon: React.ReactNode;
    type: "primary" | "secondary" | "tertiary";
    isRound?: boolean;
    isDisabled?: boolean;
    className?: string;
    onFileSelected: (f: File) => void;
}

export function IconButtonFile(props: IIconButtonFileProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const sizeCss = useMemo(() => {
        if (props.size) {
            const baseFontSize = parseFloat(
                window.getComputedStyle(document.documentElement).fontSize
            );
            return {
                width: props.size / baseFontSize + "rem",
                height: props.size / baseFontSize + "rem",
            };
        }
    }, [props.size]);

    const buttonCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("icon-button")
            .addConditional(props.type, props.type)
            .addConditional(props.isRound, "round")
            .addConditional(props.isDisabled, "disabled")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className, props.type, props.isDisabled, props.isRound]);

    const handleClicked = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation();
            if (props.isDisabled) return;
            inputRef.current?.click();
        },
        [inputRef, props.isDisabled]
    );

    const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        props.onFileSelected(e.target.files[0]);

        if (inputRef.current)
            inputRef.current.value = "";
    }, [inputRef, props.onFileSelected]);

    return (
        <div
            className={buttonCss}
            style={sizeCss}
            onClick={handleClicked}
        >
            {props.icon}
            <input
                title="hidden-upload-input"
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
        </div>
    );
}
