import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";
import {
  Optional,
  ValidatorFunction,
  requiredValidator,
  useFormControl,
} from "../../lib/components/form/Form";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormFieldDatePicker } from "../../lib/components/form/form-field/FormFieldDatePicker";
import { DateTime } from "luxon";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { Form } from "react-router-dom";
import { Button } from "../../lib/components/buttons/Button";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { SectionSeparator } from "../../lib/components/form/form-section/SectionSeparator";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { TextButton } from "../../lib/components/buttons/TextButton";
import { useContext, useState } from "react";
import { ProjectCreateDTO } from "../../models/project-create-dto";
import { ProjectService } from "../../services/ProjectService";
import { text } from "body-parser";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { UISContext } from "../../lib/infrastructure/ui/UIServices";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { Axios, AxiosError } from "axios";
const projectService = new ProjectService();

export function CreateProjectForm() {
  const context = useContext(UISContext);

  const datePickerFormControl = useFormControl<DateTime>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
  });
  const textInputFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });

  function lengthValidator(): ValidatorFunction<Optional<string>> {
    return (value) => {
      if (value !== undefined) {
        if (value.length >= 50) {
          return "This field exceeds maximum length!";
        }
      }
      return null;
    };
  }

  async function handleSubmit() {
    if (textInputFormControl.validate() && datePickerFormControl.validate()) {
      const project: ProjectCreateDTO = {
        name: textInputFormControl.value?.toString() as string,
        startDate: datePickerFormControl.value,
      };
      try {
        const res = await projectService.createProject(project);
        context?.setShowPopup(true);
        context?.setPopupContent(
          <SuccessPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Project created successfully!
          </SuccessPopup>
        );
      } catch (err: any) {
        context?.setShowPopup(true);
        console.log(err);
        const msg = err.response.data.message;
        context?.setPopupContent(
          <ErrorPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Something went wrong!{msg}
          </ErrorPopup>
        );
      }
    }
  }
  return (
    <div>
      <FormContainer>
        <h1>Create Project</h1>
        <FormFieldTextInput
          formControl={textInputFormControl}
          label="Name"
          placeholder="Type something"
          id="name"
        />
        <FormFieldDatePicker
          formControl={datePickerFormControl}
          label="Start Date"
          placeholder="Select a date"
          minDate={DateTime.now().minus({ years: 3 })}
          id="start-date"
        />
        <Button
          type="primary"
          text="Create"
          onClick={handleSubmit}
          isDisabled={
            !textInputFormControl.isValid || !datePickerFormControl.isValid
          }
        />
      </FormContainer>
    </div>
  );
}
