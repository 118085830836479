import "./ProjectComponentsPage.css"

import { ColumnDefinition, IOrderInfo } from '../../../lib/components/table/TableInterfaces';
import { DescriptionCell, NameCell, OptionsCell } from './ProjectComponentsTableCells';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInitLoader, useServiceCallPro } from '../../../lib/hooks/useServiceCall';
import { useNavigate, useParams } from 'react-router-dom';

import { AddComponentPopup } from '../../popups/AddComponentPopup/AddComponentPopup';
import { Button } from '../../../lib/components/buttons/Button';
import { ComponentService } from '../../../services/ComponentService';
import { ComponentSummaryDTO } from '../../../models/component-summary-dto';
import { ComponentsChangedEvent } from '../../../events/ComponentsChangedEvent';
import { FlexLayout } from '../../../lib/layouts/containers/flex/FlexLayout';
import { IconButton } from '../../../lib/components/buttons/IconButton';
import { PageContainer } from '../../../lib/layouts/containers/page/PageContainer';
import { PlusSVG } from '../../../lib/assets/icons';
import { ResponsiveDataTable } from '../../../lib/components/table/ResponsiveDataTable';
import { Spacer } from '../../../lib/components/separator/Spacer';
import { usePopup } from '../../../lib/infrastructure/ui/UIServices';

const componentService = new ComponentService();


export function ProjectComponentsPage() {
    const [components, setComponents] = useState<ComponentSummaryDTO[]>([]);
    const navigate = useNavigate();

    const {projectId} = useParams();
    const openPopup = usePopup();

    const projectIdAsNumber = useMemo(() =>  parseInt(projectId||"0"), [projectId]);

    const initLoader = useInitLoader();
  
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
      {
        columnKey: "Name",
        direction: "ASC",
      }
    ]);

    const getComponentsCall = useServiceCallPro(componentService.getComponents);

  /****************************
  * DATA REQUESTS
  *****************************/

    const getComponents = useCallback(() => {
        const request : PageModel = {
            pageNumber: currentPage,
            pageSize: itemsPerPage,
            orderOption: undefined,
            filterOptions: undefined
        //   column: orderInfoColumns[0]?.columnKey || 'Name',
        //   isDescending: orderInfoColumns[0]?.direction === 'DESC' || false,
        //   name: nameFilterFormControl.value
        }


        getComponentsCall.invoke(request, projectIdAsNumber).then((res) => {
          setComponents(res.items);
          setTotalItems(res.totalItems)
        });
    
      }, [getComponentsCall.invoke, currentPage, itemsPerPage, orderInfoColumns, setComponents, setTotalItems]);

      
/****************************
  * DATA MANIPULATION EFFECTS
 *****************************/

    useEffect(() => {
        if (!initLoader && currentPage === 0) {
        getComponents();
        } else {
          setCurrentPage(0)
        }
      }, [itemsPerPage]);
    
      useEffect(() => {
        getComponents();
      }, [currentPage, orderInfoColumns]);

      useEffect(() => {
        const handleComponentsEvent = () => {
        getComponents();
        }
    
        ComponentsChangedEvent.attachHandler(handleComponentsEvent);
        return () => { ComponentsChangedEvent.detachHandler(handleComponentsEvent); };
      }, [getComponents]);


  /****************************
  * USER ACTIONS
  *****************************/
    const handleAddComponentClicked = useCallback(() => {
        openPopup(<AddComponentPopup onCompleted={getComponents} projectId={projectIdAsNumber} />)
      }, [projectIdAsNumber, openPopup, getComponents]);


      const handleClickSearch = useCallback(() => {
        getComponents();
      }, [getComponents]);
    
      const handleOnOrderChanged = useCallback(
        (orderedPriorities: IOrderInfo[]) => {
          setOrderInfoColumns(orderedPriorities);
        }, []);

        const handleOnPageAndItemsChanged = useCallback(
            (items: number, currentPage: number) => {
              setItemsPerPage(items);
              setCurrentPage(currentPage);
            },
            [setItemsPerPage, setCurrentPage]
          );

  /****************************
  * CSS & HTML
  *****************************/
    const columns: ColumnDefinition<ComponentSummaryDTO>[] = useMemo(() =>  [
    {
        cellRenderProp: (v) => <NameCell value={v} projectId={projectIdAsNumber} />,
        width: "40%",
        headerRender: <>Name</>,
        columnKey: "Name",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <DescriptionCell value={v} projectId={projectIdAsNumber} />,
        width: "40%",
        headerRender: <>Description</>,
        columnKey: "Description",
        isSortable: true,
    },

    {
        cellRenderProp: (v) => <OptionsCell value={v} projectId={projectIdAsNumber} />,
        width: "15%",
        headerRender: <></>,
        columnKey: "options",
        isSortable: false,
        },
    ],[projectIdAsNumber]);



    return (

        <PageContainer className="components-page">

            <FlexLayout direction="horizontal" className="components-page-header">
                <div className="page-header-title">
                    <h1>Components</h1>
                </div>
                <div className="page-header-actions">
                    <Button
                        className="addproject-button"
                        icon={<PlusSVG />}
                        text="Add Component"
                        type="primary"
                        onClick={handleAddComponentClicked}
                    />
                </div>
            </FlexLayout>

            <Spacer mode="vertical" px={20} />
                 
            <div className="table">

                <ResponsiveDataTable
                    items={components}
                    columnDefinitions={columns}
                    totalitems={totalItems}
                    currentpage={currentPage}
                    paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [5, 10, 20],
                    }}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                    onOrderChanged={handleOnOrderChanged}
                    isLoading={getComponentsCall.isLoading}
                    onClickRow={(item) => navigate(item.componentId)}
                    resizeThreshold={510}
    
                ></ResponsiveDataTable>
            </div>

        </PageContainer>
    )
}