import "./AddProjectPopup.css";

import { Optional, ValidatorFunction, requiredValidator, useFormControl } from "../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldDatePicker } from "../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { ProjectCreateDTO } from "../../../models/project-create-dto";
import { ProjectService } from "../../../services/ProjectService";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IProps {
  onCompleted: () => void;
}

export function lengthValidator(): ValidatorFunction<Optional<string>> {
    return (value) => {
      if (value !== undefined) {
        if (value.length >= 50) {
          return "This field exceeds maximum length!";
        }
      }
      return null;
    };
  }


const projectService = new ProjectService();

export function AddProjectPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const projectNameFormControl = useFormControl<string>({ validators: [requiredValidator(), lengthValidator()], enableAutoValidate: true, initialValue: '' });
  const datePickerFormControl = useFormControl<DateTime>({ validators: [requiredValidator()], enableAutoValidate: true, isDisabled: false});

  const createProjectCall = useServiceCallPro(projectService.createProject);


  /****************************
   * DATA REQUESTS
   *****************************/

  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  const isFormValid = useMemo(() => projectNameFormControl.isValid && datePickerFormControl.isValid && 
  projectNameFormControl.value && datePickerFormControl.value , [projectNameFormControl, datePickerFormControl]);

  const isButtonDisabled = useMemo(() => {
    return !isFormValid || createProjectCall.isLoading;
  }, [isFormValid, createProjectCall.isLoading]);


  /****************************
  * USER ACTIONS
  *****************************/

  const handleAddProjectClicked = useCallback(() => {

    if (!isFormValid || createProjectCall.isLoading) return;

    var requestBody: ProjectCreateDTO = {
      name: projectNameFormControl.value,
      startDate: datePickerFormControl.value
    };

    createProjectCall.invoke(requestBody).then((response) => {

      openPopup(<SuccessPopup>
        <div>Project created successfully!</div>
      </SuccessPopup>);

      props.onCompleted();
    }).catch((err) => {
      console.log(err);
      openPopup(<ErrorPopup><div>{err.response.data.message}</div></ErrorPopup>);
    });

  },
    [props, isFormValid, projectNameFormControl, datePickerFormControl, openPopup, createProjectCall]
  );

  /****************************
  * CSS & HTML
  *****************************/

  if (createProjectCall.isLoading) {
    return (
      <PopupContainer className="add-project-popup">
        <Loader />
      </PopupContainer>
    )
  }

  return (
    <PopupContainer className="add-project-popup">

      <h3>Add project</h3>
      <Spacer mode="vertical" px="16" />
  
      <FormContainer>
        <FormFieldTextInput id="name" label="Name" placeholder="Insert project name" formControl={projectNameFormControl} />
        <FormFieldDatePicker id="start-date" label="Start Date" placeholder="Select a date" formControl={datePickerFormControl} minDate={DateTime.now().minus({ years: 3 })}/>
      </FormContainer>

      <Spacer mode="vertical" px="16" />
      <LineSeparator />

      <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >

        <TextButton text="Cancel" type="white" onClick={() => closePopup()} />
        <Button text="Add" type="primary" isDisabled={isButtonDisabled}
          onClick={handleAddProjectClicked} />

      </FlexLayout>

    </PopupContainer>
  );
}


