import { useCallback } from "react";
import { Button } from "../../lib/components/buttons/Button";
import { SpaceBackgroundLayout } from "../../lib/layouts/space-background/SpaceBackgroundLayout";
import "./ErrorPage.css";

import { useNavigate } from "react-router-dom";
export interface IErrorProps {
  mainTitle: string;
  errorName: string;
  errorMessage?: string;
  buttonText: string;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
export function ErrorPage(props: IErrorProps) {
  const navigate = useNavigate();

  return (
    <SpaceBackgroundLayout>
      <h1 className="logo-text">{props.mainTitle}</h1>

      <h1>{props.errorName}</h1>
      <p>{props.errorMessage}</p>
      <div>
        <Button
          text={props.buttonText}
          type="primary"
          onClick={props.onClick ? props.onClick : () => navigate("/")}
        />
      </div>
    </SpaceBackgroundLayout>
  );
}
