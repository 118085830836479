import axios, { AxiosResponse } from "axios";
import Settings from "../appconfiguration.json";
import { ComponentCreateDTO } from "../models/component-create-dto";
import { ComponentDetailsDTO } from "../models/component-details-dto";
import { ComponentUpdateDTO } from "../models/component-update-dto";
import { SuiteSetCreateDTO } from "../models/suite-set-create-dto";
import { SuiteSetDetailsDTO } from "../models/suite-set-details-dto";
import { SuiteSetUpdateDTO } from "../models/suite-set-update-dto";
import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";

const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;

export class SuiteSetService {
    async deleteSuiteSet(id: number, projectId: number) {
        const res = await axios.delete(Route(`api/Projects/${projectId}/SuiteSets/${id}`));
        return res.status === 200 ? true : false;
    }


    async updateSuiteSet(projectId: number, suiteSet: SuiteSetUpdateDTO) {
        const res = await axios.put<SuiteSetUpdateDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSet.suiteSetId}`), suiteSet);
        return res.data ? res.data : undefined;
    }


    async getSuiteSet(id: number, projectId: number) {
        const res = await axios.get<SuiteSetDetailsDTO>(Route(`api/Projects/${projectId}/SuiteSets/${id}`));
        return res.data ? res.data : undefined;
    }
    async createSuiteSet(suiteSet: SuiteSetCreateDTO) {
        const res = await axios.post<SuiteSetCreateDTO>(Route(`api/Projects/${suiteSet.projectId}/SuiteSets`), suiteSet);
        return res.data ? res.data : undefined;
    }
    async getSuiteSets(paginationModel: PageModel | undefined, projectId: number) {
        return axios.request({
            url: Route(`api/Projects/${projectId}/SuiteSets`),
            method: 'get',
            paramsSerializer: {
                indexes: false
            },
            params: {
                pageNumber: paginationModel?.pageNumber,
                pageSize: paginationModel?.pageSize,
                orderOption: paginationModel?.orderOption,
                filterOptions: paginationModel?.filterOptions
            }
        }).then((res: AxiosResponse<SuiteSetPage>) => res.data);
    }
}
