import "./Main.css";

import { Button } from "../lib/components/buttons/Button";
import { CgComponents } from "react-icons/cg";
import { FlexLayout } from "../lib/layouts/containers/flex/FlexLayout";
import { GrProjects } from "react-icons/gr";
import { IconButton } from "../lib/components/buttons/IconButton";
import { MenuItem } from "../lib/components/side-bar/MenuItem";
import { NavBar } from "../lib/components/nav-bar/NavBar";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../lib/components/side-bar/Sidebar";
import { TbComponents } from "react-icons/tb";
import { TextButton } from "../lib/components/buttons/TextButton";

interface IMainPageProps {
  children: React.ReactNode;
}

export function Main(props: IMainPageProps) {
  //const buttons = useSideBarButtons();
  //const setButtons = useSetSideBarButtons();
  let items: MenuItem[] = [
    {
      icon: (
        <TextButton
          type="primary"
          text="Projects"
          icon={<GrProjects />}
          iconBefore
        />
      ),
      navigateTo: "/projects",
    },
  ];
  return (
    <div>
      <FlexLayout
        direction="horizontal"
        horizontalAlign="start"
        className="page-container"
      >
        <Sidebar buttons={items}></Sidebar>
        <div className="content-container">
          <Outlet />
          {props.children}
        </div>
      </FlexLayout>
    </div>
  );
}
