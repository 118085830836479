import { DateTime } from "luxon";
import { useCallback, useContext, useState } from "react";
import { ProjectService } from "../../services/ProjectService";
import React from "react";
import { ResponsiveDataTable } from "../../lib/components/table/ResponsiveDataTable";
import {
  ColumnDefinition,
  IOrderInfo,
} from "../../lib/components/table/TableInterfaces";
import "../ListTable.css";
import {
  RiAddFill,
  RiAlertFill,
  RiCheckFill,
  RiCloseFill,
  RiDeleteBin7Fill,
  RiDownload2Fill,
  RiEditFill,
  RiEyeFill,
  RiSearchFill,
} from "react-icons/ri";
import { Tag } from "../../lib/components/tag/Tag";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { Tooltip } from "../../lib/components/tooltip/Tooltip";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { useFormControl } from "../../lib/components/form/Form";
import { UISContext } from "../../lib/infrastructure/ui/UIServices";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ExecutionService } from "../../services/ExecutionService";
import { ExecutionSummaryDTO } from "../../models/execution-summary-dto";
import { ExecutionStatusTag } from "../ExecutionStatusTag";
import { ExecutionStatus } from "../../models/execution-status";
import { ExecutionUpdateDTO } from "../../models/execution-update-dto";
const executionService = new ExecutionService();

export function ListExecutionsTable() {
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false);
  const [isSearchingProject, setIsSearchingProject] = useState<boolean>(false);
  const [isSearchingSuiteSet, setIsSearchingSuiteSet] =
    useState<boolean>(false);
  const textInputFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const url = window.location.pathname;
  const projectId = parseInt(useParams().projectId?.toString() || "0");
  const suiteSetId = parseInt(useParams().suiteSetId?.toString() || "0");

  const context = useContext(UISContext);

  const handleExportReport = useCallback(async () => {
    alert("you are downloading report");
  }, []);

  const handleAbort = useCallback(
    async (id: number) => {
      if (context !== null) {
        context.setShowPopup(true);
        context.setPopupContent(
          <WarningPopup
            onConfirmClicked={async () => {
              const res = await executionService.abortExecution(
                projectId,
                suiteSetId,
                id,
                {
                  executionId: id,
                  suiteSetId: suiteSetId,
                  projectId: projectId,
                  status: ExecutionStatus[ExecutionStatus.Aborted],
                } as ExecutionUpdateDTO
              );
              if (res) {
                context.setPopupContent(
                  <SuccessPopup
                    onDismissClicked={() => {
                      context?.setShowPopup(false);
                      setNeedsRefreshing(!needsRefreshing);
                    }}
                  >
                    Execution aborted successfully!
                  </SuccessPopup>
                );
              } else {
                context.setPopupContent(
                  <ErrorPopup
                    onDismissClicked={() => {
                      context?.setShowPopup(false);
                    }}
                  >
                    Error aborting execution!
                  </ErrorPopup>
                );
              }
            }}
            onDismissClicked={() => {
              context.setShowPopup(false);
            }}
          >
            Are you sure you want to abort this execution?
          </WarningPopup>
        );
      }
    },
    [context, projectId, suiteSetId, needsRefreshing]
  );

  const navigate = useNavigate();
  const columns: ColumnDefinition<ExecutionSummaryDTO>[] = [
    {
      columnKey: "ProjectName",
      cellRenderProp: (v: ExecutionSummaryDTO) => v.projectName,
      headerRender: isSearchingProject ? (
        <div className="search-header">
          <FormContainer>
            <FlexLayout direction="horizontal" horizontalAlign="start">
              <FormFieldTextInput
                formControl={textInputFormControl}
                placeholder="ProjectName"
                id="projectName"
              />
              <IconButton
                type="primary"
                icon={<RiSearchFill />}
                onClick={() => {
                  setIsSearchingProject(!isSearchingProject);
                  search();
                }}
                isRound
                className="search-icon-box"
              />
            </FlexLayout>
          </FormContainer>
        </div>
      ) : (
        <div className="search-header">
          <FlexLayout direction="horizontal" horizontalAlign="start">
            Project Name
            <IconButton
              type="primary"
              icon={<RiSearchFill />}
              onClick={() => {
                setIsSearchingSuiteSet(!isSearchingSuiteSet);
              }}
              isRound
              className="search-icon"
            />
          </FlexLayout>
        </div>
      ),
      isMobilePrimaryCell: true,
      isSortable: true,
    },
    {
      columnKey: "SuiteSetName",
      cellRenderProp: (v: ExecutionSummaryDTO) => v.suiteSetName,
      headerRender: isSearchingSuiteSet ? (
        <div className="search-header">
          <FormContainer>
            <FlexLayout direction="horizontal" horizontalAlign="start">
              <FormFieldTextInput
                formControl={textInputFormControl}
                placeholder="SuiteSetName"
                id="suiteSetName"
              />
              <IconButton
                type="primary"
                icon={<RiSearchFill />}
                onClick={() => {
                  setIsSearchingSuiteSet(!isSearchingSuiteSet);
                  search();
                }}
                isRound
                className="search-icon-box"
              />
            </FlexLayout>
          </FormContainer>
        </div>
      ) : (
        <div className="search-header">
          <FlexLayout direction="horizontal" horizontalAlign="start">
            Suite Set Name
            <IconButton
              type="primary"
              icon={<RiSearchFill />}
              onClick={() => {
                setIsSearchingSuiteSet(!isSearchingSuiteSet);
              }}
              isRound
              className="search-icon"
            />
          </FlexLayout>
        </div>
      ),
      isMobilePrimaryCell: true,
      isSortable: true,
    },
    {
      columnKey: "status",
      cellRenderProp: (v: ExecutionSummaryDTO) => {
        var stat = ExecutionStatus[v.status as keyof typeof ExecutionStatus];
        stat = stat !== undefined ? stat : ExecutionStatus.None;
        return (
          <ExecutionStatusTag
            status={stat}
            toolTipName="Start Date"
            toolTipMessage={DateTime.fromISO(v.executionDate).toFormat(
              "dd/MM/yyyy"
            )}
          />
        );
      },
      headerRender: "Latest Execution",
      isStatusCell: true,
    },
    {
      columnKey: "view",
      cellRenderProp: (v: ExecutionSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiEyeFill />}
          onClick={() => {
            navigate(url + "/executions/" + v.executionId);
          }}
          id={v.executionId.toString() + "-view"}
        />
      ),
      isMobileHeaderIcon: true,
      width: "24px",
    },
    {
      columnKey: "download",
      cellRenderProp: (v: ExecutionSummaryDTO) => (
        <Tooltip text={"Download report"}>
          <IconButton
            type="tertiary"
            icon={<RiDownload2Fill />}
            onClick={() => {
              handleExportReport();
            }}
            id={v.executionId.toString() + "-download"}
          />
        </Tooltip>
      ),
      isMobileHeaderIcon: true,
      width: "24px",
    },
    {
      columnKey: "delete",
      cellRenderProp: (v: ExecutionSummaryDTO) => {
        var stat = ExecutionStatus[v.status as keyof typeof ExecutionStatus];
        stat = stat !== undefined ? stat : ExecutionStatus.None;
        return (
          <Tooltip text={"Abort Execution"}>
            <IconButton
              isDisabled={stat === ExecutionStatus.Aborted}
              type="secondary"
              isRound={true}
              icon={<RiAlertFill />}
              onClick={() => {
                handleAbort(v.executionId);
              }}
              id={v.executionId.toString() + "-delete"}
            />
          </Tooltip>
        );
      },
      width: "24px",
    },
  ];
  const [filters, setFilters] = useState<IFilterOption[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
    { columnKey: "Name", direction: "ASC" },
    { columnKey: "StartDate", direction: "ASC" },
  ]);
  const [lastOrderInfoColumn, setLastOrderInfoColumn] = useState<IOrderInfo>();
  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );

  const updateColumnOrderInfo = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderInfoColumns);
      setLastOrderInfoColumn(orderedPriorities[orderedPriorities.length - 1]);
    },
    [orderInfoColumns]
  );

  const [totalItems, setTotalItems] = React.useState(0);
  const [items, setItems] = React.useState([] as ExecutionSummaryDTO[]);

  async function search() {
    let filters: IFilterOption[] = [];
    if (textInputFormControl.value) {
      filters.push({
        field: "Name",
        value: textInputFormControl.value,
        exact: "false",
      } as IFilterOption);
    }
    setFilters(filters);
  }
  React.useEffect(() => {
    setIsLoading(true);
    executionService
      .getExecutions(
        {
          pageNumber: currentPage,
          pageSize: itemsPerPage,
          orderOption: lastOrderInfoColumn,
          filterOptions: filters,
        } as PageModel,
        projectId,
        suiteSetId
      )
      .then((datat) => {
        if (datat != null) {
          setTotalItems(datat.totalItems || 0);
          setItems(datat.items || []);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          navigate("/networkerror");
        } else if (error.response?.status === 404) {
          navigate("/notfound");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    needsRefreshing,
    currentPage,
    itemsPerPage,
    lastOrderInfoColumn,
    filters,
    projectId,
    suiteSetId,
    navigate,
  ]);
  return (
    <div>
      <h1>Executions</h1>
      <ResponsiveDataTable
        items={items}
        columnDefinitions={columns}
        totalitems={totalItems}
        currentpage={currentPage}
        paginationOptions={{
          itemsPerPage: itemsPerPage,
          itemsPerPageOptions: [5, 10, 20],
        }}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
        onOrderChanged={updateColumnOrderInfo}
        isLoading={isLoading}
        resizeThreshold={600}
      ></ResponsiveDataTable>
    </div>
  );
}
