import { DateTime } from "luxon";
import { useCallback, useContext, useState } from "react";
import React from "react";
import { ResponsiveDataTable } from "../../lib/components/table/ResponsiveDataTable";
import {
  ColumnDefinition,
  IOrderInfo,
} from "../../lib/components/table/TableInterfaces";
import "../ListTable.css";
import {
  RiAddFill,
  RiCheckFill,
  RiCloseFill,
  RiDeleteBin7Fill,
  RiEditFill,
  RiEyeFill,
  RiPlayCircleFill,
  RiPlayFill,
  RiRunFill,
  RiSearchFill,
} from "react-icons/ri";
import { Tag } from "../../lib/components/tag/Tag";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { Tooltip } from "../../lib/components/tooltip/Tooltip";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { useFormControl } from "../../lib/components/form/Form";
import { UISContext } from "../../lib/infrastructure/ui/UIServices";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { useNavigate, useParams } from "react-router-dom";
import { ComponentService } from "../../services/ComponentService";
import { ComponentSummaryDTO } from "../../models/component-summary-dto";
import { SuiteSetService } from "../../services/SuiteSetService";
import { SuiteSetSummaryDTO } from "../../models/suite-set-summary-dto";
import { ExecutionStatusTag } from "../ExecutionStatusTag";
import { ExecutionStatus } from "../../models/execution-status";
import { ExecutionSummaryDTO } from "../../models/execution-summary-dto";
import { ExecutionPage } from "../../models/execution-page-dto";
import { ExecutionService } from "../../services/ExecutionService";
import { Button } from "../../lib/components/buttons/Button";
import { TextButton } from "../../lib/components/buttons/TextButton";
import { ExecutionCreateDTO } from "../../models/execution-create-dto";
const suiteSetService = new SuiteSetService();
const executionService = new ExecutionService();
interface IsRunningSuiteSet {
  isRunning: boolean;
  suiteSetId: number;
}
export function ListSuiteSetsTable() {
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isSearchingType, setIsSearchingType] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState<IsRunningSuiteSet[]>([]);
  const textInputFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const testTypeInputFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const url = window.location.pathname;
  const projectId = parseInt(useParams().id?.toString() || "0");
  const handleExecutionRun = useCallback(
    async (suiteSetId: number) => {
      const res = await executionService.createExecution(
        {
          projectId: projectId,
          suiteSetId: suiteSetId,
          executionDate: DateTime.utc().toISO(),
        } as ExecutionCreateDTO,
        suiteSetId
      );
      if (res) {
        setIsRunning([
          ...isRunning,
          { isRunning: true, suiteSetId: suiteSetId },
        ]);
      }
    },
    [projectId, isRunning]
  );
  async function handleDelete(id: number, projectid: number) {
    if (context !== null) {
      context.setShowPopup(true);
      context.setPopupContent(
        <WarningPopup
          onConfirmClicked={async () => {
            const res = await suiteSetService.deleteSuiteSet(id, projectid);
            if (res) {
              context.setPopupContent(
                <SuccessPopup
                  onDismissClicked={() => {
                    context?.setShowPopup(false);
                    setNeedsRefreshing(!needsRefreshing);
                  }}
                >
                  Suite Set removed successfully!
                </SuccessPopup>
              );
            } else {
              context.setPopupContent(
                <ErrorPopup
                  onDismissClicked={() => {
                    context?.setShowPopup(false);
                  }}
                >
                  Error deleting suite set!
                </ErrorPopup>
              );
            }
          }}
          onDismissClicked={() => {
            context.setShowPopup(false);
          }}
        >
          Are you sure you want to delete this suite set?
        </WarningPopup>
      );
    }
  }

  const context = useContext(UISContext);
  const navigate = useNavigate();
  const columns: ColumnDefinition<SuiteSetSummaryDTO>[] = [
    {
      columnKey: "Name",
      cellRenderProp: (v: SuiteSetSummaryDTO) => v.name,
      headerRender: isSearching ? (
        <div className="search-header">
          <FormContainer>
            <FlexLayout direction="horizontal" horizontalAlign="start">
              <FormFieldTextInput
                formControl={textInputFormControl}
                placeholder="Name"
                id="name"
              />
              <IconButton
                type="primary"
                icon={<RiSearchFill />}
                onClick={() => {
                  setIsSearching(!isSearching);
                  search();
                }}
                isRound
                className="search-icon-box"
              />
            </FlexLayout>
          </FormContainer>
        </div>
      ) : (
        <div className="search-header">
          <FlexLayout direction="horizontal" horizontalAlign="start">
            Name
            <IconButton
              type="primary"
              icon={<RiSearchFill />}
              onClick={() => {
                setIsSearching(!isSearching);
              }}
              isRound
              className="search-icon"
            />
          </FlexLayout>
        </div>
      ),
      isMobilePrimaryCell: true,
      isSortable: true,
    },
    /* {
      columnKey: "ProjectName",
      cellRenderProp: (v: SuiteSetSummaryDTO) => v.projectName,
      //v.startDate ? v.startDate.startDate.toFormat("dd/MM/yyyy") : "",
      headerRender: "Project Name",
      isSortable: true,
    }, */
    {
      columnKey: "TestType",
      cellRenderProp: (v: SuiteSetSummaryDTO) => v.testType,
      headerRender: isSearchingType ? (
        <div className="search-header">
          <FormContainer>
            <FlexLayout direction="horizontal" horizontalAlign="start">
              <FormFieldTextInput
                formControl={testTypeInputFormControl}
                placeholder="Test Type"
                id="testType"
              />
              <IconButton
                type="primary"
                icon={<RiSearchFill />}
                onClick={() => {
                  setIsSearchingType(!isSearchingType);
                  search();
                }}
                isRound
                className="search-icon-box"
              />
            </FlexLayout>
          </FormContainer>
        </div>
      ) : (
        <div className="search-header">
          <FlexLayout direction="horizontal" horizontalAlign="start">
            Test Type
            <IconButton
              type="primary"
              icon={<RiSearchFill />}
              onClick={() => {
                setIsSearchingType(!isSearchingType);
              }}
              isRound
              className="search-icon"
            />
          </FlexLayout>
        </div>
      ),
      isSortable: true,
    },

    {
      columnKey: "status",
      cellRenderProp: (v: SuiteSetSummaryDTO) => {
        var stat =
          ExecutionStatus[
            v.mostRecentExecutionStatus as keyof typeof ExecutionStatus
          ];
        stat = stat !== undefined ? stat : ExecutionStatus.None;
        return (
          <ExecutionStatusTag
            status={stat}
            toolTipName="Date"
            toolTipMessage={DateTime.fromISO(
              v.mostRecentExecutionDate
            ).toFormat("dd/MM/yyyy")}
          />
        );
      },
      headerRender: "Latest Execution",
      isStatusCell: true,
    },
    {
      columnKey: "view",
      cellRenderProp: (v: SuiteSetSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiEyeFill />}
          onClick={() => navigate(url + "/suiteSets/" + v.suiteSetId)}
          id={v.name.toString() + "-view"}
        />
      ),
      isMobileHeaderIcon: true,
      width: "24px",
    },
    {
      columnKey: "edit",
      cellRenderProp: (v: SuiteSetSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiEditFill />}
          onClick={() => {
            navigate(`${url}/suiteSets/${v.suiteSetId}/edit`);
          }}
          id={v.name.toString() + "-edit"}
        />
      ),
      isMobileHeaderIcon: true,
      width: "24px",
    },
    {
      columnKey: "delete",
      cellRenderProp: (v: SuiteSetSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiDeleteBin7Fill />}
          onClick={() => {
            handleDelete(v.suiteSetId, v.projectId);
          }}
          id={v.name.toString() + "-delete"}
        />
      ),
      width: "24px",
    },
    {
      columnKey: "run",
      cellRenderProp: (v: SuiteSetSummaryDTO) => {
        var isCurrentRunning = isRunning.find(
          (x) => x.suiteSetId === v.suiteSetId
        );
        /* id={v.name.toString() + "-view"} */
        return (
          <Button
            isDisabled={isCurrentRunning?.isRunning || false}
            type="tertiary"
            icon={isCurrentRunning ? <RiRunFill /> : <RiPlayCircleFill />}
            onClick={() => {
              handleExecutionRun(v.suiteSetId);
            }}
            text={isCurrentRunning ? "Running" : "Run"}
          />
        );
      },

      isMobileHeaderIcon: true,
      headerRender: (
        <IconButton
          type="primary"
          isRound={true}
          icon={<RiAddFill />}
          onClick={() => navigate(`${url}/suiteSets/new`)}
          id="add-new"
        />
      ),
      width: "24px",
      isStatusCell: true,
    },
  ];
  const [filters, setFilters] = useState<IFilterOption[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
    { columnKey: "Name", direction: "ASC" },
    { columnKey: "StartDate", direction: "ASC" },
  ]);
  const [lastOrderInfoColumn, setLastOrderInfoColumn] = useState<IOrderInfo>();
  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );

  const updateColumnOrderInfo = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderInfoColumns);
      setLastOrderInfoColumn(orderedPriorities[orderedPriorities.length - 1]);
    },
    [orderInfoColumns]
  );

  const [totalItems, setTotalItems] = React.useState(0);
  const [items, setItems] = React.useState([] as SuiteSetSummaryDTO[]);
  async function search() {
    let filters: IFilterOption[] = [];
    if (textInputFormControl.value) {
      filters.push({
        field: "Name",
        value: textInputFormControl.value,
        exact: "false",
      } as IFilterOption);
    }
    if (testTypeInputFormControl.value) {
      filters.push({
        field: "TestType",
        value: testTypeInputFormControl.value,
        exact: "true",
      } as IFilterOption);
    }
    setFilters(filters);
  }

  React.useEffect(() => {
    setIsLoading(true);
    suiteSetService
      .getSuiteSets(
        {
          pageNumber: currentPage,
          pageSize: itemsPerPage,
          orderOption: lastOrderInfoColumn,
          filterOptions: filters,
        } as PageModel,
        projectId
      )
      .then((datat) => {
        if (datat != null) {
          setTotalItems(datat.totalItems || 0);
          setItems(datat.items || []);
          for (const item of datat.items) {
            if (
              item.mostRecentExecutionStatus === "Pending" ||
              item.mostRecentExecutionStatus === "Running"
            ) {
              setIsRunning([
                ...isRunning,
                { isRunning: true, suiteSetId: item.suiteSetId },
              ]);
            }
          }
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          navigate("/networkerror");
        } else if (error.response?.status === 404) {
          navigate("/notfound");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    needsRefreshing,
    currentPage,
    itemsPerPage,
    lastOrderInfoColumn,
    filters,
  ]);
  return (
    <div>
      <h1>Suite Sets</h1>
      <ResponsiveDataTable
        items={items}
        columnDefinitions={columns}
        totalitems={totalItems}
        currentpage={currentPage}
        paginationOptions={{
          itemsPerPage: itemsPerPage,
          itemsPerPageOptions: [5, 10, 20],
        }}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
        onOrderChanged={updateColumnOrderInfo}
        isLoading={isLoading}
        resizeThreshold={510}
      ></ResponsiveDataTable>
    </div>
  );
}
