import {
  RiAlertFill,
  RiCheckFill,
  RiCloseFill,
  RiRunFill,
  RiTimeFill,
  RiTimeLine,
  RiTodoFill,
} from "react-icons/ri";
import { ITagProps, Tag } from "../lib/components/tag/Tag";
import { Tooltip } from "../lib/components/tooltip/Tooltip";
import { ExecutionStatus } from "../models/execution-status";

interface IExecutionStatusTagProps {
  status: ExecutionStatus;
  color?:
    | "status-red"
    | "status-blue"
    | "status-yellow"
    | "status-light-grey"
    | "status-green"
    | "status-lime-green"
    | "status-secondary-orange"
    | "status-secondary-green";
  toolTipName?: string;
  toolTipMessage?: string;
  icon?: React.ReactNode;
}

export function ExecutionStatusTag(props: IExecutionStatusTagProps) {
  var color = props.color ? props.color : "status-light-grey";
  var icon = props.icon ? props.icon : <RiTimeLine />;
  switch (props.status) {
    case ExecutionStatus.Pending:
      color = "status-blue";
      icon = <RiTimeFill />;
      break;
    case ExecutionStatus.Running:
      color = "status-green";
      icon = <RiRunFill />;
      break;
    case ExecutionStatus.Completed:
      color = "status-lime-green";
      icon = <RiCheckFill />;
      break;
    case ExecutionStatus.Failed:
      color = "status-red";
      icon = <RiCloseFill />;
      break;
    case ExecutionStatus.Aborted:
      color = "status-secondary-orange";
      icon = <RiAlertFill />;
      break;
    case ExecutionStatus.None:
      color = "status-light-grey";
      icon = <RiTimeLine />;
      break;
  }
  var final: JSX.Element[] = [];
  if (props.toolTipName) {
    final.push(
      <Tooltip text={props.toolTipName + ":" + props.toolTipMessage}>
        <Tag
          backgroundColor={color}
          text={ExecutionStatus[props.status]}
          icon={icon}
        ></Tag>
      </Tooltip>
    );
  } else {
    final.push(
      <Tag
        backgroundColor={color}
        text={ExecutionStatus[props.status]}
        icon={icon}
      ></Tag>
    );
  }
  var html = final.flat();
  return <>{html}</>;
}
