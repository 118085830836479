import { DateTime } from "luxon";
import { FormFieldDatePicker } from "../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectMultiple } from "../../lib/components/form/form-field/FormFieldSelectMultiple";
import { FormFieldSelectMultipleNoSearch } from "../../lib/components/form/form-field/FormFieldSelectMultipleNoSearch";
import { FormFieldSelectSingle } from "../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { RiSearchLine } from "react-icons/ri";
import { Toggle } from "../../lib/components/form/toggle/Toggle";
import { useFormControl } from "../../lib/components/form/Form";

interface Option {
  id: string;
  name: string;
}

const optionsMock = [
  {
    id: "01",
    name: "Option 01",
  },
  {
    id: "02",
    name: "Option 02",
  },
  {
    id: "03",
    name: "Option 03",
  },
  {
    id: "04",
    name: "Option 04",
  },
];

const labelSelector = (item: Option) => item.name;
const idSelector = (item: Option) => item.id;

interface IInputsProps {}

export function Inputs(props: IInputsProps) {
  const textInputFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const disabledInputFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: true,
    initialValue: "",
  });

  const selectSingleFormControl = useFormControl<Option>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
  });
  const disabledSelectSingleFormControl = useFormControl<Option>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: true,
  });

  const selectMultipleNoSearchFormControl = useFormControl<Option[]>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
  });
  const disabledSelectMultipleNoSearchFormControl = useFormControl<Option[]>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: true,
  });

  const datePickerFormControl = useFormControl<DateTime>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
  });
  const disabledDatePickerFormControl = useFormControl<DateTime>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: true,
  });

  return (
    <div className="section inputs">
      <h1>
        <span>01. </span>Text Inputs
      </h1>
      <div className="item">
        <div className="flex-row">
          <FormFieldTextInput
            formControl={textInputFormControl}
            label="Text Input"
            placeholder="Type something"
          />
          <FormFieldTextInput
            formControl={disabledInputFormControl}
            label="Text Input Disabled"
            placeholder="Type something"
          />
          <FormFieldTextInput
            formControl={textInputFormControl}
            label="With Icon"
            placeholder="Type something"
            icon={<RiSearchLine />}
          />
          <FormFieldTextInput
            formControl={disabledInputFormControl}
            label="With Icon Disabled"
            placeholder="Type something"
            icon={<RiSearchLine />}
          />
        </div>
      </div>
      <div className="item">
        <div className="flex-row">
          <FormFieldTextArea
            formControl={textInputFormControl}
            label="Text Area"
            placeholder="Type something"
          />
          <FormFieldTextArea
            formControl={disabledInputFormControl}
            label="Text Area Disabled"
            placeholder="Type something"
          />
          <FormFieldTextArea
            formControl={textInputFormControl}
            label="With Icon"
            placeholder="Type something"
            icon={<RiSearchLine />}
          />
          <FormFieldTextArea
            formControl={disabledInputFormControl}
            label="With Icon Disabled"
            placeholder="Type something"
            icon={<RiSearchLine />}
          />
        </div>
      </div>
      <h1>
        <span>02. </span>Dropdowns
      </h1>
      <div className="item">
        <div className="flex-row">
          <FormFieldSelectSingle
            label="Select Single"
            placeholder="Type something"
            formControl={selectSingleFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
            isClearSelectionAvailable
          />
          <FormFieldSelectSingle
            label="Select Single Disabled"
            placeholder="Type something"
            formControl={disabledSelectSingleFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
            isClearSelectionAvailable
          />
          <FormFieldSelectSingle
            label="With Icon"
            placeholder="Type something"
            formControl={selectSingleFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
            icon={<RiSearchLine />}
          />
          <FormFieldSelectSingle
            label="With Icon Disabled"
            placeholder="Type something"
            formControl={disabledSelectSingleFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
            icon={<RiSearchLine />}
          />
        </div>
      </div>
      <div className="item">
        <div className="flex-row">
          <FormFieldSelectMultiple
            label="Select Multiple"
            placeholder="Type something"
            formControl={selectMultipleNoSearchFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
          />
          <FormFieldSelectMultiple
            label="Select Multiple Disabled"
            placeholder="Type something"
            formControl={disabledSelectMultipleNoSearchFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
          />
          <FormFieldSelectMultiple
            label="With Icon"
            placeholder="Type something"
            formControl={selectMultipleNoSearchFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
            icon={<RiSearchLine />}
          />
          <FormFieldSelectMultiple
            label="With Icon Disabled"
            placeholder="Type something"
            formControl={disabledSelectMultipleNoSearchFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
            icon={<RiSearchLine />}
          />
        </div>
      </div>
      <div className="item">
        <div className="flex-row">
          <FormFieldSelectMultipleNoSearch
            label="Select Multiple (No Search)"
            placeholder="Select something"
            formControl={selectMultipleNoSearchFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
          />
          <FormFieldSelectMultipleNoSearch
            label="(No Search) Disabled"
            placeholder="Select something"
            formControl={disabledSelectMultipleNoSearchFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
          />
          <FormFieldSelectMultipleNoSearch
            label="With Icon"
            placeholder="Select something"
            formControl={selectMultipleNoSearchFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
            icon={<RiSearchLine />}
          />
          <FormFieldSelectMultipleNoSearch
            label="With Icon Disabled"
            placeholder="Select something"
            formControl={disabledSelectMultipleNoSearchFormControl}
            options={optionsMock}
            labelSelector={labelSelector}
            idSelector={idSelector}
            icon={<RiSearchLine />}
          />
        </div>
      </div>
      <h1>
        <span>03. </span>Date Pickers
      </h1>
      <div className="item">
        <div className="flex-row">
          <FormFieldDatePicker
            formControl={datePickerFormControl}
            label="Date Picker"
            placeholder="Select a date"
            minDate={DateTime.now()}
          />
          <FormFieldDatePicker
            formControl={disabledDatePickerFormControl}
            label="Date Picker Disabled"
            placeholder="Select a date"
            minDate={DateTime.now()}
          />
        </div>
      </div>
      <h1>
        <span>04. </span>Toggles
      </h1>
      <div className="item">
        <div className="flex-row">
          <Toggle label="Active" isActive onToggleChanged={() => {}} />
          <Toggle
            label="Disabled"
            isActive={false}
            onToggleChanged={() => {}}
            isDisabled
          />
        </div>
      </div>
    </div>
  );
}
