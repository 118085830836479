import "./FormField.css";

import { CssClassnameBuilder } from "../../../../lib/utils/CssClassnameBuilder";
import { IFormControl } from "../Form";
import { useMemo } from "react";

interface IFormFieldProps<T> {
  label?: string;
  children: React.ReactNode;
  formControl: IFormControl<T>;
  className?: string;
}

// TODO: reservar espaço da label.
// TODO: reservar 1 linha de erro.

export function FormField<T>(props: IFormFieldProps<T>) {
  /****************************
   * CSS && HTML
   *****************************/

  const formFieldCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("form-field")
      .add(props.className)
      .addConditional(props.formControl.isDisabled, "disabled")
      .addConditional(props.formControl.hasErrors, "error")
      .build();
  }, [props.className, props.formControl.isDisabled, props.formControl.hasErrors]);

  const labelHTML = useMemo(
    () =>
      props.label ? (
        <div className="form-field-label">{props.label}</div>
      ) : null,
    [props.label]
  );

  const errorMessagesHTML = useMemo(() => {
    if (!props.formControl.hasErrors) return null;

    return props.formControl.errorMessages?.map((i, idx) => (
      <div key={idx} className="caption form-field-error">
        {i}
      </div>
    ));
  }, [props.formControl.errorMessages, props.formControl.hasErrors]);

  return (
    <div className={formFieldCss}>
      {labelHTML}
      {props.children}
      <div>{errorMessagesHTML}</div>
    </div>
  );
}
