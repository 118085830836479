import { FormField } from "./FormField";
import { IFormControl } from "../Form";
import { SelectSingle } from "../select-single/SelectSingle";

interface IFormFieldSelectSingleProps<T> {
  label?: string;
  formControl: IFormControl<T>
  icon?: React.ReactNode;
  placeholder?: string;
  options: T[];
  labelSelector: (value: T) => string;
  idSelector: (value: T) => string | number;
  maxHeightOptions?: string;
  isClearSelectionAvailable?: boolean;
  classNameFormField?: string;
}



export function FormFieldSelectSingle<T>(props: IFormFieldSelectSingleProps<T>) {

  return (
    <FormField className={props.classNameFormField} label={props.label} formControl={props.formControl}>
      <SelectSingle
        options={props.options}
        labelSelector={props.labelSelector}
        idSelector={props.idSelector}
        placeholder={props.placeholder}
        icon={props.icon}
        hasError={props.formControl.hasErrors}
        isDisabled={props.formControl.isDisabled}
        onChange={props.formControl.setValue}
        value={props.formControl.value}
        maxHeightOptions={props.maxHeightOptions}
        isClearSelectionAvailable={props.isClearSelectionAvailable}
      />
    </FormField>

  )
}
