import React, { PureComponent, useEffect } from "react";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ReportDetailsDTO } from "../../../../../models/report-details-dto";
import { FlexLayout } from "../../../../../lib/layouts/containers/flex/FlexLayout";
import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import "./ReportRadialBarChart.css";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
interface IReportRadialBarChartProps {
  report: ReportDetailsDTO | undefined;
}

const style = {
  top: "50%",
  left: "35vw",
  transform: "translate(0, -50%)",
  lineHeight: "24px",
};
export function ReportRadialBarChart(props: IReportRadialBarChartProps) {
  if (props.report === undefined) {
    return <div></div>;
  }
  const data = [
    {
      name: "Total",
      uv: props.report.nTests,
      pv: 2400,
      fill: "#8884d8",
    },
  ];

  if (props.report.nPasses > 0) {
    data.push({
      name: "Passed",
      uv: props.report.nPasses,
      pv: 1398,
      fill: "#7FFCB4",
    });
  }
  if (props.report.nFailures > 0) {
    data.push({
      name: "Failed",
      uv: props.report.nFailures,
      pv: 1398,
      fill: "#ea7c84",
    });
  }
  if (props.report.nPending > 0) {
    data.push({
      name: "Pending",
      uv: props.report.nPending,
      pv: 4567,
      fill: "#5bcedd",
    });
  }
  //I want to show percentage in radial bar chart base on total number of tests

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      minWidth={350}
      minHeight={350}
    >
      <RadialBarChart
        cx="50%"
        cy="50%"
        innerRadius="10%"
        outerRadius="80%"
        barGap={1}
        data={data}
        startAngle={-90}
        endAngle={270}
      >
        <RadialBar
          label={{ position: "insideStart", fill: "#fff" }}
          background={{ fill: "rgba(255,255,255,0)" }}
          dataKey="uv"
        />
        <Legend iconSize={10} layout="vertical" wrapperStyle={style} />
      </RadialBarChart>
    </ResponsiveContainer>
  );
}
