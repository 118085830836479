import { useContext, useMemo } from "react";

import { UISContext } from "./UIServices";

export function useParentEventListener() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const parentEventListener = useMemo(() => {
    if (context.showPopup) {
      return context.popupContainerRef?.current;
    } else if (context.showEditor) {
      return context.editorContainerRef?.current;
    } else {
      return window;
    }
  }, [
    context.showPopup,
    context.popupContainerRef,
    context.popupContainerRef.current,
    context.showEditor,
    context.editorContainerRef,
    context.editorContainerRef.current,
  ]);

  return parentEventListener;
}
