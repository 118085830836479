import { RiEdit2Fill } from "react-icons/ri";
import { Button } from "../../lib/components/buttons/Button";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { ComponentService } from "../../services/ComponentService";
import { ComponentDetailsDTO } from "../../models/component-details-dto";
const componentService = new ComponentService();

export function ConsultComponent() {
  const navigate = useNavigate();
  const [component, setComponent] = React.useState<ComponentDetailsDTO>();
  const [isNotFound, setIsNotFound] = React.useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = React.useState<boolean>(false);
  const url = window.location.pathname;
  const id = parseInt(useParams().componentId?.toString() || "0");
  const projectId = parseInt(useParams().projectId?.toString() || "0");
  useEffect(() => {
    if (id === undefined) {
      setIsNotFound(true);
    }
    componentService
      .getComponent(id, projectId)
      .then((datat) => {
        if (datat != null) {
          setComponent(datat);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          setIsNetworkError(true);
        } else if (error.response?.status === 404) {
          console.log("Not found!");
          setIsNotFound(true);
        }
      });
  }, []);
  if (isNotFound) {
    navigate("/notfound");
  } else if (isNetworkError) {
    navigate("/networkerror");
  }
  return (
    <div>
      <FormContainer>
        <h1>{component?.name ? component.name : "None"}</h1>
        <FormSection title="Details" unsetFlexGrow>
          <InfoBlock
            label="Project"
            value={component?.projectName ? component.projectName : "None"}
          />
          <InfoBlock
            label="Name"
            value={component?.name ? component.name : "None"}
          />
          <InfoBlock
            label="Description"
            value={component?.description ? component.description : "None"}
          />
          <InfoBlock
            label="Repository URL"
            value={component?.repositoryUrl ? component.repositoryUrl : "None"}
          />
          <InfoBlock
            label="Access Token"
            value={component?.accessToken ? component.accessToken : "None"}
          />

          <Button
            type="secondary"
            text="Edit"
            icon={<RiEdit2Fill />}
            onClick={() => {
              navigate(`${url}/edit`);
            }}
          />
        </FormSection>
      </FormContainer>
    </div>
  );
}
