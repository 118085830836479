import {
  Optional,
  ValidatorFunction,
  requiredValidator,
  useFormControl,
} from "../../lib/components/form/Form";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormFieldDatePicker } from "../../lib/components/form/form-field/FormFieldDatePicker";
import { DateTime } from "luxon";
import { Button } from "../../lib/components/buttons/Button";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { useContext, useEffect } from "react";
import { ProjectService } from "../../services/ProjectService";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { UISContext } from "../../lib/infrastructure/ui/UIServices";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { ProjectUpdateDTO } from "../../models/project-update-dto";
import { useParams } from "react-router-dom";
import React from "react";
const projectService = new ProjectService();

export function EditProjectForm() {
  const context = useContext(UISContext);
  const datePickerFormControl = useFormControl<DateTime>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
  });
  const textInputFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  var id = useParams().id;
  useEffect(() => {
    projectService
      .getProject(id)
      .then((datat) => {
        if (datat != null) {
          console.log(datat);
          textInputFormControl.setValue(datat.name);
          datePickerFormControl.setValue(DateTime.fromISO(datat.startDate));
        }
      })
      .catch((error) => {
        /* if (error.code === "ERR_NOT_FOUND") {
          navigate("/notfound");
        } else if (error.code === "ERR_NETWORK") {
          navigate("/networkerror");
        } */
      });
  }, []);

  if (useParams().id === undefined) {
    id = "0";
    return <div>Not found</div>;
  }

  function lengthValidator(): ValidatorFunction<Optional<string>> {
    return (value) => {
      if (value !== undefined) {
        if (value.length >= 50) {
          return "This field exceeds maximum length!";
        }
      }
      return null;
    };
  }

  async function handleSubmit() {
    if (textInputFormControl.validate() && datePickerFormControl.validate()) {
      const project: ProjectUpdateDTO = {
        projectId: id ? id : "0",
        name: textInputFormControl.value?.toString() as string,
        startDate: datePickerFormControl.value,
      };
      try {
        const res = await projectService.updateProject(project);
        context?.setShowPopup(true);
        context?.setPopupContent(
          <SuccessPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Project updated successfully!
          </SuccessPopup>
        );
      } catch (err: any) {
        context?.setShowPopup(true);
        const msg = err.response.data.message;
        context?.setPopupContent(
          <ErrorPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Something went wrong!{msg}
          </ErrorPopup>
        );
      }
    }
  }
  return (
    <div>
      <FormContainer>
        <h1>Edit Project</h1>
        <FormFieldTextInput
          formControl={textInputFormControl}
          label="Name"
          placeholder="Type something"
          id="name"
        />
        <FormFieldDatePicker
          formControl={datePickerFormControl}
          label="Start Date"
          placeholder="Select a date"
          minDate={DateTime.now().minus({ years: 3 })}
          id="start-date"
        />
        <Button
          type="primary"
          text="Save"
          onClick={handleSubmit}
          isDisabled={
            !textInputFormControl.isValid || !datePickerFormControl.isValid
          }
        />
      </FormContainer>
    </div>
  );
}
