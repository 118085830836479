import axios, { AxiosResponse } from "axios";

import { ComponentCreateDTO } from "../models/component-create-dto";
import { ComponentDetailsDTO } from "../models/component-details-dto";
import { ComponentUpdateDTO } from "../models/component-update-dto";
import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { ExecutionCreateDTO } from "../models/execution-create-dto";
import { ExecutionDetailsDTO } from "../models/execution-details-dto";
import { ExecutionPage } from "../models/execution-page-dto";
import { ExecutionUpdateDTO } from "../models/execution-update-dto";
import Settings from "../appconfiguration.json";
import { SuiteSetCreateDTO } from "../models/suite-set-create-dto";
import { TestCreateDTO } from "../models/test-create-dto";
import { TestDetailsDTO } from "../models/test-details-dto";
import { TestUpdateDTO } from "../models/test-update-dto";

const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;
export class ExecutionService {
    async abortExecution(id: number, projectId: number, suiteSetId: number, execution: ExecutionUpdateDTO) {
        console.log(execution);
        const res = await axios.put<ExecutionUpdateDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Executions/${id}`), execution);
        return res.status === 200 ? true : false;
    }

    async abortExecution2(id: number, projectId: number, suiteSetId: number) {
        const res = await axios.post<ExecutionUpdateDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Executions/${id}/Abort`));
        return res.status === 200 ? true : false;
    }

    async updateExecution(test: TestUpdateDTO, projectId: number, suiteSetId: number) {
        const res = await axios.put<TestUpdateDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Tests/${test.testId}`), test);
        return res.data ? res.data : undefined;
    }

    async getExecution(id: number, projectId: number, suiteSetId: number) {
        const res = await axios.get<ExecutionDetailsDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Executions/${id}`));
        return res.data ? res.data : undefined;
    }
    async createExecution(execution: ExecutionCreateDTO, suiteSetId: number) {
        const res = await axios.post<ExecutionCreateDTO>(Route(`api/Projects/${execution.projectId}/SuiteSets/${suiteSetId}/Executions`), execution);

        return res.data ? res.data : undefined;
    }
    async getExecutions(paginationModel: PageModel | undefined, projectId: number | 0, suiteSetId: number | 0) {
        return axios.request({
            url: Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Executions`),
            method: 'get',
            paramsSerializer: {
                indexes: false
            },
            params: {
                pageNumber: paginationModel?.pageNumber,
                pageSize: paginationModel?.pageSize,
                orderOption: paginationModel?.orderOption,
                filterOptions: paginationModel?.filterOptions
            }
        }).then((res: AxiosResponse<ExecutionPage>) => res.data);

    }
}
