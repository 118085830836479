

export enum ExecutionStatus {
    None,
    Pending,
    Running,
    Completed,
    Failed,
    Aborted
};
