import { Tab, TabMenu } from "../../lib/components/menus/tab-menu/TabMenu";

const tabs: Tab[] = [
  {
    key: "1",
    menuTitle: "Tab 01",
    content: <div>Conteúdo Tab 01</div>,
    isActive: true,
  },
  {
    key: "2",
    menuTitle: "Tab 02",
    content: <div>Conteúdo Tab 02</div>,
  },
  {
    key: "3",
    menuTitle: "Tab 03",
    content: <div>Conteúdo Tab 03</div>,
  },
];

interface IMenusProps {}

export function Menus(props: IMenusProps) {
  return (
    <div className="section buttons">
      <h1>
        <span>01. </span>Tab Menu
      </h1>
      <div className="item">
        <div className="flex-row">
          <TabMenu tabs={tabs || []} />
        </div>
      </div>
    </div>
  );
}
