import { EditSVG, RemoveSVG } from "../../../lib/assets/icons";

import { ComponentService } from "../../../services/ComponentService";
import { EditInstructionPopup } from "../../popups/EditInstructionPopup/EditInstructionPopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { InstructionService } from '../../../services/InstructionService';
import { InstructionTemplateSummaryDTO } from "../../../models/intstruction-template-summary-dto";
import { InstructionsChangedEvent } from "../../../events/InstructionsChangedEvent";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { useCallback } from "react";
import { usePopup } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface ICellProps {
    value: InstructionTemplateSummaryDTO
    projectId: number
}

const instructionService = new InstructionService(); 
const componentService = new ComponentService();

export const NameCell = (props: ICellProps) => {
    return (
        <div className="name-cell">
            <div className="label-name">{props.value.simplified}</div>
        </div>
    );
};

export const DescriptionCell = (props: ICellProps) => {
    return (
        <div className="description-cell">
            <div className="label-name">{props.value.gherkin}</div>
        </div>
    );
};


export const OptionsCell = (props: ICellProps) => {
    const openPopup = usePopup();

    const deleteInstructionCall = useServiceCallPro(instructionService.deleteInstruction);

    /****************************
    * DATA REQUESTS
    *****************************/

    const deleteProject = useCallback(() => {
        deleteInstructionCall.invoke(props.value.instructionTemplateId, props.projectId)
            .then(() => {
                openPopup(
                    <SuccessPopup>Instruction removed successfully!</SuccessPopup>
                );
                InstructionsChangedEvent.fireInstructionsChanged();
            })
            .catch((error) => {
                if (error.response.data) {
                    openPopup(
                        <ErrorPopup>{error.response.data}</ErrorPopup>
                    );
                } else {
                    openPopup(
                        <ErrorPopup>{error.message}</ErrorPopup>
                    );
                }
            });
    }, [deleteInstructionCall.invoke, props.value, props.projectId, openPopup]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleOnDeleteConfirmClicked = useCallback(() => {
        deleteProject();
    }, [deleteProject]);

    const handleEditProjectClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
            <EditInstructionPopup
                projectId={props.projectId}
                instructionId={props.value.instructionTemplateId}
                onCompleted={() => InstructionsChangedEvent.fireInstructionsChanged()}
            />)
    }, [openPopup, props.value.instructionTemplateId]);


    const handleRemoveIconClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
        <WarningPopup onConfirmClicked={handleOnDeleteConfirmClicked}>
            Are you sure you want to delete this instruction?
        </WarningPopup>
    )}, [handleOnDeleteConfirmClicked]);

    
    /****************************
    * CSS & HTML
    *****************************/

    return (
        <div>
            <FlexLayout className="options-cell" direction="horizontal">
                <IconButton size={40} type="tertiary" icon={<EditSVG />} onClick={handleEditProjectClicked}/>
                <IconButton size={40} type="tertiary" icon={<RemoveSVG />} onClick={handleRemoveIconClicked} />
            </FlexLayout>
        </div>
    );
};