import {
  ColumnDefinition,
  IOrderInfo,
} from "../../lib/components/table/TableInterfaces";
import { useCallback, useEffect, useState } from "react";

import { IconButton } from "../../lib/components/buttons/IconButton";
import { ResponsiveDataTable } from "../../lib/components/table/ResponsiveDataTable";
import { RiCheckFill } from "react-icons/ri";
import { RiCloseFill } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Tag } from "../../lib/components/tag/Tag";
import { Tooltip } from "../../lib/components/tooltip/Tooltip";

interface Employee {
  id: string;
  name: string;
  mail: string;
  isActive: boolean;
}

const items: Employee[] = [
  {
    id: "01",
    name: "Henrique Oliveira",
    mail: "henrique.oliveira@n4it.pt",
    isActive: true,
  },
  {
    id: "02",
    name: "Rúben Rodrigo",
    mail: "ruben.rodrigo@n4it.pt",
    isActive: false,
  },
  {
    id: "03",
    name: "Rafael Magno",
    mail: "rafael.magno@n4it.pt",
    isActive: true,
  },
  {
    id: "04",
    name: "José Carlos Arantes",
    mail: "jose.carlos.arantes@n4it.pt",
    isActive: true,
  },
  {
    id: "05",
    name: "Rui Alves",
    mail: "rui.alves@n4it.pt",
    isActive: true,
  },
];

const columns: ColumnDefinition<Employee>[] = [
  {
    columnKey: "id",
    cellRenderProp: (v: Employee) => v.id,
    headerRender: "ID",
    isMobilePrimaryCell: true,
    width: "5%",
  },
  {
    columnKey: "name",
    cellRenderProp: (v: Employee) => v.name,
    headerRender: "Name",
    isMobilePrimaryCell: true,
    isSortable: true,
  },
  {
    columnKey: "mail",
    cellRenderProp: (v: Employee) => (
      <Tooltip text={`Email: ${v.mail}`}>{v.mail}</Tooltip>
    ),
    headerRender: "E-mail",
  },
  {
    columnKey: "isActive",
    cellRenderProp: (v: Employee) => (
      <Tag
        text={v.isActive ? "Ativo" : "Desativo"}
        backgroundColor={v.isActive ? "status-green" : "status-red"}
        icon={v.isActive ? <RiCheckFill /> : <RiCloseFill />}
      />
    ),
    headerRender: "Estado",
    isStatusCell: true,
  },
  {
    columnKey: "visible",
    cellRenderProp: () => <></>,
    headerRender: "Coluna Visível?",
    isVisible: false,
  },
  {
    columnKey: "delete",
    cellRenderProp: (v: Employee) => (
      <IconButton
        type="tertiary"
        icon={<RiDeleteBin6Line />}
        onClick={() => alert(`Delete employee ${v.name}`)}
      />
    ),
    isMobileHeaderIcon: true,
    width: "24px",
  },
];

interface IListsProps {}

export function Lists(props: IListsProps) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
    { columnKey: "name", direction: "ASC" },
  ]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );

  const updateColumnOrderInfo = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderInfoColumns);
      console.log("New order priorities array", orderedPriorities);
    },
    [orderInfoColumns]
  );

  return (
    <div className="section buttons">
      <h1>
        <span>01. </span>Responsive List
      </h1>
      <div className="item">
        <div className="flex-row">
          <ResponsiveDataTable
            columnDefinitions={columns}
            items={items || []}
            totalitems={items.length}
            onClickRow={(item) => alert(item.name)}
            paginationOptions={{
              itemsPerPage: itemsPerPage,
              itemsPerPageOptions: [2, 4, 6, 8],
            }}
            currentpage={currentPage}
            isLoading={isLoading}
            onPageAndItemsChanged={handleOnPageAndItemsChanged}
            showSelectedRowHighlighted={true}
            orderColumns={orderInfoColumns}
            onOrderChanged={(orderInfoPriority) =>
              updateColumnOrderInfo(orderInfoPriority)
            }
          />
        </div>
      </div>
    </div>
  );
}
