import "./SystemPopup.css";

import { useCallback, useMemo } from "react";

import { Button } from "../buttons/Button";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { PopupContainer } from "../../layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../separator/Spacer";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning-icon.svg";
import { useClosePopup } from "../../infrastructure/ui/UIServices";

interface IWarningPopupProps {
  children: React.ReactNode;
  className?: string;
  onDismissClicked?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onConfirmClicked?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  dismissText?: string;
  confirmText?: string;
}

export function WarningPopup(props: IWarningPopupProps) {
  const closePopup = useClosePopup();
  const popupCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("system-popup")
      .add("warning-popup")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);



  const handleDismissClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
    props.onDismissClicked && props.onDismissClicked(ev);
    closePopup();
}, [props.onDismissClicked, closePopup]);

const handleConfirmClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
    props.onConfirmClicked && props.onConfirmClicked(ev);
    closePopup();
}, [props.onConfirmClicked, closePopup]);



  const dismissText = useMemo(
    () => props.dismissText || "Cancel",
    [props.dismissText]
  );
  const confirmText = useMemo(
    () => props.confirmText || "Ok",
    [props.confirmText]
  );

  return (
    <PopupContainer className={popupCss}>
      <div className="popup-icon">
        <WarningIcon />
      </div>
      <Spacer px="14" mode="vertical" />
      <div className="popup-header">Warning!</div>

      <Spacer px="6" mode="vertical" />
      <div className="popup-content">{props.children}</div>
      <Spacer px="24" mode="vertical" />
      <div className="popup-actions">
        <Button text={dismissText} type="secondary" onClick={handleDismissClicked} />
        <Button
          text={confirmText}
          type="primary"
          onClick={handleConfirmClicked}
        />
      </div>
    </PopupContainer>
  );
}
