import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";
import {
  Optional,
  ValidatorFunction,
  requiredValidator,
  useFormControl,
} from "../../lib/components/form/Form";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormFieldDatePicker } from "../../lib/components/form/form-field/FormFieldDatePicker";
import { DateTime } from "luxon";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { Form, useParams } from "react-router-dom";
import { Button } from "../../lib/components/buttons/Button";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { SectionSeparator } from "../../lib/components/form/form-section/SectionSeparator";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { TextButton } from "../../lib/components/buttons/TextButton";
import { useContext, useEffect, useState } from "react";
import { ProjectCreateDTO } from "../../models/project-create-dto";
import { ProjectService } from "../../services/ProjectService";
import { text } from "body-parser";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { UISContext, usePopup } from "../../lib/infrastructure/ui/UIServices";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { Axios, AxiosError } from "axios";
import { TextArea } from "../../lib/components/form/text-area/TextArea";
import { TextInput } from "../../lib/components/form/text-input/TextInput";
import { FormFieldTextArea } from "../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldSelectSingle } from "../../lib/components/form/form-field/FormFieldSelectSingle";
import { RiAddFill, RiSearchLine } from "react-icons/ri";
import { ComponentCreateDTO } from "../../models/component-create-dto";
import { ComponentService } from "../../services/ComponentService";
import { SuiteSetService } from "../../services/SuiteSetService";
import { SuiteSetCreateDTO } from "../../models/suite-set-create-dto";
import { FormFieldSelectMultiple } from "../../lib/components/form/form-field/FormFieldSelectMultiple";
import { ComponentSummaryDTO } from "../../models/component-summary-dto";
import { TestSummaryDTO } from "../../models/test-summary-dto";
import { TestService } from "../../services/TestService";
import { TestType } from "../../models/suite-set-test-type";
import { TestCreateDTO } from "../../models/test-create-dto";
import { TestEditorPopupAdd } from "./TestEditorPopupAdd";
import { InstructionCreateDTO } from "../../models/instruction-create-dto";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { Spacer } from "../../lib/components/separator/Spacer";
const projectService = new ProjectService();
const componentService = new ComponentService();
const testService = new TestService();

const labelProjectSelector = (item: ProjectSummaryDTO) => item.name;
const idProjectSelector = (item: ProjectSummaryDTO) => item.projectId;
const labelTestTypeSelector = (item: string) => item;
const labelComponentSelector = (item: ComponentSummaryDTO) =>
  item.name + " - " + item.projectName;
const idComponentSelector = (item: ComponentSummaryDTO) => item.componentId;
export function CreateTestForm() {
  const openPopup = usePopup();
  const projectId = parseInt(useParams().projectId?.toString() || "0");
  const suiteSetId = parseInt(useParams().suiteSetId?.toString() || "0");
  const [projects, setProjects] = useState<ProjectSummaryDTO[]>([]);
  const [components, setComponents] = useState<ComponentSummaryDTO[]>([]);
  const [instructions, setInstructions] = useState<InstructionCreateDTO[]>([]);
  const context = useContext(UISContext);
  const nameFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator(50)],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const descriptionFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const requirementsFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const projectFormControl = useFormControl<ProjectSummaryDTO>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: {
      projectId: 0,
      name: "",
      startDate: "",
      mostRecentExecutionDate: "",
      mostRecentExecutionStatus: "",
    },
  });

  const priorityFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: undefined,
  });
  const componentFormControl = useFormControl<ComponentSummaryDTO[]>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: [
      {
        componentId: 0,
        name: "",
        description: "",
        projectId: 0,
        projectName: "",
      },
    ],
  });
  useEffect(() => {
    projectService.getProjects(undefined).then((res) => {
      setProjects(res.items);
    });
    componentService.getComponents(undefined, projectId).then((res) => {
      if (res.items.length === 0) {
        componentFormControl.setIsDisabled(true);
      }
      setComponents(res.items);
    });
  }, []);

  function lengthValidator(n: number): ValidatorFunction<Optional<string>> {
    return (value) => {
      if (value !== undefined) {
        if (value.length >= n) {
          return "This field exceeds maximum length!";
        }
      }
      return null;
    };
  }

  async function handleSubmit() {
    if (
      nameFormControl.validate() &&
      descriptionFormControl.validate() &&
      requirementsFormControl.validate() &&
      projectFormControl.validate() &&
      priorityFormControl.validate()
    ) {
      const test: TestCreateDTO = {
        name: nameFormControl.value as string,
        description: descriptionFormControl.value,
        projectId: projectFormControl.value?.projectId as number,
        priority: priorityFormControl.value as string,
        instructions: instructions,
        requirements: requirementsFormControl.value,
        componentIds: componentFormControl.value
          ? componentFormControl.value.map((c) => c.componentId)
          : [],
        userId: "1",
      };
      try {
        const res = await testService.createTest(test, suiteSetId);
        context?.setShowPopup(true);
        context?.setPopupContent(
          <SuccessPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Test created successfully!
          </SuccessPopup>
        );
      } catch (err: any) {
        context?.setShowPopup(true);
        console.log(err);
        const msg = err.response.data.message;
        context?.setPopupContent(
          <ErrorPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Something went wrong!{msg}
          </ErrorPopup>
        );
      }
    }
  }
  return (
    <div>
      <FormContainer>
        <h1>Create Test</h1>
        <FormFieldTextInput
          formControl={nameFormControl}
          label="Name"
          placeholder="Type something"
          id="name"
        />
        <FormFieldSelectSingle
          label="Project"
          placeholder="Select an option"
          formControl={projectFormControl}
          options={projects}
          labelSelector={labelProjectSelector}
          idSelector={idProjectSelector}
          icon={<RiSearchLine />}
        />
        {/*https://www.geeksforgeeks.org/how-to-get-names-of-enum-entries-in-typescript/*/}
        <FormFieldSelectSingle
          label="Priority"
          placeholder="Select an option"
          formControl={priorityFormControl}
          options={Object.getOwnPropertyNames(TestType).filter((prop) =>
            isNaN(parseInt(prop))
          )}
          labelSelector={labelTestTypeSelector}
          idSelector={labelTestTypeSelector}
          icon={<RiSearchLine />}
        />
        <FormFieldSelectMultiple
          label="Components"
          placeholder="Select an option"
          formControl={componentFormControl}
          options={components}
          labelSelector={labelComponentSelector}
          idSelector={idComponentSelector}
          icon={<RiSearchLine />}
        />

        <FormFieldTextArea
          formControl={descriptionFormControl}
          label="Description"
          placeholder="Type something"
        />
        <FormFieldTextArea
          formControl={requirementsFormControl}
          label="Requirements"
          placeholder="Type something"
        />
        <FlexLayout direction={"horizontal"}>
          <Button
            icon={<RiAddFill />}
            type="tertiary"
            text="Add instructions"
            onClick={() =>
              openPopup(
                <TestEditorPopupAdd
                  projectId={projectId.toString()}
                  testName={nameFormControl.value as string}
                  onInstructionsUpdated={(
                    instructions: InstructionCreateDTO[]
                  ) => {
                    if (instructions !== undefined) {
                      setInstructions(instructions);
                    }
                  }}
                />
              )
            }
            isDisabled={
              !nameFormControl.isValid ||
              !descriptionFormControl.isValid ||
              !projectFormControl.isValid ||
              !priorityFormControl.isValid ||
              !componentFormControl.isValid ||
              !requirementsFormControl.isValid
            }
          />
          <Spacer mode={"horizontal"}></Spacer>
          <Button type="primary" text="Create Test" onClick={handleSubmit} />
        </FlexLayout>
      </FormContainer>
    </div>
  );
}
