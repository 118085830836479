import "./NavBar.css";

import { Button } from "../buttons/Button";
import { ReactComponent as FolderSVG } from "../../assets/icons/folder-title.svg";
import { NavBarAppMenu } from "./nav-bar-app-menu/NavBarAppMenu";
import { ReactComponent as NavbarIconSVG } from "../../assets/icons/navbar-icon.svg";
import { useNavigate } from "react-router-dom";
import { useSelectedProject } from "../../infrastructure/ui/UIServices";
import { useState } from "react";

//import { useIdentityUserDisplayName } from "../../infrastructure/identity/hooks/useIdentityUserDisplayName";

export function NavBar() {
  const navigate = useNavigate();

  const selectedProject = useSelectedProject();

  //const {userDisplayName, userDisplayNameIsLoading} = useIdentityUserDisplayName();

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="header">
      <div className="header-menu-btn" onClick={() => {setShowMenu(!showMenu)}}><NavbarIconSVG /></div>
      <div className="header-app-logo" onClick={() => navigate("/")}>Galileo</div>
      <div className="header-space"></div>
      
        {selectedProject ? (<div className="header-project"><FolderSVG></FolderSVG> {selectedProject.name} </div>) : null}
     
 
      {showMenu && <NavBarAppMenu onClose={() => setShowMenu(false)} />}
    </div>
  );
}
