import "./Components.css";

import { SimpleCard } from "../../lib/components/cards/SimpleCard";
import { useNavigate } from "react-router-dom";

interface IComponentsProps {}

export function Components(props: IComponentsProps) {
  const navigate = useNavigate();

  return (
    <div className="components">
      <h2>Components</h2>
      <div className="components-list">
        <SimpleCard
          title="Buttons"
          onClick={() => navigate("buttons")}
          hideAvatar
        />
        <SimpleCard
          title="Inputs"
          onClick={() => navigate("inputs")}
          hideAvatar
        />
        <SimpleCard
          title="Sections"
          onClick={() => navigate("sections")}
          hideAvatar
        />
        <SimpleCard
          title="Lists"
          onClick={() => navigate("lists")}
          hideAvatar
        />
        <SimpleCard
          title="Menus"
          onClick={() => navigate("menus")}
          hideAvatar
        />
      </div>
    </div>
  );
}
