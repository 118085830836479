import "./ExecutionDetailsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useInitLoader,
  useServiceCallPro,
} from "../../../../lib/hooks/useServiceCall";
import { useNavigate, useParams } from "react-router-dom";
import {
  usePopup,
  useSelectedTestSuite,
} from "../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../lib/components/buttons/Button";
import { DownloadSVG } from "../../../../lib/assets/icons";
import { Empty } from "../../../../lib/components/table/empty/Empty";
import { ExecutionService } from "../../../../services/ExecutionService";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { ReportService } from "../../../../services/ReportService";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { ReportDetailsDTO } from "../../../../models/report-details-dto";
import { ReportRadialBarChart } from "./reports/ReportRadialBarChart";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { FormSection } from "../../../../lib/components/form/form-section/FormSection";
import { DateTime } from "luxon";

const executionSvc = new ExecutionService();
const reportSvc = new ReportService();

export function ExecutionDetailsPage() {
  useEffect(() => {
    getReport();
  }, []);
  const navigate = useNavigate();
  const openPopup = usePopup();
  const initLoader = useInitLoader();
  const { projectId, suiteId, executionId } = useParams();

  const projectIdAsNumber = useMemo(
    () => parseInt(projectId || "0"),
    [projectId]
  );
  const suiteIdAsNumber = useMemo(() => parseInt(suiteId || "0"), [suiteId]);
  const executionIdAsNumber = useMemo(
    () => parseInt(executionId || "0"),
    [executionId]
  );
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const selectedTestSuite = useSelectedTestSuite();

  const getReportCall = useServiceCallPro(reportSvc.getLatestReport);
  const [report, setReport] = useState<ReportDetailsDTO | undefined>(undefined);
  const [showReport, setShowReport] = useState(true);
  /****************************
   * DATA REQUESTS
   *****************************/
  const getReport = useCallback(() => {
    getReportCall
      .invoke(projectIdAsNumber, suiteIdAsNumber, executionIdAsNumber)
      .then((res) => {
        setReport(res);
      })
      .catch((err) => {
        console.log(err);
        setShowReport(false);
      });
  }, [getReportCall, projectIdAsNumber, suiteIdAsNumber, executionIdAsNumber]);

  return (
    <PageContainer className="execution-details-page">
      <FlexLayout direction="horizontal" className="page-header">
        <div className="page-header-title">
          <h1>
            {selectedTestSuite && "[" + selectedTestSuite?.name + "] "}{" "}
            Executions
          </h1>
        </div>
        <div className="page-header-actions">
          <Button
            className="addproject-button"
            icon={<DownloadSVG />}
            text="Download Report"
            type="primary"
            onClick={() => {
              alert("not implemented");
            }}
          />
        </div>
      </FlexLayout>
      <Spacer mode="vertical" />
      {showReport ? (
        <FlexLayout direction={"horizontal"}>
          <ReportRadialBarChart report={report} />
          <FormSection
            title="Time Interval"
            childrenFlexDirColumn
            className="time-interval"
          >
            <InfoBlock
              label="End Date"
              value={
                report?.start
                  ? DateTime.fromISO(report?.start).toFormat("dd/MM/yyyy")
                  : ""
              }
            />
            <InfoBlock
              label="Started At"
              value={
                report?.start
                  ? DateTime.fromISO(report?.start).toFormat("HH : mm")
                  : ""
              }
            />
            <InfoBlock
              label="Ended At"
              value={
                report?.start
                  ? DateTime.fromISO(report?.end).toFormat("HH : mm")
                  : ""
              }
            />
          </FormSection>
        </FlexLayout>
      ) : (
        <div>
          <Spacer mode={"vertical"} />
          <CardContainer>
            <div className="error-color">
              <h1>Report not generated!</h1>
            </div>
          </CardContainer>
        </div>
      )}

      <Spacer mode="vertical" px={20} />
      <div className="table">
        <Empty />
      </div>
    </PageContainer>
  );
}
