import { DateTime } from "luxon";
import { useCallback, useContext, useState } from "react";
import { ProjectService } from "../../services/ProjectService";
import React from "react";
import { ResponsiveDataTable } from "../../lib/components/table/ResponsiveDataTable";
import {
  ColumnDefinition,
  IOrderInfo,
} from "../../lib/components/table/TableInterfaces";
import "../ListTable.css";
import {
  RiAddFill,
  RiCheckFill,
  RiCloseFill,
  RiDeleteBin7Fill,
  RiEditFill,
  RiEyeFill,
  RiSearchFill,
} from "react-icons/ri";
import { Tag } from "../../lib/components/tag/Tag";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { Tooltip } from "../../lib/components/tooltip/Tooltip";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { useFormControl } from "../../lib/components/form/Form";
import { UISContext, usePopup } from "../../lib/infrastructure/ui/UIServices";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { InstructionEditorPopup } from "./InstructionEditorPopup";
import { InstructionService } from "../../services/InstructionService";
import { InstructionEditorPopupAdd } from "./InstructionEditorPopupAdd";
import { InstructionTemplateSummaryDTO } from "../../models/intstruction-template-summary-dto";
import { TestEditorPopupAdd } from "../tests/TestEditorPopupAdd";
const instructionService = new InstructionService();

export function ListInstructionsTemplatesTable() {
  const projectId = parseInt(useParams().id?.toString() || "0");
  const openPopup = usePopup();
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const textInputFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });

  const context = useContext(UISContext);
  const handleDelete = useCallback(
    async (id: number, projectId: number) => {
      if (context !== null) {
        context.setShowPopup(true);
        context.setPopupContent(
          <WarningPopup
            onConfirmClicked={async () => {
              const res = await instructionService.deleteInstruction(
                id,
                projectId
              );
              if (res) {
                setNeedsRefreshing(!needsRefreshing);
                context.setPopupContent(
                  <SuccessPopup
                    onDismissClicked={() => {
                      context?.setShowPopup(false);
                      setNeedsRefreshing(!needsRefreshing);
                    }}
                  >
                    Instruction removed successfully!
                  </SuccessPopup>
                );
              } else {
                context.setPopupContent(
                  <ErrorPopup
                    onDismissClicked={() => {
                      context?.setShowPopup(false);
                    }}
                  >
                    Error deleting instruction!
                  </ErrorPopup>
                );
              }
            }}
            onDismissClicked={() => {
              context.setShowPopup(false);
            }}
          >
            Are you sure you want to delete this instruction?
          </WarningPopup>
        );
      }
    },
    [context, needsRefreshing]
  );

  const navigate = useNavigate();
  const columns: ColumnDefinition<InstructionTemplateSummaryDTO>[] = [
    {
      columnKey: "Instruction",
      cellRenderProp: (v: InstructionTemplateSummaryDTO) => v.simplified,
      headerRender: isSearching ? (
        <div className="search-header">
          <FormContainer>
            <FlexLayout direction="horizontal" horizontalAlign="start">
              <FormFieldTextInput
                formControl={textInputFormControl}
                placeholder="Instruction"
                id="instruction"
              />
              <IconButton
                type="primary"
                icon={<RiSearchFill />}
                onClick={() => {
                  setIsSearching(!isSearching);
                  search();
                }}
                isRound
                className="search-icon-box"
              />
            </FlexLayout>
          </FormContainer>
        </div>
      ) : (
        <div className="search-header">
          <FlexLayout direction="horizontal" horizontalAlign="start">
            Instruction
            <IconButton
              type="primary"
              icon={<RiSearchFill />}
              onClick={() => {
                setIsSearching(!isSearching);
              }}
              isRound
              className="search-icon"
            />
          </FlexLayout>
        </div>
      ),
      isMobilePrimaryCell: true,
      isSortable: true,
    },

    /* {
      columnKey: "view",
      cellRenderProp: (v: InstructionTemplateSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiEyeFill />}
          onClick={() => navigate("/projects/" + v.projectId)}
          id={v.name.toString() + "-view"}
        />
      ),
      isMobileHeaderIcon: true,
      width: "24px",
    }, */
    {
      columnKey: "edit",
      cellRenderProp: (v: InstructionTemplateSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiEditFill />}
          onClick={() => {
            openPopup(
              <InstructionEditorPopup
                projectId={projectId.toString()}
                instructionId={v.instructionTemplateId.toString()}
                onInstructionUpdated={() => {
                  setNeedsRefreshing(!needsRefreshing);
                }}
              />
            );
          }}
          id={v.simplified.toString() + "-edit"}
        />
      ),
      isMobileHeaderIcon: true,
      width: "24px",
    },
    {
      columnKey: "delete",
      cellRenderProp: (v: InstructionTemplateSummaryDTO) => (
        <IconButton
          type="tertiary"
          icon={<RiDeleteBin7Fill />}
          onClick={() => {
            handleDelete(v.instructionTemplateId, projectId);
          }}
          id={v.simplified.toString() + "-delete"}
        />
      ),
      isMobileHeaderIcon: true,
      headerRender: (
        <IconButton
          type="primary"
          isRound={true}
          icon={<RiAddFill />}
          onClick={() => {
            console.log("add new");
            openPopup(
              <InstructionEditorPopupAdd
                projectId={projectId.toString()}
                onInstructionUpdated={() => {
                  setNeedsRefreshing(!needsRefreshing);
                }}
              />
            );
          }}
          id="add-new"
        />
      ),
      width: "24px",
    },
  ];
  const [filters, setFilters] = useState<IFilterOption[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
    { columnKey: "Instruction", direction: "ASC" },
  ]);
  const [lastOrderInfoColumn, setLastOrderInfoColumn] = useState<IOrderInfo>();
  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );

  const updateColumnOrderInfo = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderInfoColumns);
      setLastOrderInfoColumn(orderedPriorities[orderedPriorities.length - 1]);
    },
    [orderInfoColumns]
  );

  const [totalItems, setTotalItems] = React.useState(0);
  const [items, setItems] = React.useState(
    [] as InstructionTemplateSummaryDTO[]
  );
  const search = useCallback(async () => {
    let filters: IFilterOption[] = [];
    if (textInputFormControl.value) {
      filters.push({
        field: "simplified",
        value: textInputFormControl.value,
        exact: "false",
      } as IFilterOption);
    }
    setFilters(filters);
  }, [textInputFormControl]);

  React.useEffect(() => {
    setIsLoading(true);
    instructionService
      .getInstructions(
        {
          pageNumber: currentPage,
          pageSize: itemsPerPage,
          orderOption: lastOrderInfoColumn,
          filterOptions: filters,
        } as PageModel,
        projectId
      )
      .then((datat) => {
        if (datat != null) {
          setTotalItems(datat.totalItems || 0);
          setItems(datat.items || []);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          navigate("/networkerror");
        } else if (error.response?.status === 404) {
          navigate("/notfound");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    needsRefreshing,
    currentPage,
    itemsPerPage,
    lastOrderInfoColumn,
    filters,
    projectId,
    navigate,
  ]);
  return (
    <div>
      <h1>Instruction Templates</h1>
      <ResponsiveDataTable
        items={items}
        columnDefinitions={columns}
        totalitems={totalItems}
        currentpage={currentPage}
        paginationOptions={{
          itemsPerPage: itemsPerPage,
          itemsPerPageOptions: [5, 10, 20],
        }}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
        onOrderChanged={updateColumnOrderInfo}
        isLoading={isLoading}
        resizeThreshold={600}
      ></ResponsiveDataTable>
    </div>
  );
}
