import { Route, Routes } from "react-router-dom";

import { ConsultComponent } from "../../components/component/ConsultComponent";
import { ConsultExecution } from "../../components/executions/ConsultExecution";
import { ConsultProject } from "../../components/project/ConsultProject";
import { ConsultSuiteSet } from "../../components/suite-sets/ConsultSuiteSet";
import { ConsultTest } from "../../components/tests/ConsultTest";
import { CreateComponentForm } from "../../components/component/CreateComponentForm";
import { CreateProjectForm } from "../../components/project/CreateProjectForm";
import { CreateSuiteSetForm } from "../../components/suite-sets/CreateSuiteSetForm";
import { CreateTestForm } from "../../components/tests/CreateTestForm";
import { EditComponentForm } from "../../components/component/EditComponentForm";
import { EditProjectForm } from "../../components/project/EditProjectForm";
import { EditSuiteSetForm } from "../../components/suite-sets/EditSuiteSetForm";
import { EditTestForm } from "../../components/tests/EditTestForm";
import { ListComponentsTable } from "../../components/component/ListComponentsTable";
import { ListInstructionsTemplatesTable } from "../../components/instruction-template/ListInstructionTemplatesTable";
import { ListProjectsTable } from "../../components/project/ListProjectsTable";
import { ListSuiteSetsTable } from "../../components/suite-sets/ListSuiteSetsTable";
import { Main } from "../../pages/Main";

export function ProjectsOldModule() {




    return (
      
    <Routes>
         <Route path="new" element={<Main><CreateProjectForm /></Main> }/>
            <Route path="/" element={ <Main> <ListProjectsTable /> </Main> } />
            <Route path=":id/edit" element={ <Main> <EditProjectForm /> </Main> } />
            <Route path=":id" element={ <Main> <ConsultProject /> <ListSuiteSetsTable></ListSuiteSetsTable> <ListComponentsTable></ListComponentsTable> <ListInstructionsTemplatesTable></ListInstructionsTemplatesTable> </Main> } ></Route>
            <Route path=":projectId/suiteSets/new" element={ <Main> <CreateSuiteSetForm></CreateSuiteSetForm> </Main> } ></Route>
            <Route path=":projectId/suiteSets/:suiteSetId" element={ <Main> <ConsultSuiteSet /> </Main> } />
            <Route path=":projectId/suiteSets/:suiteSetId/executions/:id" element={ <Main> <ConsultExecution /> </Main> } />
            <Route path=":projectId/suiteSets/:suiteSetId/edit" element={ <Main> <EditSuiteSetForm /> </Main> } />
            <Route path=":projectId/suiteSets/:suiteSetId/tests/:testId/edit" element={ <Main> <EditTestForm /> </Main> } ></Route>
            <Route path=":projectId/suiteSets/:suiteSetId/tests/:testId" element={ <Main> <ConsultTest /> </Main> } ></Route>
            <Route path=":projectId/suiteSets/:suiteSetId/tests/new" element={ <Main> <CreateTestForm /> </Main> } ></Route>
            <Route path=":projectId/components/new" element={ <Main> <CreateComponentForm /> </Main> } />
            <Route path=":projectId/components/:componentId" element={ <Main> <ConsultComponent /> </Main> } />
            <Route path=":projectId/components/:componentId/edit" element={ <Main> <EditComponentForm /> </Main> } />
    </Routes>

    );
}