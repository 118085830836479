import axios, { AxiosResponse } from "axios";
import Settings from "../appconfiguration.json";
import { ComponentCreateDTO } from "../models/component-create-dto";
import { ComponentDetailsDTO } from "../models/component-details-dto";
import { ComponentUpdateDTO } from "../models/component-update-dto";
import { SuiteSetCreateDTO } from "../models/suite-set-create-dto";
import { TestCreateDTO } from "../models/test-create-dto";
import { TestDetailsDTO } from "../models/test-details-dto";
import { TestUpdateDTO } from "../models/test-update-dto";
import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { TestPage } from "../models/test-page-dto";

const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;

export class TestService {
    async deleteTest(id: number, projectId: number, suiteSetId: number) {
        const res = await axios.delete(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Tests/${id}`));
        return res.status === 200 ? true : false;
    }

    async updateTest(test: TestUpdateDTO, projectId: number, suiteSetId: number) {
        const res = await axios.put<TestUpdateDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Tests/${test.testId}`), test);
        return res.data ? res.data : undefined;
    }

    async getTest(id: number, projectId: number, suiteSetId: number) {
        const res = await axios.get<TestDetailsDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Tests/${id}`));
        return res.data ? res.data : undefined;
    }
    async createTest(test: TestCreateDTO, suiteSetId: number) {
        const res = await axios.post<TestCreateDTO>(Route(`api/Projects/${test.projectId}/SuiteSets/${suiteSetId}/Tests`), test);

        return res.data ? res.data : undefined;
    }
    async getTests(paginationModel: PageModel | undefined, projectId: number | 0, suiteSetId: number | 0) {
        return axios.request({
            url: Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Tests`),
            method: 'get',
            paramsSerializer: {
                indexes: false
            },
            params: {
                pageNumber: paginationModel?.pageNumber,
                pageSize: paginationModel?.pageSize,
                orderOption: paginationModel?.orderOption,
                filterOptions: paginationModel?.filterOptions
            }
        }).then((res: AxiosResponse<TestPage>) => res.data);

    }
}
