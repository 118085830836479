import "./DatePickerCalendar.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  changeDateMonth,
  changeDateYear,
  getCalendarRows,
} from "../../../helpers/calendar/Calendar";

import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/arrow.svg";
import { DateTime } from "luxon";
import { ReactComponent as DoubleChevronDownIcon } from "../../../assets/icons/double-arrow.svg";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

export interface IDatePickerCalendarProps {
  selectedDate: DateTime | undefined;
  onChange: (newDate: DateTime | undefined) => void;
  calendarPosition: {};
  onClickOutside: () => void;
  hasMinSelectableDate?: boolean;
  minDate?: DateTime | undefined;
  maxDate?: DateTime | undefined;
}

export function DatePickerCalendar(props: IDatePickerCalendarProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [shownDate, setShownDate] = useState<DateTime>(DateTime.now());

  const currentDate = useMemo(() => {
    return DateTime.now();
  }, [DateTime.now().day]);

  useEffect(() => {
    if (props.selectedDate) setShownDate(props.selectedDate);
  }, [props.selectedDate]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleSelectDate = (value: DateTime | undefined) => {
    return () => props.onChange(value);
  };

  const rows = useMemo(() => getCalendarRows(shownDate), [shownDate]);

  const handleIconClick = useCallback(
    (isNextMonth: boolean) => {
      return () => {
        setShownDate(changeDateMonth(shownDate, isNextMonth));
      };
    },
    [setShownDate, shownDate]
  );

  const handleDoubleIconClick = useCallback(
    (isNextYear: boolean) => {
      return () => {
        setShownDate(changeDateYear(shownDate, isNextYear));
      };
    },
    [setShownDate, shownDate]
  );

  const handleClickOutside = useCallback(
    (e: any) => {
      if (ref.current) {
        if (!ref.current.contains(e.target)) {
          props.onClickOutside();
          e.stopPropagation();
        }
      }
    },
    [props.onClickOutside]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, [handleClickOutside]);

  const isLeftChevronIconDisabled = useMemo(() => {
    if (props.minDate) {
      const minYear = props.minDate.year;
      const minMonth = props.minDate.month;

      const shownYear = shownDate.year;
      const shownMonth = shownDate.month;

      const canGoBack =
        (shownMonth > minMonth && shownYear === minYear) || shownYear > minYear;
      return !canGoBack;
    }
    return false;
  }, [shownDate, props.minDate]);

  const isRightChevronIconDisabled = useMemo(() => {
    if (props.maxDate) {
      const maxYear = props.maxDate.year;
      const maxMonth = props.maxDate.month;

      const shownYear = shownDate.year;
      const shownMonth = shownDate.month;

      const canGoFoward =
        (shownMonth < maxMonth && shownYear === maxYear) || shownYear < maxYear;
      return !canGoFoward;
    }
    return false;
  }, [shownDate, props.maxDate]);

  /****************************
   * CSS & HTML
   *****************************/

  const renderDatePickerHeader = useMemo(() => {
    return (
      <div className={"date-picker-selector"}>
        <div className="date-picker-selector-icon-box">
          <div
            className={
              `date-picker-selector-icon date-picker-selector-icon-double date-picker-selector-icon-left ` +
              (isLeftChevronIconDisabled ? "disable" : "")
            }
            onClick={handleDoubleIconClick(false)}
          >
            <DoubleChevronDownIcon />
          </div>
          <div
            className={
              `date-picker-selector-icon date-picker-selector-icon-left ` +
              (isLeftChevronIconDisabled ? "disable" : "")
            }
            onClick={handleIconClick(false)}
          >
            <ChevronDownIcon />
          </div>
        </div>
        <div className={"date-picker-selector-date"}>
          {shownDate.toFormat("MMMM yyyy")}
        </div>
        <div className="date-picker-selector-icon-box">
          <div
            className={
              `date-picker-selector-icon date-picker-selector-icon-right ` +
              (isRightChevronIconDisabled ? "disable" : "")
            }
            onClick={handleIconClick(true)}
          >
            <ChevronDownIcon />
          </div>
          <div
            className={
              `date-picker-selector-icon date-picker-selector-icon-double date-picker-selector-icon-right ` +
              (isRightChevronIconDisabled ? "disable" : "")
            }
            onClick={handleDoubleIconClick(true)}
          >
            <DoubleChevronDownIcon />
          </div>
        </div>
      </div>
    );
  }, [
    handleIconClick,
    isLeftChevronIconDisabled,
    isRightChevronIconDisabled,
    shownDate,
  ]);

  const renderDatePickerCells = useMemo(() => {
    return rows.map((cells, rowIndex) => (
      <div key={rowIndex} className={"date-picker-calendar-row"}>
        {cells.map(({ text, value }, i) => (
          <div
            id="calendar-cell"
            key={`${text} - ${i}`}
            className={
              `date-picker-calendar-cell date-picker-calendar-day-cell ` +
              (value.month.valueOf() !== shownDate.month.valueOf() ||
              (props.minDate && props.minDate > value) ||
              (props.maxDate && props.maxDate < value)
                ? "out-of-selected-month "
                : "") +
              (value.toFormat("dd/MM/yyyy") ===
              currentDate.toFormat("dd/MM/yyyy")
                ? "date-picker-calendar-day-cell-today "
                : "") +
              (value.toFormat("dd/MM/yyyy") ===
              (props.selectedDate && props.selectedDate.toFormat("dd/MM/yyyy"))
                ? "date-picker-calendar-day-cell-selected"
                : "")
            }
            onClick={
              value.month.valueOf() === shownDate.month.valueOf()
                ? handleSelectDate(value)
                : () => {}
            }
          >
            {text}
          </div>
        ))}
      </div>
    ));
  }, [
    rows,
    shownDate,
    currentDate,
    props.hasMinSelectableDate,
    props.selectedDate,
    handleSelectDate,
  ]);

  return (
    <div className="date-picker-panel-wrapper">
      <div className="date-picker-panel-wrapper-ref">
        <div
          ref={ref}
          style={props.calendarPosition}
          className="date-picker-select-panel"
        >
          {renderDatePickerHeader}

          <div className={"date-picker-calendar-header"}>
            <div className={"date-picker-calendar-cell"}>
              {translate("DESIGNSYSTEM.CALENDAR.Sunday")}
            </div>
            <div className={"date-picker-calendar-cell"}>
              {translate("DESIGNSYSTEM.CALENDAR.Monday")}
            </div>
            <div className={"date-picker-calendar-cell"}>
              {translate("DESIGNSYSTEM.CALENDAR.Tuesday")}
            </div>
            <div className={"date-picker-calendar-cell"}>
              {translate("DESIGNSYSTEM.CALENDAR.Wednesday")}
            </div>
            <div className={"date-picker-calendar-cell"}>
              {translate("DESIGNSYSTEM.CALENDAR.Thursday")}
            </div>
            <div className={"date-picker-calendar-cell"}>
              {translate("DESIGNSYSTEM.CALENDAR.Friday")}
            </div>
            <div className={"date-picker-calendar-cell"}>
              {translate("DESIGNSYSTEM.CALENDAR.Saturday")}
            </div>
          </div>

          {renderDatePickerCells}

          <div className="options-clear" onClick={handleSelectDate(undefined)}>
            {translate("DESIGNSYSTEM.ClearSelection")}
          </div>
        </div>
      </div>
    </div>
  );
}
