import "./ProjectsHomePage.css";

import {
  ColumnDefinition,
  IOrderInfo,
} from "../../lib/components/table/TableInterfaces";
import { OptionsCell, StatsCell } from "./ProjectsTableCells";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCreateNotification,
  usePopup,
} from "../../lib/infrastructure/ui/UIServices";
import {
  useInitLoader,
  useServiceCallPro,
} from "../../lib/hooks/useServiceCall";

import { AddProjectPopup } from "../popups/AddProjectPopup/AddProjectPopup";
import { Button } from "../../lib/components/buttons/Button";
import { DateTime } from "luxon";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { PageContainer } from "../../lib/layouts/containers/page/PageContainer";
import { PlusSVG } from "../../lib/assets/icons";
import { ProjectService } from "../../services/ProjectService";
import { ProjectsChangedEvent } from "../../events/ProjectsChangedEvent";
import { ResponsiveDataTable } from "../../lib/components/table/ResponsiveDataTable";
import { Spacer } from "../../lib/components/separator/Spacer";
import { useFormControl } from "../../lib/components/form/Form";
import { useNavigate } from "react-router-dom";

const projectService = new ProjectService();

export function ProjectsHomePage() {
  const navigate = useNavigate();
  const openPopup = usePopup();
  const createNotification = useCreateNotification();

  const [projects, setProjects] = useState([] as ProjectSummaryDTO[]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
    { columnKey: "name", direction: "ASC" },
  ]);
  const [lastOrderInfoColumn, setLastOrderInfoColumn] = useState<IOrderInfo>();
  const [filters, setFilters] = useState<IFilterOption[]>();
  const nameFilterFormControl = useFormControl<string>({ initialValue: "" });
  const initLoader = useInitLoader();

  const getProjectsCall = useServiceCallPro(projectService.getProjects);

  /****************************
   * DATA REQUESTS
   *****************************/

  const getProjects = useCallback(() => {
    setIsLoading(true);

    getProjectsCall
      .invoke({
        pageNumber: currentPage,
        pageSize: itemsPerPage,
        orderOption: lastOrderInfoColumn,
        filterOptions: filters,
      } as PageModel)
      .then((data) => {
        if (data != null) {
          setTotalItems(data.totalItems || 0);
          setProjects(data.items || []);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          navigate("/networkerror");
        } else if (error.response?.status === 404) {
          navigate("/notfound");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    getProjectsCall.invoke,
    filters,
    lastOrderInfoColumn,
    itemsPerPage,
    currentPage,
    setIsLoading,
    setTotalItems,
    setProjects,
    navigate,
  ]);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/
  useEffect(() => {
    if (!initLoader && currentPage === 0) {
      getProjects();
    } else {
      setCurrentPage(0);
    }
  }, [itemsPerPage]);

  useEffect(() => {
    getProjects();
  }, [currentPage, filters, lastOrderInfoColumn]);

  useEffect(() => {
    const handleProjectsEvent = () => {
      getProjects();
    };

    ProjectsChangedEvent.attachHandler(handleProjectsEvent);
    return () => {
      ProjectsChangedEvent.detachHandler(handleProjectsEvent);
    };
  }, [getProjects]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleClickSearchProjects = useCallback(() => {
    let filters: IFilterOption[] = [];
    if (nameFilterFormControl.value && nameFilterFormControl.isValid) {
      filters.push({
        field: "Name",
        value: nameFilterFormControl.value,
        exact: "false",
      } as IFilterOption);
    }
    setFilters(filters);
  }, [nameFilterFormControl, setFilters]);

  const handleAddProjectClicked = useCallback(() => {
    openPopup(<AddProjectPopup onCompleted={getProjects} />);
  }, [openPopup, getProjects]);

  const updateColumnOrderInfo = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderInfoColumns);
      setLastOrderInfoColumn(orderedPriorities[orderedPriorities.length - 1]);
    },
    [orderInfoColumns]
  );

  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );

  /****************************
   * CSS & HTML
   *****************************/

  const columns: ColumnDefinition<ProjectSummaryDTO>[] = useMemo(
    () => [
      {
        columnKey: "Name",
        headerRender: "Name",
        cellRenderProp: (v: ProjectSummaryDTO) => v.name,
        isMobilePrimaryCell: true,
        isSortable: true,
      },
      {
        columnKey: "StartDate",
        cellRenderProp: (v: ProjectSummaryDTO) =>
          DateTime.fromISO(v.startDate).toFormat("dd/MM/yyyy"),
        headerRender: "Start Date",
        isSortable: true,
      },
      {
        columnKey: "status",
        cellRenderProp: (v: ProjectSummaryDTO) => <StatsCell value={v} />,
        headerRender: "Latest Execution",
        isStatusCell: true,
      },
      {
        cellRenderProp: (v: ProjectSummaryDTO) => <OptionsCell value={v} />,
        width: "15%",
        headerRender: <></>,
        columnKey: "options",
        isSortable: false,
      },
    ],
    []
  );
  /*<Button
      className="addproject-button"
      text="OLD FRONTEND"
      type="secondary"
      onClick={() => navigate("/projects")}
    />; */

  return (
    <PageContainer className="projects-page">
      <FlexLayout direction="horizontal" className="projects-page-header">
        <div className="page-header-title">
          <h1>Projects</h1>
        </div>
        <div className="page-header-actions">
          <Button
            className="addproject-button"
            icon={<PlusSVG />}
            text="Add Project"
            type="primary"
            onClick={handleAddProjectClicked}
          />
        </div>
      </FlexLayout>

      <Spacer mode="vertical" px={20} />

      <div className="project-filters">
        <div className="project-filters-form">
          <FormFieldTextInput
            label="Name"
            formControl={nameFilterFormControl}
            placeholder="Insert the project name"
          />
        </div>

        <div className="project-filters-action">
          <Button
            className="search-button"
            text="Search"
            type="secondary"
            onClick={handleClickSearchProjects}
          />
        </div>
      </div>

      <Spacer mode="vertical" px={20} />
      <div className="flex-row">
        <ResponsiveDataTable
          columnDefinitions={columns}
          items={projects || []}
          totalitems={totalItems}
          onClickRow={(item) => navigate("" + item.projectId)}
          paginationOptions={{
            itemsPerPage: itemsPerPage,
            itemsPerPageOptions: [10, 25, 50, 100],
          }}
          currentpage={currentPage}
          isLoading={isLoading}
          onPageAndItemsChanged={handleOnPageAndItemsChanged}
          showSelectedRowHighlighted={true}
          orderColumns={orderInfoColumns}
          onOrderChanged={(orderInfoPriority) =>
            updateColumnOrderInfo(orderInfoPriority)
          }
        />
      </div>
    </PageContainer>
  );
}
