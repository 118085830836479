
import { InstructionCreateDTO } from "../models/instruction-create-dto";
import { InstructionParameterCreateDTO } from "../models/instruction-parameter-create-dto";
import { InstructionParameterTemplateCreateDTO } from "../models/instruction-parameter-template-create-dto";
import { InstructionParameterTemplateSummaryDTO } from "../models/instruction-parameter-template-summary-dto";
import { InstructionTemplateSummaryDTO } from "../models/intstruction-template-summary-dto";

export class InstructionCompiler {


    static new() {
        return new InstructionCompiler();
    }



    createInstructionParameterTemplates(simplified: string): InstructionParameterTemplateCreateDTO[] {
        // Regular expression to match the pattern <name:description>
        const pattern = /<([^:]+):([^>]+)>/g;

        const result: InstructionParameterTemplateCreateDTO[] = [];

        // Use regex to find all matches
        let match: RegExpExecArray | null;


        var alreadyExists;
        while (((match = pattern.exec(simplified)) !== null)) {
            // Destructure the match to get name and description
            const [_, name, description] = match;

            // Create the DTO object and add it to the result array
            if (result.length === 0) {

                result.push({ name, description });
                alreadyExists = true;
            }
            for (let i = 0; i < result.length; i++) {
                if (result[i].name === name) {
                    alreadyExists = true;
                }
            }
            if (!alreadyExists) {
                result.push({ name, description });
            }
            alreadyExists = false;

        }

        return result;
    }

    getInstructions(fullTestCode: string): string[] {
        const lines = fullTestCode.split('\n');
        let currentInstruction = '';
        var j = 1;
        var fixed = 0;
        for (let i = 0; i < lines.length; i++) {
            j = i + 1;
            lines[fixed] = lines[fixed].trim();
            currentInstruction = lines[fixed];
            if (lines[j] !== undefined) {
                lines[j] = lines[j].trim();

                if (lines[j].startsWith('|') && lines[j].endsWith('|')) {
                    currentInstruction += '\n' + lines[j];
                    lines[fixed] = currentInstruction;
                    lines[j] = '';
                } else {
                    fixed++;
                }

            }
        }
        return lines.filter(function (el) { return el !== ""; });
    }

    isMatchedInRegexList(instructionSimplified: string, list: InstructionTemplateSummaryDTO[]): InstructionTemplateSummaryDTO[] {
        var compatibleInstructions: InstructionTemplateSummaryDTO[] = [];
        var regex: RegExp;
        for (let i = 0; i < list.length; i++) {
            if (list[i].regex !== undefined) {
                regex = new RegExp(list[i].regex as string);
                if (regex.test(instructionSimplified)) {

                    compatibleInstructions.push(list[i]);
                }
            }

        }
        return compatibleInstructions;
    }
    compileInstructionSummaryToInstructionCreateDTO(instructionSummary: InstructionTemplateSummaryDTO, instruction: string, orderNumber: number): InstructionCreateDTO {
        const parameters = this.compileInstructionIntoInstructionParameterList(instruction, instructionSummary);
        var gherkin = instructionSummary.gherkin;
        var code = instructionSummary.code;

        if (instructionSummary.parameters !== undefined) {
            for (let i = 0; i < instructionSummary.parameters.length; i++) {
                gherkin = gherkin?.replaceAll('<' + instructionSummary.parameters[i].name + ':' + instructionSummary.parameters[i].description + '>', parameters[i].value)
                code = code?.replaceAll('<' + instructionSummary.parameters[i].name + ':' + instructionSummary.parameters[i].description + '>', parameters[i].value)
            }
        }
        console.log("ORDER\n\n\n");
        console.log(orderNumber);


        const instructionCreateDTO: InstructionCreateDTO = {
            instructionTemplateId: instructionSummary.instructionTemplateId,
            instructionParameters: parameters,
            simplified: instruction,
            gherkin: gherkin,
            code: code,
            orderPosition: orderNumber

        };

        return instructionCreateDTO;
    }

    compileInstructionIntoInstructionParameterList(instruction: string, instructionSummary: InstructionTemplateSummaryDTO): InstructionParameterCreateDTO[] {
        const pattern = new RegExp(instructionSummary.regex as string, 'g');
        const result: InstructionParameterCreateDTO[] = [];
        let match: RegExpExecArray | null;
        var alreadyExists
        let i = 0;
        while (((match = pattern.exec(instruction)) !== null)) {
            // Destructure the match to get name and description
            if (instructionSummary.parameters !== undefined) {
                for (let i = 1; i < match.length; i++) {

                    if (match[i] === undefined)
                        break;
                    result.push({ instructionParameterTemplateId: instructionSummary.parameters[i - 1].instructionParameterTemplateId, value: match[i] });
                }
            }
        }
        return result;
    }
}