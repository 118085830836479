import './ExecutionsHomePage.css'

import { ColumnDefinition, IOrderInfo } from '../../../../lib/components/table/TableInterfaces';
import { DateCell, IdCell, OptionsCell, StatsCell, TimeCell } from './ExecutionsTableCells';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInitLoader, useServiceCallPro } from '../../../../lib/hooks/useServiceCall';
import { useNavigate, useParams } from 'react-router-dom';
import { usePopup, useSelectedTestSuite } from '../../../../lib/infrastructure/ui/UIServices';

import { Button } from '../../../../lib/components/buttons/Button';
import { DateTime } from 'luxon';
import { ErrorPopup } from '../../../../lib/components/popup/ErrorPopup';
import { ExecutionCreateDTO } from '../../../../models/execution-create-dto';
import { ExecutionService } from '../../../../services/ExecutionService';
import { ExecutionSummaryDTO } from '../../../../models/execution-summary-dto';
import { ExecutionsChangedEvent } from '../../../../events/ExecutionsChangedEvent';
import { FlexLayout } from '../../../../lib/layouts/containers/flex/FlexLayout';
import { PageContainer } from '../../../../lib/layouts/containers/page/PageContainer';
import { PlusSVG } from '../../../../lib/assets/icons';
import { ResponsiveDataTable } from '../../../../lib/components/table/ResponsiveDataTable';
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { SuccessPopup } from '../../../../lib/components/popup/SuccessPopup';

const executionSvc = new ExecutionService();

export function ExecutionsHomePage() {

    const navigate = useNavigate();
    const openPopup = usePopup();
    const initLoader = useInitLoader()
    const {projectId, suiteId} = useParams();

    const projectIdAsNumber = useMemo(() =>  parseInt(projectId||"0"), [projectId]);
    const suiteIdAsNumber = useMemo(() =>  parseInt(suiteId||"0"), [suiteId]);

    const selectedTestSuite = useSelectedTestSuite();

    const [executions, setExecutions] = useState<ExecutionSummaryDTO[]>([]);

    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
      {
        columnKey: "Name",
        direction: "ASC",
      }
    ]);


    const getExecutionsCall = useServiceCallPro(executionSvc.getExecutions);
    const createExecutionCall = useServiceCallPro(executionSvc.createExecution);
  /****************************
  * DATA REQUESTS
  *****************************/

    const getExecutions = useCallback(() => {
        const request : PageModel = {
            pageNumber: currentPage,
            pageSize: itemsPerPage,
            orderOption: undefined,
            filterOptions: undefined
        //   column: orderInfoColumns[0]?.columnKey || 'Name',
        //   isDescending: orderInfoColumns[0]?.direction === 'DESC' || false,
        //   name: nameFilterFormControl.value
        }


        getExecutionsCall.invoke(request, projectIdAsNumber, suiteIdAsNumber).then((res) => {
            setExecutions(res.items);
            setTotalItems(res.totalItems)
        });
    
      }, [currentPage, itemsPerPage, getExecutionsCall, projectIdAsNumber, suiteIdAsNumber]);

      const createExecution = useCallback(() => {
        
        const execution: ExecutionCreateDTO = {
          suiteSetId: suiteIdAsNumber,
          executionDate: DateTime.now().toUTC(),
          projectId: projectIdAsNumber
        };
      
        return createExecutionCall.invoke(execution,suiteIdAsNumber)
    
      }, [suiteIdAsNumber, projectIdAsNumber, createExecutionCall]);
      
/****************************
  * DATA MANIPULATION EFFECTS
 *****************************/

    useEffect(() => {
        if (!initLoader && currentPage === 0) {
            getExecutions();
        } else {
          setCurrentPage(0)
        }
      }, [itemsPerPage]);
    
      useEffect(() => {
        getExecutions();
      }, [currentPage, orderInfoColumns]);

      useEffect(() => {
        const handleExecutionsEvent = () => {
            getExecutions();
        }
    
        ExecutionsChangedEvent.attachHandler(handleExecutionsEvent);
        return () => { ExecutionsChangedEvent.detachHandler(handleExecutionsEvent); };
      }, [getExecutions]);


  /****************************
  * USER ACTIONS
  *****************************/
    const handleAddExecutionClicked = useCallback(() => {
      createExecution()
        .then(() => {
              openPopup(<SuccessPopup> <div>Execution created successfully!</div> </SuccessPopup>);
              getExecutions()
        }).catch((err) => {
              console.log(err);
              openPopup(<ErrorPopup><div>{err.response.data.message}</div></ErrorPopup>);
        });
    }, [createExecution, getExecutions, openPopup]);


      const handleClickSearch = useCallback(() => {
        getExecutions();
      }, [getExecutions]);
    
      const handleOnOrderChanged = useCallback(
        (orderedPriorities: IOrderInfo[]) => {
          setOrderInfoColumns(orderedPriorities);
        }, []);

        const handleOnPageAndItemsChanged = useCallback(
            (items: number, currentPage: number) => {
              setItemsPerPage(items);
              setCurrentPage(currentPage);
            },
            [setItemsPerPage, setCurrentPage]
          );

  /****************************
  * CSS & HTML
  *****************************/
    const columns: ColumnDefinition<ExecutionSummaryDTO>[] = useMemo(() =>  [
    {
        cellRenderProp: (v) => <IdCell value={v} projectId={projectIdAsNumber} suiteId={suiteIdAsNumber} />,
        width: "30%",
        headerRender: <>ID</>,
        columnKey: "id",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <DateCell value={v} projectId={projectIdAsNumber} suiteId={suiteIdAsNumber} />,
        width: "40%",
        headerRender: <>Date</>,
        columnKey: "date",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <TimeCell value={v} projectId={projectIdAsNumber} suiteId={suiteIdAsNumber} />,
        headerRender: <>Time</>,
        columnKey: "time",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <StatsCell value={v} projectId={projectIdAsNumber} suiteId={suiteIdAsNumber} />,
        headerRender: <>Status</>,
        columnKey: "status",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <OptionsCell value={v} projectId={projectIdAsNumber}  suiteId={suiteIdAsNumber} />,
        headerRender: <></>,
        columnKey: "options",
        isSortable: false,
        },
    ],[projectIdAsNumber, suiteIdAsNumber]);



    return (

        <PageContainer className="executions-page">

            <FlexLayout direction="horizontal" className="executions-page-header">
                <div className="page-header-title">
                    <h1>{selectedTestSuite && ("["+selectedTestSuite?.name + "] ")} Executions</h1>
                </div>
                <div className="page-header-actions">
                    <Button
                        className="addproject-button"
                        icon={<PlusSVG />}
                        text="Add Execution"
                        type="primary"
                        onClick={handleAddExecutionClicked}
                    />
                </div>
            </FlexLayout>

            <Spacer mode="vertical" px={20} />
                 
            <div className="table">

                <ResponsiveDataTable
                    items={executions}
                    columnDefinitions={columns}
                    totalitems={totalItems}
                    currentpage={currentPage}
                    paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [5, 10, 20],
                    }}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                    onOrderChanged={handleOnOrderChanged}
                    isLoading={getExecutionsCall.isLoading}
                    onClickRow={(item) => navigate("" +item.executionId)}
                    resizeThreshold={510}
    
                ></ResponsiveDataTable>
            </div>

        </PageContainer>
    )
}