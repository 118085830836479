import { ErrorPage } from "../../components/error-page/ErrorPage";

export function NotFoundPage() {


    return (<ErrorPage
        mainTitle={"Orbital Watch"}
        errorName={"Not found!"}
        errorMessage={
          "The resource you are looking for does not exist."
        }
        buttonText={"Return to Base"}
      ></ErrorPage>)
}