import './TestDefinitionsPage.css'

import { ColumnDefinition, IOrderInfo } from '../../../../lib/components/table/TableInterfaces';
import { DescriptionCell, NameCell, OptionsCell, PriorityCell } from './TestTableCells';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInitLoader, useServiceCallPro } from '../../../../lib/hooks/useServiceCall';
import { useNavigate, useParams } from 'react-router-dom';
import { usePopup, useSelectedTestSuite } from '../../../../lib/infrastructure/ui/UIServices';

import { AddInstructionPopup } from '../../../popups/AddInstructionPopup/AddInstructionPopup';
import { AddTestDefinitionPopup } from '../../../popups/AddTestDefinitionPopup/AddTestDefinitionPopup';
import { Button } from '../../../../lib/components/buttons/Button';
import { FlexLayout } from '../../../../lib/layouts/containers/flex/FlexLayout';
import { InstructionTemplateSummaryDTO } from '../../../../models/intstruction-template-summary-dto';
import { InstructionsChangedEvent } from '../../../../events/InstructionsChangedEvent';
import { PageContainer } from '../../../../lib/layouts/containers/page/PageContainer';
import { PlusSVG } from '../../../../lib/assets/icons';
import { ResponsiveDataTable } from '../../../../lib/components/table/ResponsiveDataTable';
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { TestService } from '../../../../services/TestService';
import { TestSummaryDTO } from '../../../../models/test-summary-dto';
import { TestsChangedEvent } from '../../../../events/TestsChangedEvent';

const testService = new TestService();


export function TestDefinitionsPage() {

    const navigate = useNavigate();
    const openPopup = usePopup();
    const initLoader = useInitLoader()
    const {projectId, suiteId} = useParams();

    const projectIdAsNumber = useMemo(() =>  parseInt(projectId||"0"), [projectId]);
    const suiteIdAsNumber = useMemo(() =>  parseInt(suiteId||"0"), [suiteId]);

    const selectedTestSuite = useSelectedTestSuite();

    const [tests, setTests] = useState<TestSummaryDTO[]>([]);

    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
      {
        columnKey: "Name",
        direction: "ASC",
      }
    ]);


    const getTestsCall = useServiceCallPro(testService.getTests);
  /****************************
  * DATA REQUESTS
  *****************************/

    const getTests = useCallback(() => {
        const request : PageModel = {
            pageNumber: currentPage,
            pageSize: itemsPerPage,
            orderOption: undefined,
            filterOptions: undefined
        //   column: orderInfoColumns[0]?.columnKey || 'Name',
        //   isDescending: orderInfoColumns[0]?.direction === 'DESC' || false,
        //   name: nameFilterFormControl.value
        }


        getTestsCall.invoke(request, projectIdAsNumber, suiteIdAsNumber).then((res) => {
            setTests(res.items);
            setTotalItems(res.totalItems)
        });
    
      }, [getTestsCall.invoke, currentPage, itemsPerPage, orderInfoColumns, setTests, setTotalItems]);

      
/****************************
  * DATA MANIPULATION EFFECTS
 *****************************/

    useEffect(() => {
        if (!initLoader && currentPage === 0) {
            getTests();
        } else {
          setCurrentPage(0)
        }
      }, [itemsPerPage]);
    
      useEffect(() => {
        getTests();
      }, [currentPage, orderInfoColumns]);

      useEffect(() => {
        const handleInstructionsEvent = () => {
            getTests();
        }
    
        TestsChangedEvent.attachHandler(handleInstructionsEvent);
        return () => { TestsChangedEvent.detachHandler(handleInstructionsEvent); };
      }, [getTests]);


  /****************************
  * USER ACTIONS
  *****************************/
    const handleAddInstructionClicked = useCallback(() => {
      openPopup(<AddTestDefinitionPopup suiteId={suiteIdAsNumber} projectId={projectIdAsNumber} onCompleted={getTests} />)
    }, [getTests, openPopup, projectIdAsNumber]);


      const handleClickSearch = useCallback(() => {
        getTests();
      }, [getTests]);
    
      const handleOnOrderChanged = useCallback(
        (orderedPriorities: IOrderInfo[]) => {
          setOrderInfoColumns(orderedPriorities);
        }, []);

        const handleOnPageAndItemsChanged = useCallback(
            (items: number, currentPage: number) => {
              setItemsPerPage(items);
              setCurrentPage(currentPage);
            },
            [setItemsPerPage, setCurrentPage]
          );

  /****************************
  * CSS & HTML
  *****************************/
    const columns: ColumnDefinition<TestSummaryDTO>[] = useMemo(() =>  [
    {
        cellRenderProp: (v) => <NameCell value={v} projectId={projectIdAsNumber} suiteId={suiteIdAsNumber} />,
        width: "30%",
        headerRender: <>Name</>,
        columnKey: "Name",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <DescriptionCell value={v} projectId={projectIdAsNumber} suiteId={suiteIdAsNumber} />,
        width: "40%",
        headerRender: <>Description</>,
        columnKey: "Description",
        isSortable: true,
    },
    {
        cellRenderProp: (v) => <PriorityCell value={v} projectId={projectIdAsNumber} suiteId={suiteIdAsNumber} />,
        width: "10%",
        headerRender: <>Priority</>,
        columnKey: "Description",
        isSortable: true,
    },

    {
        cellRenderProp: (v) => <OptionsCell value={v} projectId={projectIdAsNumber}  suiteId={suiteIdAsNumber} />,
        width: "15%",
        headerRender: <></>,
        columnKey: "options",
        isSortable: false,
        },
    ],[projectIdAsNumber, suiteIdAsNumber]);



    return (

        <PageContainer className="suite-test-page">

            <FlexLayout direction="horizontal" className="suite-test-page-header">
                <div className="page-header-title">
                    <h1>{selectedTestSuite && ("["+selectedTestSuite?.name + "] ")} Tests</h1>
                </div>
                <div className="page-header-actions">
                    <Button
                        className="addproject-button"
                        icon={<PlusSVG />}
                        text="Add Test"
                        type="primary"
                        onClick={handleAddInstructionClicked}
                    />
                </div>
            </FlexLayout>

            <Spacer mode="vertical" px={20} />
                 
            <div className="table">

                <ResponsiveDataTable
                    items={tests}
                    columnDefinitions={columns}
                    totalitems={totalItems}
                    currentpage={currentPage}
                    paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [5, 10, 20],
                    }}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                    onOrderChanged={handleOnOrderChanged}
                    isLoading={getTestsCall.isLoading}
                    onClickRow={(item) => navigate("" +item.testId)}
                    resizeThreshold={510}
    
                ></ResponsiveDataTable>
            </div>

        </PageContainer>
    )
}