import { EditSVG, RemoveSVG } from "../../lib/assets/icons";
import { useCallback, useMemo } from "react";

import { DateTime } from "luxon";
import { EditProjectPopup } from "../popups/EditProjectPopup/EditProjectPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { ExecutionStatus } from "../../models/execution-status";
import { ExecutionStatusTag } from "../../components/ExecutionStatusTag";
import { FlexLayout } from '../../lib/layouts/containers/flex/FlexLayout';
import { IconButton } from '../../lib/components/buttons/IconButton';
import { ProjectService } from "../../services/ProjectService";
import { ProjectsChangedEvent } from "../../events/ProjectsChangedEvent";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { usePopup } from "../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro } from "../../lib/hooks/useServiceCall";

const projectSvc = new ProjectService();

interface ICellProps {
    value: ProjectSummaryDTO
}

export const StatsCell = (props: ICellProps) => {

    const stat = useMemo(() => ExecutionStatus[props.value.mostRecentExecutionStatus as keyof typeof ExecutionStatus] ?? ExecutionStatus.None, [props.value]);

    return (   
        <div className="stats-cell">
            <ExecutionStatusTag
                status={stat}
                toolTipName="Date"
                toolTipMessage={DateTime.fromISO(props.value.mostRecentExecutionDate).toFormat("dd/MM/yyyy")}
            />       
        </div>
    );
};



export const OptionsCell = (props: ICellProps) => {
    const openPopup = usePopup();

    const deleteProjectCall = useServiceCallPro(projectSvc.deleteProject);

    /****************************
    * DATA REQUESTS
    *****************************/

    const deleteProject = useCallback(() => {
        deleteProjectCall.invoke(props.value.projectId)
            .then(() => {
                openPopup(
                    <SuccessPopup>Project removed with success!</SuccessPopup>
                );
                ProjectsChangedEvent.fireProjectsChanged();
            })
            .catch((error) => {
                if (error.response.data) {
                    openPopup(
                        <ErrorPopup>{error.response.data}</ErrorPopup>
                    );
                } else {
                    openPopup(
                        <ErrorPopup>{error.message}</ErrorPopup>
                    );
                }
            });
    }, [deleteProjectCall.invoke, props.value, openPopup]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleOnDeleteConfirmClicked = useCallback(() => {
        deleteProject();
    }, [deleteProject]);

    const handleEditProjectClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
            <EditProjectPopup
                projectId={props.value.projectId}
                onCompleted={() => ProjectsChangedEvent.fireProjectsChanged()}
            />)
    }, [openPopup, props.value]);


    const handleRemoveIconClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
        <WarningPopup onConfirmClicked={handleOnDeleteConfirmClicked}>
            You are removing this project.
        </WarningPopup>
    )}, [handleOnDeleteConfirmClicked]);

    
    /****************************
    * CSS & HTML
    *****************************/

    return (
        <div>
            <FlexLayout className="options-cell" direction="horizontal">
                <IconButton size={40} type="tertiary" icon={<EditSVG />} onClick={handleEditProjectClicked}/>
                <IconButton size={40} type="tertiary" icon={<RemoveSVG />} onClick={handleRemoveIconClicked} />
            </FlexLayout>
        </div>
    );
};