import "./EditTestDefinitionPopup.css";

import {
  Optional,
  ValidatorFunction,
  requiredValidator,
  useFormControl,
} from "../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useClosePopup,
  usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { CodeiumEditor } from "@codeium/react-code-editor";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { InstructionCompiler } from "../../../utils/InstructionCompiler";
import { InstructionCreateDTO } from "../../../models/instruction-create-dto";
import { InstructionService } from "../../../services/InstructionService";
import { InstructionTemplateSummaryDTO } from "../../../models/intstruction-template-summary-dto";
import { Loader } from "../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { SearchSVG } from "../../../lib/assets/icons";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { TabMenu } from "../../../lib/components/menus/tab-menu/TabMenu";
import { TestService } from "../../../services/TestService";
import { TestType } from "../../../models/suite-set-test-type";
import { TestUpdateDTO } from "../../../models/test-update-dto";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IProps {
  projectId: number;
  suiteId: number;
  testId: number;
  onCompleted: () => void;
}

export function lengthValidator(
  n: number
): ValidatorFunction<Optional<string>> {
  return (value) => {
    if (value !== undefined) {
      if (value.length >= n) {
        return "This field exceeds maximum length!";
      }
    }
    return null;
  };
}

interface IDetectedInstructions {
  instructions: InstructionCreateDTO[] | undefined;
  templates: InstructionTemplateSummaryDTO[] | undefined;
}

const regexLoad = 50;

const instructionSvc = new InstructionService();
const testService = new TestService();

const labelPrioritySelector = (item: string) => item;

export function EditTestDefinitionPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const nameFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator(50)],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const descriptionFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const requirementsFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const priorityFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: undefined,
  });

  const [isSyntaxValid, setIsSyntaxValid] = useState<boolean>(false);
  const [detectedInstructionshtml, setDetectedInstructionshtml] = useState<
    JSX.Element[]
  >([]);
  const [content, setContent] = useState<string>();
  const [instructions, setInstructions] = useState<InstructionCreateDTO[]>([]);

  const getTestCall = useServiceCallPro(testService.getTest);
  const updateTestCall = useServiceCallPro(testService.updateTest);
  const getInstructionsCall = useServiceCallPro(instructionSvc.getInstructions);

  /****************************
   * DATA REQUESTS
   *****************************/

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  const isFormValid = useMemo(
    () =>
      nameFormControl.isValid &&
      descriptionFormControl.isValid &&
      priorityFormControl.isValid &&
      requirementsFormControl.isValid &&
      nameFormControl.value &&
      priorityFormControl.value,
    [
      nameFormControl.isValid,
      nameFormControl.value,
      descriptionFormControl.isValid,
      priorityFormControl.isValid,
      priorityFormControl.value,
      requirementsFormControl.isValid,
    ]
  );

  const isButtonDisabled = useMemo(() => {
    return !isFormValid || updateTestCall.isLoading || getTestCall.isLoading;
  }, [getTestCall.isLoading, isFormValid, updateTestCall.isLoading]);

  useEffect(() => {
    if (instructions) {
      var buildContent = "";
      for (var instruction of instructions) {
        buildContent += instruction.simplified + "\n";
      }
      setContent(buildContent);
    }
  }, [instructions]);

  useEffect(() => {
    getTestCall
      .invoke(props.testId, props.projectId, props.suiteId)
      .then((data) => {
        if (data != null) {
          nameFormControl.setValue(data.name);
          descriptionFormControl.setValue(data.description);
          requirementsFormControl.setValue(data.requirements);
          priorityFormControl.setValue(data.priority);
          setInstructions(data.instructions);
        }
      })
      .catch((error) => {
        openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
      });
  }, []);

  /****************************
   * USER ACTIONS
   *****************************/

  const getDetectedInstructions =
    useCallback(async (): Promise<IDetectedInstructions> => {
      const compiler = new InstructionCompiler();
      var valid = true;
      var instructionsDetected: string[] = [];

      if (content !== undefined) {
        instructionsDetected = compiler.getInstructions(content);

        let matchedTemplates: InstructionTemplateSummaryDTO[] = [];
        let instructionsToCreate: InstructionCreateDTO[] = [];
        let res;
        if (instructionsDetected.length > 0) {
          var length = regexLoad;
          var page = 0;
          while (length === regexLoad) {
            res = await getInstructionsCall.invoke(
              {
                pageNumber: page,
                pageSize: regexLoad,
                orderOption: [],
                filterOptions: [],
              },
              props.projectId
            );
            for (var j = 0; j < instructionsDetected.length; j++) {
              var match = compiler.isMatchedInRegexList(
                instructionsDetected[j],
                res.items
              );
              for (var i = 0; i < match.length; i++) {
                matchedTemplates.push(match[i]);
                instructionsToCreate.push(
                  compiler.compileInstructionSummaryToInstructionCreateDTO(
                    match[i],
                    instructionsDetected[j],
                    j
                  )
                );
              }
            }
            length = res.items.length;
            valid = true;
          }
          if (instructionsToCreate.length === 0) {
            valid = false;
          }
        } else {
          valid = false;
        }

        if (valid) {
          return {
            instructions: instructionsToCreate,
            templates: matchedTemplates,
          };
        } else {
          return {
            instructions: undefined,
            templates: undefined,
          } as IDetectedInstructions;
        }
      }
      return {
        instructions: [],
        templates: [],
      } as IDetectedInstructions;
    }, [content, getInstructionsCall, props.projectId]);

  const formatDetectedInstructionsHTML = useCallback(
    (
      instructionsToCreate: InstructionCreateDTO[],
      matchedTemplates: InstructionTemplateSummaryDTO[]
    ): JSX.Element[] => {
      const html = [];
      for (let i = 0; i < instructionsToCreate.length; i++) {
        const paramshtml = [];
        const parameterTemplates = matchedTemplates[i].parameters;
        const parameters = instructionsToCreate[i].instructionParameters;
        if (parameters !== undefined) {
          for (let j = 0; j < parameters.length; j++) {
            paramshtml.push([
              <InfoBlock
                key={"param" + j}
                label={parameterTemplates?.[j].name}
                value={parameters[j].value}
              />,
            ]);
          }

          html.push([
            <FormSection
              key={"instruction" + i}
              title={matchedTemplates[i].simplified}
              isInputGap
              childrenFlexDirColumn
            >
              {paramshtml}
            </FormSection>,
          ]);
        }
      }
      return html.flat();
    },
    []
  );

  const handleCheckSyntaxClicked = useCallback(async () => {
    setDetectedInstructionshtml([]);
    var detected = await getDetectedInstructions();
    if (
      detected.instructions !== undefined &&
      detected.templates !== undefined
    ) {
      setIsSyntaxValid(true);
      setDetectedInstructionshtml(
        formatDetectedInstructionsHTML(
          detected.instructions,
          detected.templates
        )
      );
      setInstructions(detected.instructions);
    } else {
      setIsSyntaxValid(false);
    }
  }, [formatDetectedInstructionsHTML, getDetectedInstructions]);

  const handleEditTestClicked = useCallback(async () => {
    if (!isFormValid || updateTestCall.isLoading) return;

    var detected = await getDetectedInstructions();
    const testDefinition: TestUpdateDTO = {
      testId: props.testId,
      name: nameFormControl.value as string,
      priority: priorityFormControl.value as string,
      description: descriptionFormControl.value,
      requirements: requirementsFormControl.value,
      instructions:
        detected.instructions && detected.templates
          ? detected.instructions
          : [],
    };

    updateTestCall
      .invoke(testDefinition, props.projectId, props.suiteId)
      .then((response) => {
        openPopup(
          <SuccessPopup>
            <div>Test edited successfully!</div>
          </SuccessPopup>
        );

        props.onCompleted();
      })
      .catch((err) => {
        console.log(err);
        openPopup(
          <ErrorPopup>
            <div>{err.response.data.message}</div>
          </ErrorPopup>
        );
      });
  }, [
    isFormValid,
    updateTestCall,
    getDetectedInstructions,
    props,
    openPopup,
    nameFormControl.value,
    priorityFormControl.value,
    descriptionFormControl.value,
    requirementsFormControl.value,
  ]);

  /****************************
   * CSS & HTML
   *****************************/

  if (updateTestCall.isLoading) {
    return (
      <PopupContainer className="edit-test-definition-popup">
        <Loader />
      </PopupContainer>
    );
  }

  return (
    <PopupContainer className="edit-test-definition-popup">
      <div className="edit-test-definition-popup-header">
        <h3>Edit test definition</h3>
        <FlexLayout
          className="act-btns"
          direction="horizontal"
          horizontalAlign="end"
          verticalAlign="center"
        >
          <TextButton text="Cancel" type="white" onClick={() => closePopup()} />
          <Button
            text="Edit"
            type="primary"
            isDisabled={isButtonDisabled}
            onClick={handleEditTestClicked}
          />
        </FlexLayout>
      </div>

      <FlexLayout direction="horizontal">
        <div className="edit-test-definition-popup-form-container">
          <FormFieldTextInput
            classNameFormField="edit-test-definition-popup-formfield"
            id="name"
            label="Name"
            placeholder="Insert name"
            formControl={nameFormControl}
          />
          <FormFieldSelectSingle
            classNameFormField="edit-test-definition-popup-formfield"
            label="Type of Test"
            placeholder="Select an option"
            formControl={priorityFormControl}
            options={Object.getOwnPropertyNames(TestType).filter((prop) =>
              isNaN(parseInt(prop))
            )}
            labelSelector={labelPrioritySelector}
            idSelector={labelPrioritySelector}
            icon={<SearchSVG />}
          />
        </div>
      </FlexLayout>

      <FlexLayout direction="horizontal">
        <div className="edit-test-definition-popup-form-container">
          <FormFieldTextArea
            classNameFormField="edit-test-definition-popup-formfield"
            label="Description"
            placeholder="Insert description"
            formControl={descriptionFormControl}
          />
          <FormFieldTextArea
            classNameFormField="edit-test-definition-popup-formfield"
            label="Requirements"
            placeholder="Insert requirements"
            formControl={requirementsFormControl}
          />
        </div>
      </FlexLayout>

      <FlexLayout direction={"horizontal"}>
        <div className="edit-test-definition-popup-form-container">
          <Button
            text="Check syntax"
            type="tertiary"
            //isDisabled={isButtonDisabled}
            onClick={handleCheckSyntaxClicked}
          />

          <InfoBlock
            label={
              "Use the editor below to write the test with simplified instructions and the compiler will do the rest for you."
            }
            value={"The check syntax button will validate the test."}
          />
        </div>
      </FlexLayout>

      <FlexLayout direction={"horizontal"} horizontalAlign="end">
        <TabMenu
          className="edit-test-definition-popup-tab-menu"
          tabs={[
            {
              key: "1",
              menuTitle: "Code Editor",
              content: (
                <div>
                  <FlexLayout
                    direction={"horizontal"}
                    horizontalAlign={"center"}
                  >
                    <FormContainer className="edit-test-definition-popup-code-container">
                      <FormSection title="Test Specification">
                        <CodeiumEditor
                          theme="vs-dark"
                          value={content}
                          onChange={setContent}
                          language={""}
                          containerClassName=""
                        />
                      </FormSection>
                    </FormContainer>
                  </FlexLayout>
                </div>
              ),
              isActive: true,
            },
            {
              key: "2",
              menuTitle: "GUI Editor",
              content: "GUI Editor",
            },
          ]}
        ></TabMenu>
        <div className="detected-instructions">
          <FormContainer>
            <h2>
              {isSyntaxValid
                ? "Detected Instructions"
                : "No instructions detected"}
            </h2>
            {detectedInstructionshtml}
          </FormContainer>
        </div>
      </FlexLayout>
    </PopupContainer>
  );
}
