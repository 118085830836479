import "./EditProjectPopup.css";

import { requiredValidator, useFormControl } from "../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldDatePicker } from "../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { ProjectService } from "../../../services/ProjectService";
import { ProjectUpdateDTO } from "../../../models/project-update-dto";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { lengthValidator } from "../AddProjectPopup/AddProjectPopup";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IProps {
    projectId: number;
    onCompleted: () => void;
}

const projectService = new ProjectService();

export function EditProjectPopup(props: IProps) {

    const closePopup = useClosePopup();
    const openPopup = usePopup();

    var projectNameFormControl = useFormControl<string>({ validators: [requiredValidator(), lengthValidator()], initialValue: '' });
    var datePickerFormControl = useFormControl<DateTime>({ validators: [requiredValidator()], enableAutoValidate: true, isDisabled: false });

    const getProjectCall = useServiceCallPro(projectService.getProject);
    const updateProjectCall = useServiceCallPro(projectService.updateProject);


    /****************************
     * DATA REQUESTS
     *****************************/

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    const isFormValid = useMemo(() => projectNameFormControl.isValid && datePickerFormControl.isValid
        && projectNameFormControl.value && datePickerFormControl.value, [projectNameFormControl, datePickerFormControl])

    const isButtonDisabled = useMemo(() => {
        return !isFormValid || getProjectCall.isLoading || updateProjectCall.isLoading;
    }, [isFormValid, getProjectCall.isLoading, updateProjectCall.isLoading]);

    useEffect(() => {
        getProjectCall.invoke(props.projectId.toString()).then((response) => {
            if (response != null) {
                projectNameFormControl.setValue(response.name);
                datePickerFormControl.setValue(DateTime.fromISO(response.startDate));
            }
        }).catch((error) => {
            openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        });

    }, []);

    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnClickEditProject = useCallback(() => {

        if (!isFormValid) return;

        const project: ProjectUpdateDTO = {
            projectId: props.projectId.toString(),
            name: projectNameFormControl.value?.toString() as string,
            startDate: datePickerFormControl.value,
        };

        updateProjectCall.invoke(project).then((response) => {
            openPopup(<SuccessPopup>
                <div>Project edited with success!</div>
            </SuccessPopup>);
            props.onCompleted();

        }).catch((error) => {
            openPopup(<ErrorPopup><div>{error.response.data.message}</div></ErrorPopup>);
        });
    }, [isFormValid, updateProjectCall, projectNameFormControl.value, datePickerFormControl.value, props, openPopup]);

    /****************************
     * CSS & HTML
     *****************************/

    if (getProjectCall.isLoading || updateProjectCall.isLoading) {
        return (
            <PopupContainer className="edit-project-popup">
                <Loader />
            </PopupContainer>
        )
    }

    return (
        <PopupContainer className="edit-project-popup">

            <h3>Edit project</h3>
            <Spacer mode="vertical" px="16" />

            <FormContainer>
                <FormFieldTextInput  id="name" label="Name" placeholder="Insert project name" formControl={projectNameFormControl} />
                <FormFieldDatePicker id="start-date" label="Start Date" placeholder="Select a date" formControl={datePickerFormControl} minDate={DateTime.now().minus({ years: 3 })} />
            </FormContainer>

            <Spacer mode="vertical" px="16" />
            <LineSeparator />

            <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >
                <TextButton type="white" text={"Cancel"} onClick={() => closePopup()} />
                <Button text="Edit" type="primary" isDisabled={isButtonDisabled}
                    onClick={handleOnClickEditProject} />
            </FlexLayout>
        </PopupContainer>
    );
}

