//EditPagePopup.tsx on Ark Web - MdEditor is container where code is

import "./TestEditor.css";

import { CodeiumEditor, Language } from "@codeium/react-code-editor";
import {
  UISContext,
  useCloseEditor,
  useClosePopup,
  usePopup,
} from "../../lib/infrastructure/ui/UIServices";
import {
  requiredValidator,
  useFormControl,
} from "../../lib/components/form/Form";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button } from "../../lib/components/buttons/Button";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../lib/layouts/containers/flex/FlexLayout";
import { Form } from "react-router-dom";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { InstructionCompiler } from "../../utils/InstructionCompiler";
import { InstructionCreateDTO } from "../../models/instruction-create-dto";
import { InstructionDetailsDTO } from "../../models/instruction-details-dto";
import { InstructionParameterTemplateCreateDTO } from "../../models/instruction-parameter-template-create-dto";
import { InstructionService } from "../../services/InstructionService";
import { InstructionTemplateCreateDTO } from "../../models/instruction-template-create-dto";
import { InstructionTemplateDetailsDTO } from "../../models/instruction-template-details-dto";
import { InstructionTemplateSummaryDTO } from "../../models/intstruction-template-summary-dto";
import { InstructionTemplateUpdateDTO } from "../../models/instruction-template-update-dto";
import { LineSeparator } from "../../lib/components/separator/LineSeparator";
import ParameterTemplateCrossValidation from "../../utils/ParameterTemplateCrossValidation";
import { PopupArea } from "../../lib/layouts/containers/popup-area/PopupArea";
import { PopupContainer } from "../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { TabMenu } from "../../lib/components/menus/tab-menu/TabMenu";
import { TestDetailsDTO } from "../../models/test-details-dto";
import { TestService } from "../../services/TestService";
import { TextButton } from "../../lib/components/buttons/TextButton";
import { get } from "http";
import { updateNamedImports } from "typescript";

/* im baby |mldwke|
|mlaksd|
|mlaksd|
|mlaksd|
|mlaksd|
I have 1 and 2 and 3 and 4 and 5 */
export interface ITestEditorPopupAddProps {
  projectId: string;
  onInstructionsUpdated?: (ins: any) => void;
  testName?: string;
  instructions?: InstructionDetailsDTO[];
}
export interface IDetectedInstructions {
  instructions: InstructionCreateDTO[] | undefined;
  templates: InstructionTemplateSummaryDTO[] | undefined;
}
const regexLoad = 50;
const testSvc = new TestService();
const instructionSvc = new InstructionService();
export function TestEditorPopupAdd(props: ITestEditorPopupAddProps) {
  const context = useContext(UISContext);
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const [isSyntaxValid, setIsSyntaxValid] = useState<boolean>(false);
  const [detectedInstructions, setDetectedInstructions] =
    useState<InstructionCreateDTO[]>();
  const [detectedInstructionshtml, setDetectedInstructionshtml] = useState<
    JSX.Element[]
  >([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  /* const editorRef = useRef<MdEditor>(null); */
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [testDetails, settestDetails] = useState<TestDetailsDTO>();
  const [content, setContent] = useState<string>();
  const testNameFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
  });

  const testFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
  });
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/
  /* 
  const isButtonDisabled = useMemo(() => {
    return editPage.isLoading || isLoadingData;
  }, [editPage]); */
  useEffect(() => {
    console.log(props.instructions);
    if (props.instructions) {
      var instructions = "";
      for (var instruction of props.instructions) {
        instructions += instruction.simplified + "\n";
      }
      setContent(instructions);
    }
  }, []);
  /****************************
   * USER ACTIONS
   *****************************/
  const close = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      closePopup();
    },
    [closePopup]
  );
  const getDetectedInstructions =
    useCallback(async (): Promise<IDetectedInstructions> => {
      const compiler = new InstructionCompiler();
      var valid = true;
      var instructionsDetected: string[] = [];

      if (content !== undefined) {
        instructionsDetected = compiler.getInstructions(content);

        let matchedTemplates: InstructionTemplateSummaryDTO[] = [];
        let instructionsToCreate: InstructionCreateDTO[] = [];
        let res;
        if (instructionsDetected.length > 0) {
          var length = regexLoad;
          var page = 0;
          while (length === regexLoad) {
            res = await instructionSvc.getInstructions(
              {
                pageNumber: page,
                pageSize: regexLoad,
                orderOption: [],
                filterOptions: [],
              },
              parseInt(props.projectId)
            );
            for (var j = 0; j < instructionsDetected.length; j++) {
              var match = compiler.isMatchedInRegexList(
                instructionsDetected[j],
                res.items
              );
              for (var i = 0; i < match.length; i++) {
                matchedTemplates.push(match[i]);
                instructionsToCreate.push(
                  compiler.compileInstructionSummaryToInstructionCreateDTO(
                    match[i],
                    instructionsDetected[j],
                    j
                  )
                );
              }
            }
            length = res.items.length;
            valid = true;
          }
          if (instructionsToCreate.length === 0) {
            valid = false;
          }
        } else {
          valid = false;
        }

        if (valid) {
          return {
            instructions: instructionsToCreate,
            templates: matchedTemplates,
          };
        } else {
          return {
            instructions: undefined,
            templates: undefined,
          } as IDetectedInstructions;
        }
      }
      return {
        instructions: [],
        templates: [],
      } as IDetectedInstructions;
    }, [content, props.projectId]);
  const formatDetectedInstructionsHTML = useCallback(
    (
      instructionsToCreate: InstructionCreateDTO[],
      matchedTemplates: InstructionTemplateSummaryDTO[]
    ): JSX.Element[] => {
      const html = [];
      for (let i = 0; i < instructionsToCreate.length; i++) {
        const paramshtml = [];
        const parameterTemplates = matchedTemplates[i].parameters;
        const parameters = instructionsToCreate[i].instructionParameters;
        if (parameters !== undefined) {
          for (let j = 0; j < parameters.length; j++) {
            paramshtml.push([
              <InfoBlock
                key={"param" + j}
                label={parameterTemplates?.[j].name}
                value={parameters[j].value}
              />,
            ]);
          }

          html.push([
            <FormSection
              key={"instruction" + i}
              title={matchedTemplates[i].simplified}
              isInputGap
              childrenFlexDirColumn
            >
              {paramshtml}
            </FormSection>,
          ]);
        }
      }
      return html.flat();
    },
    []
  );

  const handleCheckSyntaxClicked = useCallback(async () => {
    setDetectedInstructionshtml([]);
    var detected = await getDetectedInstructions();
    if (
      detected.instructions !== undefined &&
      detected.templates !== undefined
    ) {
      setIsSyntaxValid(true);
      setDetectedInstructionshtml(
        formatDetectedInstructionsHTML(
          detected.instructions,
          detected.templates
        )
      );
      setDetectedInstructions(detected.instructions);
    } else {
      setIsSyntaxValid(false);
    }
  }, [formatDetectedInstructionsHTML, getDetectedInstructions]);

  const handleSaveInstructionsClicked = useCallback(
    async (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      var detected = await getDetectedInstructions();
      console.log(detected);
      if (
        detected.instructions === undefined ||
        detected.templates === undefined
      ) {
        openPopup(
          <ErrorPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            No instructions detected
          </ErrorPopup>
        );
      } else {
        openPopup(
          <SuccessPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Instructions saved successfully
          </SuccessPopup>
        );
        props.onInstructionsUpdated &&
          props.onInstructionsUpdated(detected.instructions);
        close(ev);
      }
    },
    [getDetectedInstructions, openPopup, context, props, close]
  );

  /****************************
   * CSS & HTML
   *****************************/

  return (
    <PopupArea>
      <PopupContainer className="edit-page-popup-test">
        <div className="edit-page-popup-header">
          <h1>{props.testName ? props.testName : "Test"}</h1>
          <div className="act-btns">
            <TextButton type="white" text={"Cancel"} onClick={close} />
            <Button
              text="Save"
              type="primary"
              isDisabled={isButtonDisabled}
              onClick={handleSaveInstructionsClicked}
            />
          </div>
        </div>
        <LineSeparator />
        <FlexLayout direction={"horizontal"} className="page-content-renderer">
          <Button
            text="Check syntax"
            type="tertiary"
            isDisabled={isButtonDisabled}
            onClick={handleCheckSyntaxClicked}
          />

          <InfoBlock
            label={
              "Use the editor below to write the test with simplified instructions and the compiler will do the rest for you. The check syntax button will validate the test."
            }
            value={""}
          />
        </FlexLayout>
        <LineSeparator />
        <Spacer px="15" mode="vertical" />
        <FlexLayout
          direction={"horizontal"}
          horizontalAlign="end"
          className="page-content-renderer"
        >
          <TabMenu
            tabs={[
              {
                key: "1",
                menuTitle: "Code Editor",
                content: (
                  <div>
                    <FlexLayout
                      direction={"horizontal"}
                      horizontalAlign={"center"}
                      className="page-content-renderer content"
                    >
                      <FormContainer>
                        <FormSection title="Test Specification">
                          <CodeiumEditor
                            theme="vs-dark"
                            value={content}
                            onChange={(value) => {
                              setContent(value);
                            }}
                            language={""}
                          />
                        </FormSection>
                      </FormContainer>
                    </FlexLayout>
                  </div>
                ),
                isActive: true,
              },
              {
                key: "2",
                menuTitle: "GUI Editor",
                content: "GUI Editor",
              },
            ]}
          ></TabMenu>
          <div className="detected-instructions">
            <FormContainer>
              <h1>
                {isSyntaxValid
                  ? "Detected Instructions"
                  : "No instructions detected"}
              </h1>
              {detectedInstructionshtml}
            </FormContainer>
          </div>
        </FlexLayout>
      </PopupContainer>
    </PopupArea>
  );
}
