import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";
import {
  Optional,
  ValidatorFunction,
  requiredValidator,
  useFormControl,
} from "../../lib/components/form/Form";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormFieldDatePicker } from "../../lib/components/form/form-field/FormFieldDatePicker";
import { DateTime } from "luxon";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { Form, useParams } from "react-router-dom";
import { Button } from "../../lib/components/buttons/Button";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { SectionSeparator } from "../../lib/components/form/form-section/SectionSeparator";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { TextButton } from "../../lib/components/buttons/TextButton";
import { useContext, useEffect, useState } from "react";
import { ProjectCreateDTO } from "../../models/project-create-dto";
import { ProjectService } from "../../services/ProjectService";
import { text } from "body-parser";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { UISContext } from "../../lib/infrastructure/ui/UIServices";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { Axios, AxiosError } from "axios";
import { TextArea } from "../../lib/components/form/text-area/TextArea";
import { TextInput } from "../../lib/components/form/text-input/TextInput";
import { FormFieldTextArea } from "../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldSelectSingle } from "../../lib/components/form/form-field/FormFieldSelectSingle";
import { RiSearchLine } from "react-icons/ri";
import { ComponentCreateDTO } from "../../models/component-create-dto";
import { ComponentService } from "../../services/ComponentService";
import { SuiteSetService } from "../../services/SuiteSetService";
import { SuiteSetCreateDTO } from "../../models/suite-set-create-dto";
import { FormFieldSelectMultiple } from "../../lib/components/form/form-field/FormFieldSelectMultiple";
import { ComponentSummaryDTO } from "../../models/component-summary-dto";
import { TestSummaryDTO } from "../../models/test-summary-dto";
import { TestService } from "../../services/TestService";
import { TestType } from "../../models/suite-set-test-type";
const projectService = new ProjectService();
const componentService = new ComponentService();
const testService = new TestService();
const suiteSetService = new SuiteSetService();

const labelProjectSelector = (item: ProjectSummaryDTO) => item.name;
const idProjectSelector = (item: ProjectSummaryDTO) => item.projectId;
const labelTestTypeSelector = (item: string) => item;
const labelComponentSelector = (item: ComponentSummaryDTO) =>
  item.name + " - " + item.projectName;
const idComponentSelector = (item: ComponentSummaryDTO) => item.componentId;
const labelTestSelector = (item: TestSummaryDTO) => item.name;
const idTestSelector = (item: TestSummaryDTO) => item.testId;
export function CreateSuiteSetForm() {
  const projectId = parseInt(useParams().projectId?.toString() || "0");

  const [projects, setProjects] = useState<ProjectSummaryDTO[]>([]);
  const [components, setComponents] = useState<ComponentSummaryDTO[]>([]);
  const [tests, setTests] = useState<TestSummaryDTO[]>([]);
  const context = useContext(UISContext);
  const nameFormControl = useFormControl<string>({
    validators: [requiredValidator(), lengthValidator(50)],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const descriptionFormControl = useFormControl<string>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: "",
  });
  const projectFormControl = useFormControl<ProjectSummaryDTO>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: {
      projectId: 0,
      name: "",
      startDate: "",
      mostRecentExecutionDate: "",
      mostRecentExecutionStatus: "",
    },
  });

  const testTypeFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: undefined,
  });
  const componentFormControl = useFormControl<ComponentSummaryDTO[]>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: [
      {
        componentId: 0,
        name: "",
        description: "",
        projectId: 0,
        projectName: "",
      },
    ],
  });

  const testFormControl = useFormControl<TestSummaryDTO[]>({
    validators: [],
    enableAutoValidate: true,
    isDisabled: false,
    initialValue: [
      {
        testId: 0,
        name: "",
        description: "",
        priority: "",
        userId: "",
        components: [],
      },
    ],
  });
  useEffect(() => {
    projectService.getProjects(undefined).then((res) => {
      setProjects(res.items);
    });
    componentService.getComponents(undefined, projectId).then((res) => {
      if (res.items.length === 0) {
        componentFormControl.setIsDisabled(true);
      }
      setComponents(res.items);
    });
    testService.getTests(undefined, projectId, 0).then((res) => {
      if (res.items.length === 0) {
        testFormControl.setIsDisabled(true);
      }
      setTests(res.items);
    });
  }, []);

  function lengthValidator(n: number): ValidatorFunction<Optional<string>> {
    return (value) => {
      if (value !== undefined) {
        if (value.length >= n) {
          return "This field exceeds maximum length!";
        }
      }
      return null;
    };
  }

  async function handleSubmit() {
    if (
      nameFormControl.validate() &&
      descriptionFormControl.validate() &&
      projectFormControl.validate() &&
      testTypeFormControl.validate()
    ) {
      const suiteSet: SuiteSetCreateDTO = {
        name: nameFormControl.value as string,
        description: descriptionFormControl.value,
        projectId: projectFormControl.value?.projectId as number,
        testType: testTypeFormControl.value as string,
        componentIds: componentFormControl.value?.map((c) => c.componentId),
        testIds: testFormControl.value?.map((t) => t.testId),
      };
      try {
        const res = await suiteSetService.createSuiteSet(suiteSet);
        context?.setShowPopup(true);
        context?.setPopupContent(
          <SuccessPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Suite set created successfully!
          </SuccessPopup>
        );
      } catch (err: any) {
        context?.setShowPopup(true);
        console.log(err);
        const msg = err.response.data.message;
        context?.setPopupContent(
          <ErrorPopup
            onDismissClicked={() => {
              context?.setShowPopup(false);
            }}
          >
            Something went wrong!{msg}
          </ErrorPopup>
        );
      }
    }
  }
  return (
    <div>
      <FormContainer>
        <h1>Create Suite set</h1>
        <FormFieldTextInput
          formControl={nameFormControl}
          label="Name"
          placeholder="Type something"
          id="name"
        />
        <FormFieldSelectSingle
          label="Project"
          placeholder="Select an option"
          formControl={projectFormControl}
          options={projects}
          labelSelector={labelProjectSelector}
          idSelector={idProjectSelector}
          icon={<RiSearchLine />}
        />
        {/*https://www.geeksforgeeks.org/how-to-get-names-of-enum-entries-in-typescript/*/}
        <FormFieldSelectSingle
          label="Type of Test"
          placeholder="Select an option"
          formControl={testTypeFormControl}
          options={Object.getOwnPropertyNames(TestType).filter((prop) =>
            isNaN(parseInt(prop))
          )}
          labelSelector={labelTestTypeSelector}
          idSelector={labelTestTypeSelector}
          icon={<RiSearchLine />}
        />
        <FormFieldSelectMultiple
          label="Components"
          placeholder="Select an option"
          formControl={componentFormControl}
          options={components}
          labelSelector={labelComponentSelector}
          idSelector={idComponentSelector}
          icon={<RiSearchLine />}
        />
        <FormFieldSelectMultiple
          label="Tests"
          placeholder="Select an option"
          formControl={testFormControl}
          options={tests}
          labelSelector={labelTestSelector}
          idSelector={idTestSelector}
          icon={<RiSearchLine />}
        />

        <FormFieldTextArea
          formControl={descriptionFormControl}
          label="Description"
          placeholder="Type something"
        />

        <Button
          type="primary"
          text="Create"
          onClick={handleSubmit}
          isDisabled={
            !nameFormControl.isValid ||
            !descriptionFormControl.isValid ||
            !projectFormControl.isValid ||
            !testTypeFormControl.isValid ||
            !componentFormControl.isValid ||
            !testFormControl.isValid
          }
        />
      </FormContainer>
    </div>
  );
}
