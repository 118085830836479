import { InstructionParameterTemplateCreateDTO } from "../models/instruction-parameter-template-create-dto";

function parameterCrossValidation(
    simplifiedParameters: InstructionParameterTemplateCreateDTO[],
    gherkinParameters: InstructionParameterTemplateCreateDTO[],
    codeParameters: InstructionParameterTemplateCreateDTO[]
) {

    var GherkinIsFilled = gherkinParameters.length !== 0;

    if (
        simplifiedParameters.length !== codeParameters.length
    ) {
        return false;
    } else if (GherkinIsFilled) {
        if (simplifiedParameters.length !== gherkinParameters.length ||
            codeParameters.length !== gherkinParameters.length
        ) {
            return false;
        }
    }

    for (let i = 0; i < simplifiedParameters.length; i++) {
        if (GherkinIsFilled) {
            if (simplifiedParameters[i].name !== gherkinParameters[i].name ||
                gherkinParameters[i].name !== codeParameters[i].name) {
                return false;
            }
        } else if (
            simplifiedParameters[i].name !== codeParameters[i].name
        ) {
            return false;
        }
    }
    return true;
}

export default parameterCrossValidation;