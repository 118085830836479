import { FormField } from "./FormField";
import { IFormControl } from "../Form";
import { SelectMultipleNoSearch } from "../select-multiple-no-search/SelectMultipleNoSearch";

interface IFormFieldSelectMultipleNoSearchProps<T> {
    label?: string;
    formControl: IFormControl<T[]>;
    icon?: React.ReactNode;
    placeholder?: string;
    options: T[];
    labelSelector: (value: T) => string;
    idSelector: (value: T) => string | number;
    maxHeightOptions?: string;
}

export function FormFieldSelectMultipleNoSearch<T>(
    props: IFormFieldSelectMultipleNoSearchProps<T>
) {
    return (
        <FormField label={props.label} formControl={props.formControl}>
            <SelectMultipleNoSearch
                options={props.options}
                labelSelector={props.labelSelector}
                idSelector={props.idSelector}
                placeholder={props.placeholder}
                icon={props.icon}
                hasError={props.formControl.hasErrors}
                isDisabled={props.formControl.isDisabled}
                onChange={props.formControl.setValue}
                value={props.formControl.value}
                maxHeightOptions={props.maxHeightOptions}
            />
        </FormField>
    );
}
