import "./FormContainer.css";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { useMemo } from "react";

export interface IFormContainerProps {
  children: React.ReactNode;
  mode?: "horizontal" | "vertical";
  className?: string;
}

export function FormContainer(props: IFormContainerProps) {
  
  const stackFormContainerCss = useMemo(() => {
    return CssClassnameBuilder.new()
        .add("form-container")
        .add(props.className)
        .add(props.mode ?? "vertical")
        .build();
}, [props.className, props.mode]);

  return <div className={stackFormContainerCss}>{props.children}</div>;
}
