import "./TableRow.css";

import { ITableCellProps as ITableCell, TableCell } from "../table-cell/TableCell";
import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";

export interface ITableRowProps {
    className?: string;
    cells: ITableCell[];
    onClick?: (cells: ITableCell[]) => void;
    showSelectedRowHighlighted?: boolean;
}

export function TableRow(props: ITableRowProps) {
    /****************************
     * CSS && HTML
     *****************************/
    const tableRowCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("table-row")
            .addConditional(props.className, props.className)
            .addConditional(props.showSelectedRowHighlighted, "highlighted")
            .build();
    }, [props.className, props.showSelectedRowHighlighted]);

    const cells = useMemo(() => {
        return props.cells.map((cell, idx) => {
            let tableCellCss = CssClassnameBuilder.new()
                .addConditional(cell.className, cell.className)
                .add("table-row-cell")
                .build();
            return (
                <TableCell className={tableCellCss} key={idx}>
                    {cell.children}{" "}
                </TableCell>
            );
        });
    }, [props.cells]);


    const handleRowClick = useCallback(() => {
        const screenSelectedText = window.getSelection()?.toString();
        if (screenSelectedText === "") {
            props.onClick && props.onClick(props.cells);
        }
    }, [props.onClick, props.cells]);

    return (
        <tr className={tableRowCss} onClick={handleRowClick}>
            {cells}
        </tr>
    );
}
