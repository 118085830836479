import axios, { AxiosResponse } from "axios";
import Settings from "../appconfiguration.json";
import { ComponentCreateDTO } from "../models/component-create-dto";
import { ComponentDetailsDTO } from "../models/component-details-dto";
import { ComponentUpdateDTO } from "../models/component-update-dto";
import { SuiteSetCreateDTO } from "../models/suite-set-create-dto";
import { TestCreateDTO } from "../models/test-create-dto";
import { TestDetailsDTO } from "../models/test-details-dto";
import { TestUpdateDTO } from "../models/test-update-dto";
import { ExecutionPage } from "../models/execution-page-dto";
import { ExecutionCreateDTO } from "../models/execution-create-dto";
import { ExecutionDetailsDTO } from "../models/execution-details-dto";
import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { ExecutionUpdateDTO } from "../models/execution-update-dto";
import { ReportDetailsDTO } from "../models/report-details-dto";
const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;
export class ReportService {

    async getReport(id: number, projectId: number, suiteSetId: number, executionId: number) {
        const res = await axios.get<ReportDetailsDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Executions/${executionId}/Reports/${id}`));
        return res.data ? res.data : undefined;
    }

    async getLatestReport(projectId: number, suiteSetId: number, executionId: number) {
        const res = await axios.get<ReportDetailsDTO>(Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Executions/${executionId}/Reports/latest`));
        return res.data ? res.data : undefined;
    }
    async getReports(paginationModel: PageModel | undefined, projectId: number | 0, suiteSetId: number | 0) {
        return axios.request({
            url: Route(`api/Projects/${projectId}/SuiteSets/${suiteSetId}/Executions`),
            method: 'get',
            paramsSerializer: {
                indexes: false
            },
            params: {
                pageNumber: paginationModel?.pageNumber,
                pageSize: paginationModel?.pageSize,
                orderOption: paginationModel?.orderOption,
                filterOptions: paginationModel?.filterOptions
            }
        }).then((res: AxiosResponse<ReportPageDTO>) => res.data);

    }
}
