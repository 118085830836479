import "./SimpleCard.css";

import { CardContainer } from "../../layouts/containers/card/CardContainer";
import { ReactComponent as CircleArrowSVG } from "../../assets/icons/circle-arrow.svg";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { IconButton } from "../buttons/IconButton";
import { useMemo } from "react";

interface ISimpleCardProps {
  className?: string;
  title: string;
  hideAvatar?: boolean;
  onClick?: () => void;
}

export function SimpleCard(props: ISimpleCardProps) {

  /****************************
   * CSS && HTML
   *****************************/
  const cardCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("simple-card")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const simpleCardAvatar = useMemo(() => {
    if (!props.hideAvatar)
      return (
        <div className="simple-card-avatar">
          <h1>{props.title.charAt(0)}</h1>
        </div>
      );
    else return null;
  }, [props.hideAvatar, props.title]);

  return (
    <CardContainer className={cardCss}>
      <div className="simple-card-header">
        {simpleCardAvatar}
        <h3>{props.title}</h3>
      </div>
      <IconButton type="tertiary" onClick={props.onClick} icon={<CircleArrowSVG />} size={40} />
    </CardContainer>
  );
}
