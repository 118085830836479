import {
  Optional,
  ValidatorFunction,
  requiredValidator,
  useFormControl,
} from "../../lib/components/form/Form";
import { RiEdit2Fill } from "react-icons/ri";
import { MdModeEdit } from "react-icons/md";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FormFieldDatePicker } from "../../lib/components/form/form-field/FormFieldDatePicker";
import { DateTime } from "luxon";
import { Button } from "../../lib/components/buttons/Button";
import { FormContainer } from "../../lib/layouts/containers/form/FormContainer";
import { useContext, useEffect } from "react";
import { ProjectService } from "../../services/ProjectService";
import { SuccessPopup } from "../../lib/components/popup/SuccessPopup";
import { UISContext } from "../../lib/infrastructure/ui/UIServices";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { ProjectUpdateDTO } from "../../models/project-update-dto";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import React from "react";
import { FormSection } from "../../lib/components/form/form-section/FormSection";
import { InfoBlock } from "../../lib/components/info-block/InfoBlock";
import { TextButton } from "../../lib/components/buttons/TextButton";
import { ErrorPage } from "../error-page/ErrorPage";
import { ProjectDetailsDTO } from "../../models/project-details-dto";
const projectService = new ProjectService();

export function ConsultProject() {
  const navigate = useNavigate();
  const [project, setProject] = React.useState<ProjectDetailsDTO>();
  const [isNotFound, setIsNotFound] = React.useState<boolean>(false);
  const [isNetworkError, setIsNetworkError] = React.useState<boolean>(false);
  var id = useParams().id;
  useEffect(() => {
    if (id === undefined) {
      setIsNotFound(true);
    }
    projectService
      .getProject(id)
      .then((datat) => {
        if (datat != null) {
          setProject(datat);
        }
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          setIsNetworkError(true);
        } else if (error.response?.status === 404) {
          console.log("Not found!");
          setIsNotFound(true);
        }
      });
  }, []);

  if (isNotFound) {
    navigate("/notfound");
  } else if (isNetworkError) {
    navigate("/networkerror");
  }
  return (
    <div>
      <FormContainer>
        <h1>{project?.name ? project.name : "None"}</h1>
        <FormSection title="Details" unsetFlexGrow>
          <InfoBlock
            label="Name"
            value={project?.name ? project.name : "None"}
          />
          <InfoBlock
            label="Start Date"
            value={
              project?.startDate
                ? DateTime.fromISO(project.startDate).toFormat("dd/MM/yyyy")
                : "No date"
            }
          />
          <Button
            type="secondary"
            text="Edit"
            icon={<RiEdit2Fill />}
            onClick={() => navigate(`/projects/${project?.projectId}/edit`)}
          />
        </FormSection>
      </FormContainer>
    </div>
  );
}
