import axios, { AxiosResponse } from "axios";
import Settings from "../appconfiguration.json";
import { ComponentUpdateDTO } from "../models/component-update-dto";
import { InstructionTemplateDetailsDTO } from "../models/instruction-template-details-dto";
import { InstructionTemplateCreateDTO } from "../models/instruction-template-create-dto";
import { InstructionTemplateUpdateDTO } from "../models/instruction-template-update-dto";
import { InstructionTemplatePage } from "../models/instruction-template-page";
import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
const Route = (path: string) => `${ConfigurationProvider.getConfiguration().App.BackendUrl}/${path}`;

export class InstructionService {
    async deleteInstruction(id: number, projectId: number) {
        console.log("Deleting instruction with id: " + id);
        const res = await axios.delete(Route(`api/Projects/${projectId}/Instructions/${id}`));
        return res.status === 200 ? true : false;

    }

    async updateInstruction(projectId: string, template: InstructionTemplateUpdateDTO) {
        const res = await axios.put<InstructionTemplateUpdateDTO>(Route(`api/Projects/${projectId}/Instructions/${template.instructionTemplateId}`), template);
        return res.data ? res.data : undefined;
    }

    async getInstruction(id: string, projectId: string) {
        const res = await axios.get<InstructionTemplateDetailsDTO>(Route(`api/Projects/${projectId}/Instructions/${id}`));

        return res.data ? res.data : undefined;
    }
    async createInstruction(template: InstructionTemplateCreateDTO) {

        const res = await axios.post<InstructionTemplateCreateDTO>(Route(`api/Projects/${template.projectId}/Instructions`), template);

        return res.data ? res.data : undefined;


    }
    async getInstructions(paginationModel: PageModel | undefined, projectId: number) {
        console.log("Getting instructions");
        return axios.request({
            url: Route(`api/Projects/${projectId}/Instructions`),
            method: 'get',
            paramsSerializer: {
                indexes: false
            },
            params: {
                pageNumber: paginationModel?.pageNumber,
                pageSize: paginationModel?.pageSize,
                orderOption: paginationModel?.orderOption,
                filterOptions: paginationModel?.filterOptions
            }
        }).then((res: AxiosResponse<InstructionTemplatePage>) => res.data);

    }
}
