import "./AppPortal.css";

import { PopupArea } from "../containers/popup-area/PopupArea";
import {
  useNotification,
  useRenderNotificationContainer,
  useRenderPopup,
} from "../../infrastructure/ui/UIServices";

export interface IAppPortalProps {
  children: React.ReactNode;
}

export function AppPortal(props: IAppPortalProps) {
  const { showPopup, popupContent } = useRenderPopup();

  return (
    <div className="app-portal">
      {props.children}
      {showPopup ? <PopupArea>{popupContent}</PopupArea> : null}
    </div>
  );
}
